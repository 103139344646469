import type { ReadConfig } from 'api-read-hook';
import { useApiRead } from 'api-read-hook';

import type { HydraCollection } from '../../types';
import * as Upload from './Upload';

export type V = {
  '@id': string;
  '@type': 'SuggestedMedia';
  id: string;
  album: {
    '@id': string;
    '@type': 'Album';
    title: string;
    friendship: {
      '@id': string;
      '@type': 'Friendship';
      friend: {
        '@id': string;
        '@type': 'User';
        firstName: string;
        lastName: string;
      };
    } | null;
  };
  upload: Upload.BaseView;
};

export function useReadCollectionSuggestedMedias(
  id: string,
  options?: ReadConfig
) {
  return useApiRead<HydraCollection<V>>(
    `/users/${id}/suggested-medias`,
    options
  );
}
