import { NativeStackScreenProps } from '@react-navigation/native-stack';
import * as React from 'react';
import { toast } from 'react-hot-toast';
import { View } from 'react-native';

import { Api, useApiCallable } from '../../../api';
import {
  Button,
  LRText,
  PaddedArea,
  ScreenError,
  ScreenHeading,
  ScreenLoading,
  Spacer,
} from '../../../components';
import { Style } from '../../../constants';
import { MainStackParamList } from '../../../navigation';

type Props = NativeStackScreenProps<MainStackParamList, 'Trustees.ViewInvite'>;

export default function ViewInviteScreen({ navigation, route }: Props) {
  const { code } = route.params;

  const callApi = useApiCallable();

  const [saving, setSaving] = React.useState(false);

  const api = Api.TrusteeInvite.useReadItem(code);
  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ScreenLoading />;
  const invite = api.data;

  return (
    <PaddedArea v h style={[Style.fill, Style.alignCenter]}>
      <ScreenHeading title="Trustee invitation" />
      <Spacer />
      <LRText textAlign="center">
        You have received an invitation to become a trustee. After accepting,
        you will have access to the room of the person who invited you -{' '}
        {invite.owner.firstName} {invite.owner.lastName}
      </LRText>
      <Spacer />
      <Spacer />
      <View style={Style.row}>
        <View style={{ flex: 2 }}>
          <Button
            disabled={saving}
            title="Refuse"
            buttonType={'tertiary'}
            onPress={async () => {
              setSaving(true);
              const response = await callApi(
                `/trustee-invites/${code}/reject`,
                {
                  method: 'POST',
                  jsonBody: {},
                }
              );
              if (response) {
                toast.success('Invite rejected');
                navigation.navigate('Home.Home');
              }
            }}
          />
        </View>
        <Spacer size={0.3} />
        <View style={{ flex: 3 }}>
          <Button
            disabled={saving}
            title="Accept"
            onPress={async () => {
              setSaving(true);
              const response = await callApi(
                `/trustee-invites/${code}/accept`,
                {
                  method: 'POST',
                  jsonBody: {},
                }
              );
              if (response) {
                toast.success('Invite accepted');
                navigation.navigate('Account.ManageRooms');
              }
            }}
          />
        </View>
      </View>
    </PaddedArea>
  );
}
