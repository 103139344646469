import { NativeStackScreenProps } from '@react-navigation/native-stack';
import * as React from 'react';
import { ActivityIndicator } from 'react-native';

import { useApiCallable } from '../../../api';
import { LRText, PaddedArea, Spacer } from '../../../components';
import { Color } from '../../../constants';
import { useLRDataContext } from '../../../contexts';
import { MainStackParamList } from '../../../navigation';

type Props = NativeStackScreenProps<MainStackParamList, 'Account.VerifyEmail'>;

export default function VerifyEmailScreen({ route }: Props) {
  const { email, code } = route.params;
  const callApi = useApiCallable();
  const { apiUserOverview } = useLRDataContext();

  const verifyEmail = async () => {
    const response = await callApi('/email-verification/verify', {
      jsonBody: { code, email },
      method: 'POST',
    });
    if (response) {
      apiUserOverview.invalidate();
      window.location.reload();
    }
  };

  React.useEffect(() => {
    verifyEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PaddedArea v h>
      <LRText color="extraDarkGray" textAlign="center" typeface="h1">
        Please wait while we verify your email
      </LRText>
      <Spacer />
      <ActivityIndicator color={Color.lightGrey} size={'large'} />
    </PaddedArea>
  );
}
