import { Image, StyleSheet, View } from 'react-native';

// @ts-expect-error
import FriendsWallIcon from '../../../../assets/img/pods/home/illustrations/friends_wall_icon.svg';
import { LRText, PressableLink, Spacer } from '../../../components';
import { IconAvatar, IconCaret } from '../../../components/icons';
import { Color, Dimen, Style } from '../../../constants';
import { useLayoutContext } from '../../../contexts';
import { useUserOverview } from '../../../hooks';

type Props = {};

export default function FriendsWallButton({}: Props) {
  const { isDesktopLayout } = useLayoutContext();
  const {
    managingUser,
    managingUserFeaturedFriends,
    managingUserFriendsCount,
  } = useUserOverview();

  const friendsToShow =
    managingUserFeaturedFriends.length > 4
      ? managingUserFeaturedFriends.slice(0, 4)
      : managingUserFeaturedFriends;

  return (
    <PressableLink
      to={{
        screen: 'FriendsWall.FriendsWall',
      }}
      style={({ hovered }) => [
        styles.button,
        isDesktopLayout && styles.desktopButton,
        hovered && styles.buttonHover,
      ]}
      disabled={!managingUser.featureEntitlements.includes('friends_wall')}
    >
      <Image source={FriendsWallIcon} style={styles.roomIcon} />
      <Spacer size={0.5} />
      <View style={[Style.fill, { justifyContent: 'center' }]}>
        <LRText typeface="h3">Friends Wall</LRText>
        <View style={Style.row}>
          {friendsToShow.map((user, i) => {
            const avatarImage = user.avatar?.pathAvatar;
            if (avatarImage) {
              return (
                <Image
                  key={user['@id']}
                  source={avatarImage as any}
                  style={[styles.avatar, { left: -15 * i }]}
                />
              );
            }
            return (
              <View
                key={user['@id']}
                style={[
                  styles.avatar,
                  Style.centerBoth,
                  {
                    left: -15 * i,
                  },
                ]}
              >
                <IconAvatar size={46} />
              </View>
            );
          })}
          {managingUserFriendsCount > 4 && (
            <View
              style={[
                Style.centerBoth,
                styles.avatar,
                styles.additionalFriendsPlaceholder,
              ]}
            >
              <LRText>{`+${
                managingUserFriendsCount - friendsToShow.length
              }`}</LRText>
            </View>
          )}
        </View>
      </View>
      {!isDesktopLayout && <IconCaret />}
    </PressableLink>
  );
}

const styles = StyleSheet.create({
  button: {
    paddingVertical: Dimen.spacing,
    paddingRight: Dimen.spacing,
    flexDirection: 'row',
    alignItems: 'center',
    transitionProperty: 'background-color',
    transitionDuration: '200ms',
  },
  roomIcon: {
    width: 65,
    height: 60,
  },
  desktopButton: {
    paddingLeft: Dimen.spacing,
  },
  buttonHover: {
    backgroundColor: Color.lightGrey,
  },
  avatar: {
    height: 52,
    width: 52,
    border: `3px solid ${Color.whiteHigh}`,
    borderRadius: 26,
    backgroundColor: Color.lightGrey,
  },
  additionalFriendsPlaceholder: {
    left: -60,
  },
});
