import Clipboard from '@react-native-clipboard/clipboard';
import * as React from 'react';
import { toast } from 'react-hot-toast';
import { StyleSheet, TouchableOpacity } from 'react-native';

import { LRText, PaddedArea, Spacer } from '../components';
import { IconCopy } from '../components/icons';
import { Color, Dimen } from '../constants';

type Props = {
  text: string;
};

export default function CopyableField({ text }: Props) {
  return (
    <TouchableOpacity
      onPress={async () => {
        Clipboard.setString(text);
        toast.success('Copied to clipboard');
      }}
    >
      <PaddedArea style={styles.container}>
        <Spacer fill />
        <LRText numberOfLines={1} typeface="h3">
          {text}
        </LRText>
        <Spacer fill />
        <IconCopy />
      </PaddedArea>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Color.lightGrey,
    paddingVertical: Dimen.spacing,
    paddingLeft: Dimen.spacing * 0.5,
    paddingRight: Dimen.spacing,
    borderRadius: Dimen.cornerRadiusLarge,
    flexDirection: 'row',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    alignSelf: 'center',
  },
  copyIcon: {
    width: 24,
    height: 24,
  },
});
