import { NavigationProp, RouteProp } from '@react-navigation/native';
import React from 'react';
import { Image, StyleSheet, View, useWindowDimensions } from 'react-native';

import { Api, useApiCallable } from '../../api';
import {
  ActionsDropdown,
  ConfirmationModal,
  LRText,
  PaddedArea,
  PressableLink,
  ScreenError,
  ScreenLoading,
  Spacer,
} from '../../components';
import { DropdownAction } from '../../components/ActionsDropdown';
import {
  IconDelete,
  IconEdit,
  IconFriend,
  IconPencil,
  IconPlus,
} from '../../components/icons';
import { Color, Dimen, Style } from '../../constants';
import { useLayoutContext } from '../../contexts';
import { useDynamicPageTitle, useUserOverview } from '../../hooks';
import { Model } from '../../models';
import { MainStackParamList } from '../../navigation';
import { iriToId } from '../../util';
import { SharedWithHeader, ViewAlbumItem } from './components';

type Props = {
  route: RouteProp<MainStackParamList, 'Albums.ViewAlbum'>;
  navigation: NavigationProp<MainStackParamList>;
};

export default function ViewAlbumScreen({ route, navigation }: Props) {
  const { albumId, ownerName } = route.params;
  const { isDesktopLayout } = useLayoutContext();
  const { width: windowWidth } = useWindowDimensions();
  const { myUser } = useUserOverview();
  const callApi = useApiCallable();

  const containerWidth =
    Math.min(windowWidth, Dimen.contentMaxWidth) - Dimen.spacing * 2 - 10 - 1;
  const minItemWidth = 110;
  const itemsPerRow = Math.min(
    3,
    Math.max(1, Math.floor(containerWidth / minItemWidth))
  );
  const itemSize = Math.floor(containerWidth / itemsPerRow);

  const [deleteModalRoomTriggerId, setDeleteModalRoomTriggerId] =
    React.useState<string | null>(null);
  const [removedFriend, setRemovedFriend] = React.useState<
    Api.Album.V_Detail['friendship'] | null
  >(null);

  const api = Api.Album.useReadItem(albumId);
  const album = api.data;

  useDynamicPageTitle(`View Album: ${api.data ? api.data.title : null}`);

  if (api.error) return <ScreenError {...api} />;
  if (!album) return <ScreenLoading />;

  let isOwner = false;
  if (album.friendship && myUser.id === iriToId(album.friendship.user['@id'])) {
    isOwner = true;
  }

  async function onDeleteConfirm() {
    if (!deleteModalRoomTriggerId) return;
    await callApi(`/room-triggers/${deleteModalRoomTriggerId}`, {
      method: 'DELETE',
    });
    album &&
      album.roomTrigger &&
      navigation.navigate('Albums.AlbumsList', {
        roomTriggerType: album.roomTrigger.type,
      });
  }

  async function onRemoveConfirm() {
    const response = await callApi(
      `/friendships/${iriToId(removedFriend!['@id'])}`,
      {
        method: 'DELETE',
      }
    );
    if (response) {
      setRemovedFriend(null);
      navigation.navigate('FriendsWall.FriendsWall');
    }
  }

  const actions = [
    ...(album.safeCover && !ownerName
      ? [
          {
            label: 'Edit main photo',
            icon: IconEdit,
            to: {
              screen: 'Albums.EditMedia',
              params: { mediaId: album.safeCover.id },
            },
          } as DropdownAction,
        ]
      : []),
    ...(!ownerName
      ? [
          {
            label: 'Delete album',
            icon: IconDelete,
            onPress: () => {
              if (album.roomTrigger) {
                setDeleteModalRoomTriggerId(iriToId(album.roomTrigger['@id']));
              }
            },
          },
        ]
      : []),
    {
      label: ownerName ? 'Add suggested content' : 'Edit album',
      icon: IconPencil,
      to: ownerName
        ? {
            screen: 'Albums.SuggestMedia',
            params: { albumId: album.id, ownerName },
          }
        : {
            screen: 'Albums.EditAlbum',
            params: { albumId: album.id },
          },
    } as DropdownAction,
    ...(album.friendship && isOwner
      ? [
          {
            label: 'Remove friend',
            icon: IconFriend,
            onPress: () => {
              if (album.friendship) {
                setRemovedFriend(album.friendship);
              }
            },
          },
        ]
      : []),
  ];

  return (
    <>
      <PaddedArea v h>
        {album.friendship ? (
          <SharedWithHeader album={album} allowedActions={actions} />
        ) : (
          <View style={[Style.row, Style.alignCenter, { zIndex: 10 }]}>
            <View style={Style.fill}>
              <LRText typeface="h1" color="accent">
                {album.title}
              </LRText>
              {album.roomTrigger &&
                album.roomTrigger.type !== 'welcome_note' && (
                  <>
                    <Spacer size={0.25} />
                    <LRText typeface="body1" color="darkBg">
                      {`${Model.RoomTrigger.typeToReadableName(
                        album.roomTrigger.type
                      )} ${album.roomTrigger.slot ?? ''}`}
                    </LRText>
                  </>
                )}
            </View>
            <ActionsDropdown actions={actions} />
          </View>
        )}
        <Spacer />
        {windowWidth <= 440 ? (
          <View>
            <LRText typeface="body1" color="darkBg">
              {album.story}
            </LRText>
            <Spacer />
            <Image
              style={styles.image}
              source={album.safeCover?.upload.pathOrganiserCover as any}
            />
          </View>
        ) : (
          <View style={Style.row}>
            <Image
              style={[styles.image, { maxWidth: itemSize }]}
              source={album.safeCover?.upload.pathOrganiserCover as any}
            />
            <Spacer />
            <PaddedArea style={Style.fill} h={1.5}>
              <LRText typeface="body1" color="darkBg">
                {album.story}
              </LRText>
            </PaddedArea>
          </View>
        )}
        <Spacer />
        <View style={styles.contentContainer}>
          <PressableLink
            to={
              ownerName
                ? {
                    screen: 'Albums.SuggestMedia',
                    params: { albumId: album.id, ownerName },
                  }
                : {
                    screen: 'Albums.EditAlbum',
                    params: { albumId: album.id },
                  }
            }
            style={[
              styles.button,
              {
                height: itemSize,
                width: itemSize,
              },
            ]}
          >
            <IconPlus
              size={isDesktopLayout ? 36 : 23}
              color={Color.accent400}
            />
          </PressableLink>
          {album.albumMedias.map((albumMedia) => (
            <ViewAlbumItem
              key={albumMedia.id}
              media={albumMedia}
              isCover={albumMedia.id === album.safeCover?.id}
              size={itemSize}
              albumId={album.id}
            />
          ))}
        </View>
      </PaddedArea>
      <ConfirmationModal
        title="Are you sure?"
        message=" All media in this album and the album itself will be permanently deleted."
        confirmationText="Delete album"
        onCancel={() => setDeleteModalRoomTriggerId(null)}
        visible={!!deleteModalRoomTriggerId}
        onConfirm={onDeleteConfirm}
      />
      <ConfirmationModal
        title={`Are you sure you want to remove ${
          isOwner
            ? removedFriend?.friend.firstName
            : removedFriend?.user.firstName
        }`}
        confirmationText="Remove friend"
        onCancel={() => setRemovedFriend(null)}
        visible={!!removedFriend}
        onConfirm={onRemoveConfirm}
      />
    </>
  );
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: Color.lightGrey,
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    flex: 1,
    aspectRatio: 9 / 7,
    width: '100%',
    resizeMode: 'contain',
    backgroundColor: Color.imageLoadingBg,
  },
  contentContainer: {
    gap: 5,
    overflow: 'hidden',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
});
