import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import { Color, Dimen, Style } from '../../constants';
import { To } from '../../types';
import LRText from '../LRText';
import PaddedArea from '../PaddedArea';
import PressableLink from '../PressableLink';
import Spacer from '../Spacer';

type Props = {
  title: string;
  icon: React.ReactNode;
} & (
  | {
      to?: undefined;
      href: string;
    }
  | {
      to: To;
      href?: undefined;
    }
);

export default function NavigationLink({ to, href, icon, title }: Props) {
  if (to) {
    return (
      <PressableLink
        to={to}
        style={({ hovered }: any) => [
          styles.button,
          hovered && styles.buttonHovered,
        ]}
      >
        <PaddedArea v={0.5} h={0.25} style={[Style.row, Style.alignCenter]}>
          <View style={styles.iconWrap}>{icon}</View>
          <Spacer size={0.5} />
          <LRText typeface="button">{title}</LRText>
        </PaddedArea>
      </PressableLink>
    );
  } else {
    return (
      <Pressable
        {...{
          ['href' as any]: `${href}`,
        }}
        style={({ hovered }: any) => [
          styles.button,
          hovered && styles.buttonHovered,
        ]}
      >
        <PaddedArea v={0.5} h={0.25} style={[Style.row, Style.alignCenter]}>
          <View style={styles.iconWrap}>{icon}</View>
          <Spacer size={0.5} />
          <LRText typeface="button">{title}</LRText>
        </PaddedArea>
      </Pressable>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    borderRadius: Dimen.cornerRadiusSmall,
    transitionDuration: '0.2s',
  },
  buttonHovered: {
    backgroundColor: Color.lightGrey,
  },
  iconWrap: {
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
