import * as React from 'react';

import { Button } from '../components';

type PassthroughProps = Omit<React.ComponentProps<typeof Button>, 'onPress'>;

type Props = PassthroughProps & {
  isLoading: boolean;
  handleSubmit: () => void;
  forceDisabled?: boolean;
};

export default function SubmitButton({
  isLoading,
  handleSubmit,
  forceDisabled,
  title = 'Submit',
  ...rest
}: Props) {
  return (
    <Button
      title={title}
      disabled={isLoading || forceDisabled}
      onPress={handleSubmit}
      {...rest}
    />
  );
}
