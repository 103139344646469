import {
  DMSans_400Regular,
  DMSans_500Medium,
  DMSans_700Bold,
  useFonts,
} from '@expo-google-fonts/dm-sans';
import { DMSerifDisplay_400Regular } from '@expo-google-fonts/dm-serif-display';
import { NavigationContainer } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import * as React from 'react';
import { Toaster } from 'react-hot-toast';
import { ActivityIndicator, View } from 'react-native';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { LRApiReadConfig } from '../api';
import {
  DropdownMutexContextProvider,
  LRDataContextProvider,
  LayoutContextProvider,
} from '../contexts';
import MainStack, { mainStackLinkingConfig } from '../navigation/MainStack';
import { configureStore } from '../store';

const { store, persistor } = configureStore();

export default function AppLoader() {
  const [fontsLoaded] = useFonts({
    DMSans_400Regular,
    DMSans_500Medium,
    DMSans_700Bold,
    DMSerifDisplay_400Regular,
  });

  const prefix = Linking.createURL('/');

  const linking = {
    prefixes: [prefix],
    config: {
      screens: mainStackLinkingConfig,
    },
  };

  if (!fontsLoaded) {
    return (
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <ActivityIndicator style={{ alignSelf: 'center' }} />
      </View>
    );
  }

  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <NavigationContainer linking={linking}>
          <DropdownMutexContextProvider>
            <LayoutContextProvider>
              <LRApiReadConfig>
                <LRDataContextProvider>
                  <MainStack />
                  <Toaster
                    toastOptions={{ style: { fontFamily: 'DMSans_500Medium' } }}
                  />
                </LRDataContextProvider>
              </LRApiReadConfig>
            </LayoutContextProvider>
          </DropdownMutexContextProvider>
        </NavigationContainer>
      </PersistGate>
    </ReduxProvider>
  );
}
