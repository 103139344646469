import { Link } from '@react-navigation/native';
import * as React from 'react';
import { View } from 'react-native';
import { useForm } from 'react-typed-form';

import { Api, authApi } from '../../api';
import {
  Button,
  CardPage,
  ErrorList,
  LRText,
  LinkButton,
  Spacer,
} from '../../components';
import { Style } from '../../constants';
import { FieldTextRequired } from '../../form';
import Cookies from 'universal-cookie';

type FormShape = {
  email: string;
  password: string;
};

export default function LoginScreen() {
  const attemptLogin = authApi.useAttemptLogin();
  const cookies = new Cookies();
  const friendCode = cookies.get('lr_friend_invite');

  const form = useForm<FormShape>({
    defaultValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values, { addSubmitError, setLoading }) => {
      setLoading(true);
      const success = await attemptLogin(
        {
          email: values.email.trim().toLowerCase(),
          password: values.password.trim(),
        },
        addSubmitError
      );
      if (!success) {
        setLoading(false);
      }
    },
  });

  return (
    <View>
      <Spacer size={2} />   
      <View style={Style.alignCenter}>
        <View style={{ maxWidth: 200 }}>
          <LRText typeface="h1" textAlign="center" color="whiteHigh">
            Log in to Your Account
          </LRText>
        </View>
      </View>
      <Spacer />
      <CardPage>
      {friendCode && (
        <>
        <View style={Style.alignCenter}>
          <LRText typeface="body1" textAlign="justify">Welcome to the Legacy Room, a personalised and secure 
              digital space that reflects your unique life story. 
              Someone you know has decided to share his Friend's Wall with you.
              There you will be able to upload and share you memories with them.
              Login or create an account to find out more.
            </LRText>
        </View>
        <Spacer size={2} />
        </>
        )}
        <FieldTextRequired
          autoCapitalize="none"
          field={form.getField('email')}
        />
        <Spacer />
        <FieldTextRequired
          autoCapitalize="none"
          secureTextEntry
          onSubmitEditing={form.handleSubmit}
          field={form.getField('password')}
        />
        <Spacer size={0.5} />
        <Link to={{ screen: 'Auth.ForgotPassword' }}>
          <LRText typeface="captionM" color="darkGray">
            Forgot your password?
          </LRText>
        </Link>
        <ErrorList errorList={form.formErrorList} />
        <Spacer />
        <Button
          disabled={form.isLoading}
          title="Log in"
          onPress={form.handleSubmit}
        />
        <Spacer size={0.6} />
        <View style={Style.alignCenter}>
          <LRText typeface="body2">or</LRText>
        </View>
        <Spacer size={0.6} />
        <LinkButton
          disabled={form.isLoading}
          title="Create account"
          buttonType="hollowAccent"
          to={{ screen: 'Auth.CreateAccount' }}
        />
      </CardPage>
    </View>
  );
}
