import type { ReadConfig } from 'api-read-hook';
import { useApiRead } from 'api-read-hook';

export type V_Detail = {
  '@id': string;
  '@type': 'VaultEntry';
  id: string;
  title: string;
  secureUpload: {
    '@id': string;
    '@type': 'SecureUpload';
    id: string;
    fileOriginalName: string | null;
  };
  instructions: string | null;
  isLocked: boolean;
  createdAt: string;
};

export type W_Create = {
  user: string;
  category: string;
  title: string;
  secureUpload: string;
  instructions: string | null;
  isLocked: boolean;
};

export type W_Update = {
  title: string;
  instructions: string | null;
};

export function useReadItem(id: string, options?: ReadConfig) {
  return useApiRead<V_Detail>(`/vault-entries/${id}`, options);
}
