import { ReadResult, useApiRead } from 'api-read-hook';
import * as React from 'react';

import { Api } from '../api';
import { ScreenError, ScreenLoading } from '../components';
import { useTypedSelector } from '../reducers';

/**
 * Global context which holds data from common API calls, without each
 * screen needing to call them individually.
 */
const LRDataContext = React.createContext<{
  apiUserOverview: ReadResult<Api.UserOverview.V>;
  userOverview: Api.UserOverview.V | undefined;
}>({
  apiUserOverview: undefined as any,
  userOverview: {} as any,
});

type Props = {
  children: React.ReactNode;
};

export function LRDataContextProvider({ children }: Props) {
  const auth = useTypedSelector((state) => state.auth);

  const apiUserOverview = useApiRead<Api.UserOverview.V>(
    auth.user ? `/users/${auth.managingUserId ?? auth.user.id}/overview` : null,
    { staleWhileInvalidated: true }
  );

  if (apiUserOverview.error) return <ScreenError {...apiUserOverview} />;

  // Essential data which the app can't function without
  if (auth.user && !apiUserOverview.data) return <ScreenLoading />;

  return (
    <LRDataContext.Provider
      value={{
        apiUserOverview,
        userOverview: apiUserOverview.data,
      }}
    >
      {children}
    </LRDataContext.Provider>
  );
}

export function useLRDataContext() {
  return React.useContext(LRDataContext);
}
