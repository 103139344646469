type Props = {
  color: string;
  size: number;
};

export default function Logotype({ color, size }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size - 1}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_493_9022)">
        <path
          d="M3.68394 23.5769V0.03125H0.03125V26.9669H0.664587H3.68394H17.7728V23.5769H3.68394Z"
          fill={color}
        />
        <path
          d="M7.56276 3.30968V0.03125H18.4835C21.3656 0.03125 23.6205 0.745455 25.2483 2.17348C26.8757 3.60189 27.6899 5.58219 27.6899 8.1158C27.6899 9.97855 27.2361 11.5562 26.3293 12.8469C25.4219 14.1389 24.1359 15.0579 22.4718 15.604L27.9505 26.9669H23.888L18.8561 16.312H14.3837V13.0707H18.5204C20.2101 13.0707 21.5207 12.6363 22.4526 11.7668C23.3845 10.8976 23.8503 9.69341 23.8503 8.15297C23.8503 6.61254 23.3716 5.39004 22.4154 4.55764C21.4585 3.72588 20.1478 3.30968 18.4832 3.30968H7.5625H7.56276Z"
          fill={color}
        />
        <path
          d="M18.856 6.55078H10.0256H7.5625V19.8892H11.2152V9.94097H18.856V6.55078Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_493_9022">
          <rect width="28" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
