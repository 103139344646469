export default function IconPaperClip() {
  return (
    <svg
      width="8"
      height="15"
      viewBox="0 0 8 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.95625 14.9621C2.85625 14.9621 1.922 14.5776 1.1535 13.8086C0.3845 13.0401 0 12.1121 0 11.0246V2.90586C0 2.11836 0.28125 1.44336 0.84375 0.880859C1.40625 0.318359 2.08125 0.0371094 2.86875 0.0371094C3.65625 0.0371094 4.33125 0.318359 4.89375 0.880859C5.45625 1.44336 5.7375 2.11836 5.7375 2.90586V10.2184C5.7375 10.7184 5.56575 11.1434 5.22225 11.4934C4.87825 11.8434 4.45625 12.0184 3.95625 12.0184C3.45625 12.0184 3.03425 11.8434 2.69025 11.4934C2.34675 11.1434 2.175 10.7184 2.175 10.2184V2.90586H3.05625V10.2184C3.05625 10.4809 3.14375 10.6966 3.31875 10.8656C3.49375 11.0341 3.70625 11.1184 3.95625 11.1184C4.20625 11.1184 4.41875 11.0341 4.59375 10.8656C4.76875 10.6966 4.85625 10.4809 4.85625 10.2184V2.90586C4.85625 2.35586 4.66575 1.89011 4.28475 1.50861C3.90325 1.12761 3.43125 0.937109 2.86875 0.937109C2.31875 0.937109 1.85325 1.12761 1.47225 1.50861C1.09075 1.89011 0.9 2.35586 0.9 2.90586V11.0246C0.9 11.8621 1.197 12.5809 1.791 13.1809C2.3845 13.7809 3.10625 14.0809 3.95625 14.0809C4.80625 14.0809 5.52825 13.7809 6.12225 13.1809C6.71575 12.5809 7.0125 11.8621 7.0125 11.0246V2.90586H7.9125V11.0246C7.9125 12.1121 7.52825 13.0401 6.75975 13.8086C5.99075 14.5776 5.05625 14.9621 3.95625 14.9621Z"
        fill="#1C1B1F"
      />
    </svg>
  );
}
