export default function IconMediaDrag() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 16L5 12.5L6.0625 11.4375L7.75 13.125V8.75H3.375L5.0625 10.4375L4 11.5L0.5 8L4 4.5L5.0625 5.5625L3.375 7.25H7.75V2.875L6.0625 4.5625L5 3.5L8.5 0L12 3.5L10.9375 4.5625L9.25 2.875V7.25H13.625L11.9375 5.5625L13 4.5L16.5 8L13 11.5L11.9375 10.4375L13.625 8.75H9.25V13.125L10.9375 11.4375L12 12.5L8.5 16Z"
        fill="#3E3F51"
      />
    </svg>
  );
}
