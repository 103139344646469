import React from 'react';
import { Image, StyleSheet, View } from 'react-native';

import { Api } from '../../../api';
import {
  ActionsDropdown,
  LRText,
  PaddedArea,
  Spacer,
} from '../../../components';
import { DropdownAction } from '../../../components/ActionsDropdown';
import { IconAvatar } from '../../../components/icons';
import { Color, Dimen, Style } from '../../../constants';
import { useUserOverview } from '../../../hooks';
import { iriToId } from '../../../util';

type Props = {
  album: Api.Album.V_Detail;
  allowedActions: DropdownAction[];
};

export default function SharedWithHeader({ album, allowedActions }: Props) {
  const { managingUser } = useUserOverview();

  const friendAvatar = album.friendship?.friend.avatar?.pathAvatar ?? undefined;
  const userAvatar = album.friendship?.user.avatar?.pathAvatar ?? undefined;
  let isOwner = false;
  if (
    album.friendship &&
    managingUser.id === iriToId(album.friendship.user['@id'])
  ) {
    isOwner = true;
  }

  return (
    <PaddedArea
      style={[
        Style.row,
        Style.alignCenter,
        {
          backgroundColor: Color.lightGrey,
          paddingLeft: Dimen.spacing,
          paddingRight: Dimen.spacing * 0.5,
          paddingVertical: Dimen.spacing * 0.5,
          borderRadius: Dimen.cornerRadiusLarge,
          zIndex: 10,
        },
      ]}
    >
      {isOwner ? (
        userAvatar ? (
          <Image source={userAvatar as any} style={styles.avatar} />
        ) : (
          <IconAvatar size={42} />
        )
      ) : friendAvatar ? (
        <Image source={friendAvatar as any} style={styles.avatar} />
      ) : (
        <IconAvatar size={42} />
      )}
      <View style={{ left: -16 }}>
        {!isOwner ? (
          userAvatar ? (
            <Image source={userAvatar as any} style={styles.avatar} />
          ) : (
            <IconAvatar size={42} />
          )
        ) : friendAvatar ? (
          <Image source={friendAvatar as any} style={styles.avatar} />
        ) : (
          <IconAvatar size={42} />
        )}
      </View>
      <View>
        <LRText typeface="captionM">Shared with</LRText>
        <LRText typeface="h3">
          {isOwner
            ? `${album.friendship?.friend.firstName} ${album.friendship?.friend.lastName}`
            : `${album.friendship?.user.firstName} ${album.friendship?.user.lastName}`}
        </LRText>
      </View>
      <Spacer fill />
      <ActionsDropdown actions={allowedActions} />
    </PaddedArea>
  );
}

const styles = StyleSheet.create({
  avatar: {
    height: 42,
    width: 42,
    borderRadius: 21,
    backgroundColor: Color.gray2,
    border: `3px solid ${Color.whiteHigh}`,
    overflow: 'hidden',
  },
});
