import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useCallback } from 'react';
import React from 'react';
import { toast } from 'react-hot-toast';
import { Linking, View } from 'react-native';
import { useForm } from 'react-typed-form';

import { Api, useApiCallable, useApiFormSubmit } from '../../api';
import {
  Button,
  ErrorList,
  LRText,
  PaddedArea,
  Spacer,
} from '../../components';
import { useLRDataContext } from '../../contexts';
import { FieldAvatar, FieldTextRequired, SubmitButton } from '../../form';
import { useManaging, useUserOverview } from '../../hooks';
import { MainStackParamList } from '../../navigation';

type Props = NativeStackScreenProps<MainStackParamList, 'Home.Settings'>;

type FormShape = Omit<Api.User.W_Update, 'roomPin'> & {
  roomPin: string;
};

export default function SettingsScreen({}: Props) {
  const { apiUserOverview } = useLRDataContext();
  const { actingAsTrustee } = useManaging();
  const { managingUser } = useUserOverview();
  const [manageLink, setManageLink] = React.useState<string | null>(null);

  const onSubmit = useApiFormSubmit<FormShape>(
    'PUT',
    `/users/${managingUser.id}`,
    {
      onSuccess: () => {
        toast.success('Settings updated successfully');
        apiUserOverview.invalidate();
      },
    }
  );
  const form = useForm<FormShape>({
    defaultValues: {
      avatar: managingUser.avatar?.['@id'] ?? null,
      roomSlug: managingUser.roomSlug,
      roomPin: managingUser.roomPin ?? '',
    },
    onSubmit,
  });

  const callApi = useApiCallable();
  const getCheckoutLink = useCallback(async () => {
    const response = await callApi<{ url: string }, unknown>(
      '/subscription/manage',
      {
        method: 'POST',
        jsonBody: {
          key: 'core',
        },
      }
    );

    if (response && response.data) {
      setManageLink(response.data.url);
    }
  }, [callApi, setManageLink]);

  React.useEffect(() => {
    if (!actingAsTrustee) {
      getCheckoutLink();
    }
  }, [actingAsTrustee, getCheckoutLink]);

  return (
    <PaddedArea h v>
      {!actingAsTrustee && (
        <>
          <LRText typeface="h1" color="accent">
            Subscription
          </LRText>
          <Spacer />
          <View style={{ alignItems: 'flex-start' }}>
            <Button
              buttonSize="small"
              buttonType="tertiary"
              title="Manage Subscription"
              loading={!manageLink}
              onPress={() => {
                if (manageLink) {
                  Linking.openURL(manageLink);
                }
              }}
            />
          </View>
          <Spacer size={2} />
        </>
      )}
      <LRText typeface="h1" color="accent">
        Settings
      </LRText>
      <Spacer size={2} />
      <LRText color="darkGray" textAlign="center" typeface="body1">
        Profile photo
      </LRText>
      <Spacer />
      <View style={{ alignItems: 'center' }}>
        <FieldAvatar
          field={form.getField('avatar')}
          existingImageUrl={managingUser.avatar?.pathAvatar}
        />
      </View>
      <Spacer />
      <FieldTextRequired label="Room link" field={form.getField('roomSlug')} />
      <Spacer />
      <FieldTextRequired label="Room pin" field={form.getField('roomPin')} />
      <Spacer />
      <SubmitButton title="Save" {...form} />
      <Spacer />
      <ErrorList errorList={form.formErrorList} />
    </PaddedArea>
  );
}
