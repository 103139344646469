import type { ReadConfig } from 'api-read-hook';
import { useApiRead } from 'api-read-hook';

export type V = {
  '@id': string;
  '@type': 'VaultCategoryExplore';
  id: string;
  name: string;
  childCategories: Array<{
    '@id': string;
    '@type': 'VaultCategoryExploreChild';
    id: string;
    name: string;
    userEntries: Array<{
      '@id': string;
      '@type': 'VaultEntry';
      id: string;
      title: string;
      createdAt: string;
    }>;
  }>;
};

export function useReadItemVaultCategoriesCategoryIdexplore(
  userId: string,
  categoryId: string,
  options?: ReadConfig
) {
  return useApiRead<V>(
    `/users/${userId}/vault-categories/${categoryId}/explore`,
    options
  );
}
