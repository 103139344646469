import { useNavigation } from '@react-navigation/native';
import { View } from 'react-native';
import { useForm } from 'react-typed-form';

import { Api, useApiFormSubmit } from '../../../api';
import { LRText, LinkButton, PaddedArea, Spacer } from '../../../components';
import { Style } from '../../../constants';
import {
  FieldMediaOrganiser,
  FieldText,
  FieldTextRequired,
  SubmitButton,
} from '../../../form';
import { useDynamicPageTitle, useUserOverview } from '../../../hooks';

type Props = {
  existing: Api.Album.V_Detail;
};

export default function EditAlbumLoaded({ existing }: Props) {
  useDynamicPageTitle(`Edit Album: ${existing.title}`);
  const navigation = useNavigation();
  const { managingUser } = useUserOverview();

  const onSubmit = useApiFormSubmit<Api.Album.W_Create>(
    'PUT',
    `/albums/${existing.id}`,
    {
      onSuccess: () => {
        navigation.navigate('Albums.ViewAlbum', { albumId: existing.id });
      },
    }
  );
  const form = useForm<Api.Album.W_Create>({
    defaultValues: {
      user: managingUser['@id'],
      title: existing.title,
      story: existing.story,
      friendship: existing.friendship?.['@id'] ?? null,
      roomTrigger: existing.roomTrigger
        ? {
            user: managingUser['@id'],
            type: existing.roomTrigger['@type'],
            slot: existing.roomTrigger.slot,
          }
        : null,
      albumMedias: existing.albumMedias,
    },
    onSubmit,
  });

  return (
    <PaddedArea v h>
      <LRText typeface="h1" color="accent">
        Edit Album
      </LRText>
      <Spacer size={0.5} />
      <LRText typeface="body2" color="gray7">
        {
          'Add up to 50 photos/videos\n\nThese photos and videos will be shown in your room.'
        }
      </LRText>
      <FieldMediaOrganiser
        // @ts-expect-error
        field={form.getField('albumMedias')}
      />
      <Spacer size={1.5} />
      <FieldTextRequired field={form.getField('title')} />
      <Spacer size={0.5} />
      <FieldText field={form.getField('story')} multiline />
      <Spacer />
      <View style={Style.row}>
        <View style={{ flex: 2 }}>
          <LinkButton
            title="Cancel"
            buttonType="tertiary"
            to={{
              screen: 'Albums.ViewAlbum',
              params: { albumId: existing.id },
            }}
          />
        </View>
        <Spacer size={0.5} />
        <View style={{ flex: 3 }}>
          <SubmitButton {...form} disabled={form.isLoading} title="Save" />
        </View>
      </View>
    </PaddedArea>
  );
}
