import * as React from 'react';
import { Pressable, StyleSheet } from 'react-native';

import { Color, Dimen } from '../constants';

type Props = React.ComponentProps<typeof Pressable>;

/**
 * Round purple button you can place an icon in the middle of
 */
export default function IconButton({ ...rest }: Props) {
  return <Pressable style={styles.button} {...rest} />;
}

const styles = StyleSheet.create({
  button: {
    width: 36,
    height: 36,
    borderRadius: Dimen.cornerRadiusExtraLarge,
    backgroundColor: Color.accent,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
