import { ImageBackground, StyleSheet } from 'react-native';

import { Upload } from '../../../api/endpoints';
import { LRText, PaddedArea, PressableLink, Spacer } from '../../../components';
import { IconPlay } from '../../../components/icons';
import { Color, Dimen } from '../../../constants';
import { useLayoutContext } from '../../../contexts';
import { Model } from '../../../models';

type Props = {
  media: {
    '@id': string;
    '@type': 'AlbumMedia';
    id: string;
    description: string | null;
    upload: Upload.BaseView;
    position: number;
  };
  isCover: boolean;
  size: number;
  albumId: string;
};

export default function ViewAlbumItem({
  media,
  isCover,
  size,
  albumId,
}: Props) {
  const { isDesktopLayout } = useLayoutContext();

  return (
    <ImageBackground
      source={media.upload.pathOrganiserCover as any}
      style={[
        {
          height: size,
          width: size,
        },
        styles.backgroundImage,
      ]}
    >
      <PressableLink
        to={{
          screen: 'Albums.ViewMedia',
          params: { albumId: albumId, position: media.position },
        }}
        style={styles.container}
      >
        <PaddedArea
          style={[
            styles.container,
            isDesktopLayout ? styles.containerDesktop : styles.containerMobile,
          ]}
          v={0.5}
          h={0.5}
        >
          {isCover && (
            <PaddedArea v={0.5} h={0.5} style={styles.tag}>
              <LRText typeface="captionM" color="whiteHigh">
                Cover photo
              </LRText>
            </PaddedArea>
          )}
          {isCover && Model.Upload.isVideo(media.upload) && (
            <Spacer size={0.5} />
          )}
          {Model.Upload.isVideo(media.upload) && (
            <PaddedArea
              v={0.5}
              h={0.5}
              style={[styles.tag, styles.videoTagPadding]}
            >
              <LRText typeface="captionM" color="whiteHigh">
                Watch
              </LRText>
              <Spacer size={0.5} />
              <IconPlay />
            </PaddedArea>
          )}
        </PaddedArea>
      </PressableLink>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  backgroundImage: {
    backgroundColor: Color.imageLoadingBg,
  },
  container: {
    height: '100%',
    width: '100%',
  },
  containerDesktop: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerMobile: {
    justifyContent: 'flex-end',
  },
  tag: {
    height: 32,
    flexDirection: 'row',
    backgroundColor: 'rgba(0,0,0,0.8)',
    borderRadius: Dimen.cornerRadiusExtraLarge,
    borderWidth: 1,
    borderColor: Color.whiteHigh,
    alignItems: 'center',
    justifyContent: 'center',
  },
  videoTagPadding: {
    paddingVertical: 6.5,
    paddingRight: 5.5,
    paddingLeft: 10,
  },
});
