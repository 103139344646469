import { RouteProp } from '@react-navigation/native';
import * as React from 'react';
import { View } from 'react-native';
import { useForm } from 'react-typed-form';

import { Api, ApiError, useApiFormSubmit } from '../../api';
import {
  CardPage,
  ErrorList,
  LRText,
  LinkButton,
  Spacer,
} from '../../components';
import { Style } from '../../constants';
import { FieldTextRequired, SubmitButton } from '../../form';
import { MainStackParamList } from '../../navigation';

type FormShape = Api.ResetPasswordConfirm.W;

type Props = {
  route: RouteProp<MainStackParamList, 'Auth.ResetPassword'>;
};

export default function ResetPasswordScreen({ route }: Props) {
  const { token } = route.params;
  const [passwordReset, setPasswordReset] = React.useState(false);

  const onSubmit = useApiFormSubmit<FormShape>(
    'POST',
    '/reset-password/confirm',
    {
      onSuccess: () => {
        setPasswordReset(true);
      },
      errorHandlerCreator:
        ({ addSubmitError }) =>
        (err, originalHandler) => {
          if (
            err instanceof ApiError &&
            'data' in err.response &&
            err.response.data['hydra:description'] === 'Invalid link'
          ) {
            addSubmitError(
              '_form',
              'The link you followed was not valid, or may have already been used. Please check you followed the correct link, or request a new one.'
            );
          } else if (
            err instanceof ApiError &&
            'data' in err.response &&
            err.response.data['hydra:description'] === 'Expired link'
          ) {
            addSubmitError(
              '_form',
              'The link you followed has expired. You must use it within 1 hour. Please request a new link.'
            );
          } else {
            originalHandler(err);
          }
        },
    }
  );

  const form = useForm<FormShape>({
    defaultValues: {
      token,
      newPassword: '',
      confirmNewPassword: '',
    },
    validator: (values) => {
      if (values.newPassword !== values.confirmNewPassword) {
        return {
          confirmNewPassword: ['The passwords you entered do not match.'],
        };
      }
      return {};
    },
    onSubmit,
  });

  return (
    <View>
      <Spacer size={2} />
      {passwordReset ? (
        <>
          <View style={Style.alignCenter}>
            <View style={{ maxWidth: 200 }}>
              <LRText typeface="h1" textAlign="center" color="whiteHigh">
                Password Reset!
              </LRText>
            </View>
          </View>
          <Spacer />
          <CardPage>
            <LRText typeface="body1">
              All done, your password has been updated. You can now sign in with
              your new password.
            </LRText>
            <Spacer />
            <LinkButton
              disabled={form.isLoading}
              title="Back to login"
              buttonType="hollowAccent"
              to={{ screen: 'Auth.Login' }}
            />
          </CardPage>
        </>
      ) : (
        <>
          <View style={Style.alignCenter}>
            <View
              style={{
                maxWidth: 200,
              }}
            >
              <LRText typeface="h1" textAlign="center" color="whiteHigh">
                Set a New Password
              </LRText>
            </View>
          </View>
          <Spacer />
          <CardPage>
            <FieldTextRequired
              autoCapitalize="none"
              field={form.getField('newPassword')}
            />
            <Spacer />
            <FieldTextRequired
              autoCapitalize="none"
              field={form.getField('confirmNewPassword')}
            />
            <ErrorList errorList={form.formErrorList} />
            <Spacer />
            <SubmitButton {...form} />
            <Spacer size={0.6} />
            <LinkButton
              disabled={form.isLoading}
              title="Back to login"
              buttonType="hollowAccent"
              to={{ screen: 'Auth.Login' }}
            />
          </CardPage>
        </>
      )}
    </View>
  );
}
