export default function IconMediaDelete() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_503_19129"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="17"
        height="16"
      >
        <rect x="0.5" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_503_19129)">
        <path
          d="M4.76659 12.6666L3.83325 11.7333L7.56659 7.99998L3.83325 4.26665L4.76659 3.33331L8.49992 7.06665L12.2333 3.33331L13.1666 4.26665L9.43325 7.99998L13.1666 11.7333L12.2333 12.6666L8.49992 8.93331L4.76659 12.6666Z"
          fill="#3E3F51"
        />
      </g>
    </svg>
  );
}
