import { NavigationProp, RouteProp } from '@react-navigation/native';
import * as React from 'react';
import { useForm } from 'react-typed-form';

import { Api, useApiFormSubmit } from '../../../api';
import {
  ConfirmationModal,
  LRText,
  PaddedArea,
  Spacer,
} from '../../../components';
import { Style } from '../../../constants';
import { useLayoutContext } from '../../../contexts';
import { FieldTextRequired, SubmitButton } from '../../../form';
import { MainStackParamList } from '../../../navigation';

type FormShape = Api.TrusteeInvite.W_Create;

type Props = {
  route: RouteProp<MainStackParamList, 'Trustees.AddTrustee'>;
  navigation: NavigationProp<MainStackParamList>;
};

export default function AddTrusteeScreen({ navigation }: Props) {
  const [shouldShowConfirmation, setShouldShowConfirmation] =
    React.useState(false);
  const { isDesktopLayout } = useLayoutContext();

  const onSubmit = useApiFormSubmit<FormShape>('POST', '/trustee-invites', {
    onSuccess: () => setShouldShowConfirmation(true),
  });
  const form = useForm<FormShape>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    onSubmit: (values, submittedForm) => {
      onSubmit(values, submittedForm);
      setShouldShowConfirmation(true);
    },
  });

  return (
    <>
      <PaddedArea v h style={Style.fill}>
        <LRText typeface="h1" color={'accent400'} textAlign="center">
          Add a trustee
        </LRText>
        <Spacer size={2.5} />
        <LRText textAlign="center">
          We will send your trustee an invitation asking them to act as a
          trustee for you (a trustee is someone with access to your Legacy
          Room).
        </LRText>
        <Spacer />
        <FieldTextRequired field={form.getField('firstName')} />
        <Spacer size={0.5} />
        <FieldTextRequired field={form.getField('lastName')} />
        <Spacer size={0.5} />
        <FieldTextRequired
          autoCapitalize="none"
          field={form.getField('email')}
        />
        <Spacer size={2} fill={!isDesktopLayout} />
        <SubmitButton title="Add Trustee" {...form} />
      </PaddedArea>
      <ConfirmationModal
        visible={shouldShowConfirmation}
        title={'Trustee invitation'}
        message={'The trustee will receive an email about this request'}
        cancelText="Ok"
        onCancel={() => {
          setShouldShowConfirmation(false);
          navigation.navigate('Trustees.TrusteesList');
        }}
      />
    </>
  );
}
