import * as React from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import type { FieldProp } from 'react-typed-form';

import { Spacer } from '../components';
import { IconCheck } from '../components/icons';
import { Color, Dimen } from '../constants';

type ConditionalProps =
  | {
      label: string;
      customLabel?: undefined;
    }
  | {
      label?: undefined;
      customLabel: React.ReactNode;
    };

type Props = {
  style?: StyleProp<ViewStyle>;
  field: FieldProp<boolean>;
  disabled?: boolean;
} & ConditionalProps;

export default function FieldCheckbox({
  style,
  field,
  label,
  customLabel,
  disabled,
}: Props) {
  const isInputDisabled = field.isLoading || disabled;
  return (
    <View style={style}>
      <View style={styles.contentContainer}>
        <TouchableOpacity
          disabled={isInputDisabled}
          onPress={() => {
            field.handleValueChange(!field.value);
          }}
          style={[styles.checkbox, !field.value && styles.emptyCheckbox]}
        >
          {field.value && <IconCheck color={Color.whiteHigh} />}
        </TouchableOpacity>
        <Spacer size={0.4} />
        {customLabel ? customLabel : <Text>{label}</Text>}
      </View>

      {field.lastErrorList &&
        field.lastErrorList.map((text) => <Text key={text}>{text}</Text>)}
    </View>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tick: {
    width: 18,
    height: 18,
  },
  checkbox: {
    height: 18,
    width: 18,
    backgroundColor: Color.accent,
    borderRadius: Dimen.cornerRadiusSmall,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyCheckbox: {
    backgroundColor: 'transparent',
    borderColor: Color.accent,
    borderWidth: 1,
  },
});
