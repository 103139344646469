import { AppLoader } from './src/app';

import * as Sentry from 'sentry-expo';

Sentry.init({
  dsn: "https://9ee0760af657487704e012ceb4cf03da@o4506774605725696.ingest.sentry.io/4506774632529920",
  enableInExpoDevelopment: false,
  debug: __DEV__,
});


export default AppLoader;
