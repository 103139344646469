import { useNavigation } from '@react-navigation/native';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-hot-toast';
import { View } from 'react-native';
import { useForm } from 'react-typed-form';

import { Api, useApiFormSubmit } from '../../../api';
import {
  LRText,
  LinkButton,
  PaddedArea,
  Seperator,
  Spacer,
} from '../../../components';
import { Color, Style } from '../../../constants';
import { FieldText, FieldTextRequired, SubmitButton } from '../../../form';
import FileDetails from './FileDetails';

type FormShape = Api.VaultEntry.W_Update;

type Props = {
  exisiting: Api.VaultEntry.V_Detail;
};

export default function EditFileLoaded({ exisiting }: Props) {
  const navigation = useNavigation();
  const onSubmit = useApiFormSubmit<FormShape>(
    'PUT',
    `/vault-entries/${exisiting.id}`,
    {
      onSuccess: () => {
        navigation.navigate('Vault.ViewFile', { fileId: exisiting.id });
        toast.success('File updated');
      },
    }
  );
  const form = useForm<FormShape>({
    defaultValues: {
      title: exisiting.title,
      instructions: exisiting.instructions,
    },
    onSubmit,
  });

  return (
    <PaddedArea v h>
      <LRText typeface="h1" color="accent">
        Edit File
      </LRText>
      <Spacer />
      <FieldTextRequired field={form.getField('title')} />
      <Spacer />
      <PaddedArea h={0.5}>
        <LRText color="darkGray" typeface="body1">
          Uploaded File
        </LRText>
        <Spacer />
        <FileDetails
          fileName={exisiting.title}
          createdAt={format(parseISO(exisiting.createdAt), 'dd/MM/yyyy')}
        />
      </PaddedArea>
      <Spacer size={0.5} />
      <Seperator color={Color.gray} />
      <Spacer />
      <FieldText
        label="Notes and Instructions"
        multiline
        field={form.getField('instructions')}
      />
      <Spacer />
      <View style={Style.row}>
        <View style={{ flex: 2 }}>
          <LinkButton
            title="Cancel"
            buttonType="tertiary"
            to={{
              screen: 'Vault.ViewFile',
              params: { fileId: exisiting.id },
            }}
          />
        </View>
        <Spacer size={0.5} />
        <View style={{ flex: 3 }}>
          <SubmitButton {...form} title="Update" />
        </View>
      </View>
    </PaddedArea>
  );
}
