import { NavigationProp, RouteProp } from '@react-navigation/native';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { Api, useApiCallable } from '../../api';
import {
  ActionsDropdown,
  ConfirmationModal,
  LRText,
  PaddedArea,
  PressableLink,
  ScreenError,
  ScreenLoading,
  Spacer,
} from '../../components';
import {
  IconArrow,
  IconClose,
  IconDelete,
  IconPencil,
  IconPhotoLibrary,
} from '../../components/icons';
import { Color, Style } from '../../constants';
import { Model } from '../../models';
import { MainStackParamList } from '../../navigation';
import { iriToId } from '../../util';
import { MediaView } from './components';

type Props = {
  navigation: NavigationProp<MainStackParamList>;
  route: RouteProp<MainStackParamList, 'Albums.ViewMedia'>;
};

export default function ViewMediaScreen({ navigation, route }: Props) {
  const { albumId, position } = route.params;
  const callApi = useApiCallable();

  const [modal, setModal] = React.useState<'delete' | 'makeCover' | null>(null);

  const api = Api.Album.useReadItem(albumId);
  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ScreenLoading />;
  const album = api.data;

  const media = album.albumMedias.find((am) => am.position === position);
  const canPrev = position > 1;
  const canNext = position < album.albumMedias.length;
  const mediumName =
    media && Model.Upload.isVideo(media.upload) ? 'Video' : 'Photo';
  const canDelete = album.albumMedias.length > 1;

  async function onDeleteConfirm() {
    if (!media) return;
    await callApi(`/album-media/${media.id}`, { method: 'DELETE' });
    api.invalidate();
    setModal(null);
    if (!canNext) {
      navigation.navigate('Albums.ViewMedia', {
        albumId,
        position: position - 1,
      });
    }
  }

  async function onMakeCoverConfirm() {
    if (!media) return;
    await callApi(`/album-media/${media.id}/make-cover`, {
      method: 'POST',
      jsonBody: {},
    });
    api.invalidate();
    setModal(null);
  }

  return (
    <>
      <View>
        <PaddedArea
          h
          v
          style={[
            Style.row,
            Style.alignCenter,
            Style.spaceBetween,
            { zIndex: 20 },
          ]}
        >
          <PressableLink
            to={{ screen: 'Albums.ViewAlbum', params: { albumId } }}
            style={[Style.row, Style.alignCenter, { height: 40 }]}
          >
            <LRText typeface="captionR">Close</LRText>
            <Spacer size={0.25} />
            <IconClose />
          </PressableLink>
          {media && (
            <ActionsDropdown
              actions={[
                {
                  label: 'Edit',
                  icon: IconPencil,
                  to: {
                    screen: 'Albums.EditMedia',
                    params: { mediaId: iriToId(media['@id']) },
                  },
                },
                {
                  label: 'Make cover photo',
                  icon: IconPhotoLibrary,
                  onPress: () => setModal('makeCover'),
                },
                {
                  label: 'Delete',
                  icon: IconDelete,
                  onPress: () => setModal('delete'),
                },
              ]}
            />
          )}
        </PaddedArea>
        {media && <MediaView media={media} />}
        <PaddedArea v h style={Style.alignCenter}>
          <View
            style={[
              Style.row,
              Style.alignCenter,
              Style.spaceBetween,
              { maxWidth: 260, width: '100%' },
            ]}
          >
            <PressableLink
              disabled={!canPrev}
              to={{
                screen: 'Albums.ViewMedia',
                params: { albumId, position: position - 1 },
              }}
              style={({ hovered }) => [
                styles.arrowButton,
                hovered && canPrev && styles.arrowButtonHovered,
                !canPrev && styles.arrowButtonDisabled,
              ]}
            >
              <IconArrow dir="left" color={Color.accent400} />
            </PressableLink>
            <LRText color="accent400">
              {position} / {album.albumMedias.length}
            </LRText>
            <PressableLink
              disabled={!canNext}
              to={{
                screen: 'Albums.ViewMedia',
                params: { albumId, position: position + 1 },
              }}
              style={({ hovered }) => [
                styles.arrowButton,
                hovered && canNext && styles.arrowButtonHovered,
                !canNext && styles.arrowButtonDisabled,
              ]}
            >
              <IconArrow dir="right" color={Color.accent400} />
            </PressableLink>
          </View>
          <Spacer size={1.5} />
          <LRText textAlign="center" style={{ maxWidth: 350 }}>
            {media?.description}
          </LRText>
        </PaddedArea>
      </View>
      <ConfirmationModal
        visible={modal === 'makeCover'}
        title="Make cover picture?"
        message="You can change your cover picture at any time."
        onConfirm={onMakeCoverConfirm}
        onCancel={() => setModal(null)}
      />
      <ConfirmationModal
        visible={modal === 'delete'}
        title={
          canDelete
            ? `Are you sure you want to delete this ${mediumName}?`
            : `You can't delete this ${mediumName}`
        }
        message={
          canDelete
            ? `${mediumName} will be permanently deleted.`
            : `This is the last ${mediumName} so it can't be deleted. You may want
        to delete the whole album instead.`
        }
        onConfirm={canDelete ? onDeleteConfirm : undefined}
        onCancel={() => setModal(null)}
      />
    </>
  );
}

const styles = StyleSheet.create({
  arrowButton: {
    width: 48,
    height: 48,
    borderRadius: 24,
    alignItems: 'center',
    justifyContent: 'center',
    transitionDuration: '200ms',
  },
  arrowButtonHovered: {
    backgroundColor: Color.lightGrey,
  },
  arrowButtonDisabled: {
    opacity: 0.25,
  },
});
