import type { ReadConfig } from 'api-read-hook';
import { useApiRead } from 'api-read-hook';

export type V = {
  '@id': string;
  '@type': 'TrusteeInvite';
  owner: {
    '@id': string;
    '@type': 'User';
    firstName: string;
    lastName: string;
  };
};

export type W_Create = {
  firstName: string;
  lastName: string;
  email: string;
};

export function useReadItem(id: string, options?: ReadConfig) {
  return useApiRead<V>(`/trustee-invites/${id}`, options);
}
