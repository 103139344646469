import React from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';

import { Api } from '../../../api';
import {
  LRText,
  PaddedArea,
  ScreenError,
  ScreenLoading,
  Seperator,
  Spacer,
} from '../../../components';
import {
  IconAvatar,
  IconRadioEmpty,
  IconRadioFilled,
} from '../../../components/icons';
import { Style } from '../../../constants';
import { useUserOverview } from '../../../hooks';
import { useTypedDispatch } from '../../../reducers';

export default function ManageRoomsScreen() {
  const dispatch = useTypedDispatch();
  const { managingUser, myUser } = useUserOverview();

  const api = Api.User.useReadCollectionManageableUsers(myUser.id);
  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ScreenLoading />;
  const manageableUsers = api.data['hydra:member'];

  return (
    <PaddedArea v h style={Style.fill}>
      <LRText typeface="h1" color="accent">
        Manage other rooms
      </LRText>
      <Spacer />

      <TouchableOpacity
        onPress={() => {
          dispatch({ type: 'SET_MANAGING_USER_ID', payload: null });
        }}
        style={[Style.row, Style.alignCenter]}
      >
        {myUser.avatar ? (
          <Image
            style={styles.avatar}
            source={myUser.avatar.pathAvatar as any}
          />
        ) : (
          <IconAvatar size={36} />
        )}
        <Spacer size={0.5} />
        {managingUser.id === myUser.id ? (
          <IconRadioFilled />
        ) : (
          <IconRadioEmpty />
        )}
        <Spacer size={0.5} />
        <LRText>My Room</LRText>
      </TouchableOpacity>

      <Spacer />
      <Seperator />
      <Spacer />
      <LRText typeface="h3">Other Rooms</LRText>
      <Spacer size={0.5} />
      <LRText typeface="body2" color="gray7">
        Any users who you act as Trustee for will appear here
      </LRText>
      <Spacer />
      <>
        {manageableUsers.map((user) => {
          return (
            <View key={user['@id']}>
              <TouchableOpacity
                onPress={() => {
                  dispatch({ type: 'SET_MANAGING_USER_ID', payload: user.id });
                }}
                style={[Style.row, Style.alignCenter]}
              >
                {user.avatar ? (
                  <Image
                    style={styles.avatar}
                    source={user.avatar.pathAvatar as any}
                  />
                ) : (
                  <IconAvatar size={36} />
                )}
                <Spacer size={0.5} />
                {managingUser['@id'] === user['@id'] ? (
                  <IconRadioFilled />
                ) : (
                  <IconRadioEmpty />
                )}
                <Spacer size={0.5} />
                <LRText>
                  {user.firstName} {user.lastName}
                </LRText>
              </TouchableOpacity>
              <Spacer />
            </View>
          );
        })}
      </>
    </PaddedArea>
  );
}

const styles = StyleSheet.create({
  avatar: {
    height: 36,
    width: 36,
    borderRadius: 18,
    overflow: 'hidden',
  },
});
