import * as React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Style } from '../constants';
import { useLayoutContext } from '../contexts';
import { useTypedSelector } from '../reducers';
import LinkButton from './LinkButton';
import LRText from './LRText';
import PaddedArea from './PaddedArea';
import ScreenOnboarding from './ScreenOnboarding';
import Spacer from './Spacer';
import { useUserOverview } from '../hooks';

const IntroImage = require('../../assets/img/pods/digitalVault/two-factor-authentication-intro-image.png');

export default function ScreenTwoFactorIntro() {
  const { isDesktopLayout } = useLayoutContext();
  const { myUser } = useUserOverview();
  const { completedScreens } = useTypedSelector((state) => state.onboarding);
  const shouldShowOnboarding = !completedScreens.includes('digital_vault');
  
  if (myUser.phoneverified) {
    window.location.href = '/2fa/request-code';
  }

  if (shouldShowOnboarding) {
    return (
      <PaddedArea style={Style.fill} v h>
        <ScreenOnboarding screen="digital_vault" />
      </PaddedArea>
    );
  }

  return (
    <PaddedArea style={Style.fill} v h>
      <View style={[Style.alignCenter, Style.fill]}>
        <Spacer size={4} />
        <Image style={styles.introImage} source={IntroImage} />
        <Spacer />
        <LRText textAlign="center" typeface="subheader">
          Secure your Digital Vault with two-step verification
        </LRText>
        <Spacer />
        <LRText textAlign="center" typeface="body1">
          Two step verification gives you additional security by requiring a
          verification code whenever you log in to Digital Vault.
        </LRText>
        <Spacer size={2} fill={!isDesktopLayout} />
        <View style={!isDesktopLayout && Style.fullWidth}>
          <LinkButton
            title={'Set up'}
            to={{ screen: 'Account.TwoFactorRequestCode' }}
          />
        </View>
      </View>
    </PaddedArea>
  );
}

const styles = StyleSheet.create({
  confirmationButton: {
    flex: 3,
  },
  cancelButton: {
    flex: 2,
  },
  introImage: {
    width: 200,
    height: 200,
  },
});
