import { capitalize } from '../util';

export function typeToReadableName(
  type: string,
  options: { plural?: boolean } = {}
): string {
  const { plural } = options;
  if (type === 'welcome_note') return 'Welcome Note';
  if (type === 'friends_wall') return 'Friends Wall';

  return capitalize(`${type.replace('_', ' ')}${plural ? 's' : ''}`);
}
