import * as React from 'react';
import {
  ActivityIndicator,
  Pressable,
  StyleProp,
  StyleSheet,
  TextStyle,
  ViewStyle,
} from 'react-native';

import { Color, Dimen, Style } from '../constants';
import LRText from './LRText';
import Spacer from './Spacer';

export type ButtonType =
  | 'hollowAccent'
  | 'hollowWhite'
  | 'primary'
  | 'text'
  | 'link'
  | 'red900'
  | 'tertiary'
  | 'lightGrey';

export type ButtonSize = 'regular' | 'small' | 'tiny';

const getButtonRadius = (buttonSize: ButtonSize): StyleProp<ViewStyle> => {
  switch (buttonSize) {
    case 'tiny':
      return { borderRadius: Dimen.cornerRadiusSmall };
    default:
    case 'regular':
    case 'small':
      return { borderRadius: Dimen.cornerRadiusLarge };
  }
};

const getButtonPadding = (buttonSize: ButtonSize): StyleProp<ViewStyle> => {
  switch (buttonSize) {
    case 'tiny':
      return {
        paddingVertical: Dimen.spacing * 0.25,
        paddingHorizontal: Dimen.spacing * 0.5,
      };
    case 'small':
      return {
        paddingVertical: Dimen.spacing * 0.5,
        paddingHorizontal: Dimen.spacing * 1,
      };
    case 'regular':
      return {
        paddingVertical: Dimen.spacing,
        paddingHorizontal: Dimen.spacing * 1,
      };
  }
};

const getBackgroundColor = (buttonType: ButtonType): StyleProp<ViewStyle> => {
  switch (buttonType) {
    case 'hollowWhite':
      return {
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: Color.whiteHigh,
      };
    case 'tertiary':
    case 'lightGrey':
      return { backgroundColor: Color.lightGrey };
    case 'hollowAccent':
      return {
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: Color.accent,
      };
    case 'red900':
      return { backgroundColor: Color.red900 };
    case 'text':
    case 'link':
      return { backgroundColor: 'transparent' };
    case 'primary':
    default:
      return { backgroundColor: Color.accent };
  }
};

const getHoveredStyle = (buttonType: ButtonType): StyleProp<ViewStyle> => {
  switch (buttonType) {
    case 'hollowAccent':
      return {
        backgroundColor: Color.accent,
        borderColor: Color.accent,
      };
    case 'hollowWhite':
      return {
        backgroundColor: Color.whiteHigh,
        borderColor: Color.whiteHigh,
      };
    case 'primary':
      return { backgroundColor: Color.accentHovered };
    case 'text':
    case 'tertiary':
    case 'lightGrey':
      return { backgroundColor: Color.lightGreyHovered };
    default:
      return null;
  }
};

const getFontColor = (buttonType: ButtonType): keyof typeof Color => {
  switch (buttonType) {
    case 'hollowAccent':
    case 'text':
      return 'accent';
    case 'link':
      return 'gray';
    case 'tertiary':
      return 'accent';
    case 'lightGrey':
      return 'darkGray';
    default:
      return 'whiteHigh';
  }
};

const getHoveredFontColor = (buttonType: ButtonType): keyof typeof Color => {
  switch (buttonType) {
    case 'hollowWhite':
      return 'darkBg';
    case 'tertiary':
    case 'text':
      return 'accent';
    case 'link':
      return 'gray';
    default:
      return 'whiteHigh';
  }
};

const getFontStyle = (buttonType: ButtonType): TextStyle | undefined => {
  switch (buttonType) {
    case 'link':
      return { textDecorationLine: 'underline' };
    default:
      return undefined;
  }
};

type Props = {
  title?: string;
  onPress: () => void;
  icon?: React.ReactNode;
  disabled?: boolean;
  buttonType?: ButtonType;
  buttonSize?: ButtonSize;
  loading?: boolean;
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
};

export default function Button({
  title,
  onPress,
  icon,
  disabled = false,
  buttonType = 'primary',
  buttonSize = 'regular',
  loading,
  justifyContent = 'center',
}: Props) {
  return (
    <Pressable
      disabled={disabled || loading}
      onPress={onPress}
      style={({ hovered }: any) => [
        styles.button,
        getButtonRadius(buttonSize),
        getButtonPadding(buttonSize),
        getBackgroundColor(buttonType),
        hovered && !disabled && getHoveredStyle(buttonType),
        disabled && Style.disabled,
        { justifyContent },
      ]}
    >
      {({ hovered }: any) => (
        <>
          {loading ? (
            <ActivityIndicator color={'white'} />
          ) : (
            <>
              {title && (
                <LRText
                  textAlign="center"
                  typeface="button"
                  style={getFontStyle(buttonType)}
                  color={
                    hovered
                      ? getHoveredFontColor(buttonType)
                      : getFontColor(buttonType)
                  }
                >
                  {title}
                </LRText>
              )}
              {icon && (
                <>
                  {title && <Spacer size={0.5} />}
                  {icon}
                </>
              )}
            </>
          )}
        </>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    transitionDuration: '200ms',
  },
});
