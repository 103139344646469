import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { produce } from 'immer';
import * as React from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { StyleSheet, View, useWindowDimensions } from 'react-native';
import { FieldProp } from 'react-typed-form';

import { Api, ApiError, useApiCallable } from '../../api';
import { LRText, Spacer } from '../../components';
import { Color, Config, Dimen } from '../../constants';
import { useManaging } from '../../hooks';
import { useTypedDispatch } from '../../reducers';
import FieldMediaOrganiserItem from './FieldMediaOrganiserItem';

export type Media = {
  /**
   * Pre-exesting items only
   */
  albumMediaId?: string;
  /**
   * Upload UUID (pre-existing), or `new-{random numer}` if just created
   */
  id: string;
  /**
   * Either pre-existing AlbumMedia, or something which successfully uploaded
   * and returned a payload.  Do not mutate, just use as a reference.
   */
  uploadData?: Readonly<{
    id: string;
    rotation: number;
    pathOrganiserThumb: string | null;
  }>;
  uploadError?: unknown;
  /**
   * Newly uploaded files only
   */
  file?: File;
  rotation: number;
  isCover: boolean;
};

type Props = {
  field: FieldProp<
    // If one or more photos is still uploading, then whole field blocks
    // itself as "BUSY".
    | typeof BUSY
    | Array<{
        upload: {
          id: string;
          rotation: number;
          pathOrganiserThumb: string | null;
        };
        id?: string;
        position: number;
        isCover: boolean;
      }>
  >;
  existingPath?: string | null;
  disableDrag?: boolean;
};

export const BUSY = '_busy';

export default function FieldMediaOrganiser({ field, disableDrag }: Props) {
  const callApi = useApiCallable();
  const { managingId } = useManaging();
  const dispatch = useTypedDispatch();
  const { width: windowWidth } = useWindowDimensions();

  const containerWidth =
    Math.min(windowWidth, Dimen.contentMaxWidth) -
    // Normal outer `PaddedArea h`
    Dimen.spacing * 2 -
    // dottedAsea padding
    Dimen.spacing * 0.25 * 2 -
    // dottedArea border width
    2;
  const minItemWidth = 110;
  const itemsPerRow = Math.max(1, Math.floor(containerWidth / minItemWidth));
  const itemSize = Math.floor(containerWidth / itemsPerRow);

  const [medias, setMedias] = React.useState<Media[]>(
    !field.value || field.value.length === 0 || typeof field.value === 'string'
      ? // Create: Empty start
        []
      : // Edit: Pre-populating with existing media
        field.value.map((albumMedia) => {
          if (!albumMedia.upload.id) {
            throw new Error(
              `Passed field.value into FieldMediaOrganiser with no upload id ${JSON.stringify(
                albumMedia
              )}`
            );
          }
          return {
            albumMediaId: albumMedia.id,
            id: albumMedia.upload.id,
            uploadData: albumMedia.upload,
            rotation: albumMedia.upload.rotation,
            isCover: albumMedia.isCover,
          };
        })
  );

  const mediasRef = React.useRef(medias);
  React.useEffect(() => {
    mediasRef.current = medias;
  }, [medias]);

  const writeLatestValue = React.useCallback(() => {
    field.handleValueChange(
      mediasRef.current
        .filter((m) => m.uploadData)
        .map((m, i) => {
          if (!m.uploadData) throw new Error('Logic Error');
          const upload = m.uploadData;
          return {
            id: m.albumMediaId,
            upload,
            position: i + 1,
            isCover: m.isCover,
          };
        })
    );
  }, [field]);

  const handleNextUpload = React.useCallback(async () => {
    const next = mediasRef.current.find(
      (m) => m.file && !m.uploadData && !m.uploadError
    );
    if (next) {
      field.handleValueChange(BUSY);
    } else {
      writeLatestValue();
    }
    if (!next) return;

    const response = await callApi<Api.Upload.BaseView, unknown>(
      `/uploads?type=album_media&userId=${managingId}`,
      {
        method: 'POST',
        uploadFile: next.file,
      },
      {
        errorHandler: (err) => {
          if (err instanceof ApiError && err.response?.status === 401) {
            dispatch({ type: 'LOGOUT' });
          } else {
            setMedias((prev) =>
              produce(prev, (draft) => {
                const item = draft.find((m) => m.id === next.id);
                // May have been deleted in mean time
                if (item) {
                  item.uploadError =
                    err instanceof ApiError ? err.response.data : true;
                }
                return draft;
              })
            );
          }
        },
      }
    );

    if (response) {
      setMedias((prev) =>
        produce(prev, (draft) => {
          const item = draft.find((m) => m.id === next.id);
          // May have been deleted in mean time
          if (item) {
            item.uploadData = response.data;
          }
          return draft;
        })
      );
    }

    setTimeout(() => {
      handleNextUpload();
    }, 50);
  }, [callApi, dispatch, field, managingId, writeLatestValue]);

  const onDrop = React.useCallback(
    async (acceptedFiles: File[], _fileRejections: FileRejection[]) => {
      setMedias((prev) => [
        ...prev,
        ...acceptedFiles.map((af) => ({
          id: `new-${Math.floor(Math.random() * 100000)}`,
          file: af,
          rotation: 0,
          isCover: false,
        })),
      ]);
      setTimeout(() => {
        handleNextUpload();
      }, 0);
    },
    [handleNextUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: field.isLoading || field.value === BUSY,
    accept: {
      ...Config.ACCEPTED_MEDIA_TYPES.IMAGE_TYPE,
      ...Config.ACCEPTED_MEDIA_TYPES.VIDEO_TYPE,
    },
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setMedias((prev) => {
        const oldIndex = prev.findIndex((m) => m.id === active.id);
        const newIndex = prev.findIndex((m) => m.id === over?.id);

        setTimeout(() => {
          writeLatestValue();
        }, 0);
        return arrayMove(prev, oldIndex, newIndex);
      });
    }
  }

  return (
    <div style={{ ...styles.dottedOutline, touchAction: 'none' }}>
      <View style={styles.dropzone}>
        <div
          {...getRootProps({ isDragActive })}
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <input {...getInputProps()} />
          <LRText color="darkGray">Press here to select files</LRText>
        </div>
      </View>
      {medias.length > 0 && <Spacer />}

      <View style={styles.grid}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            disabled={disableDrag}
            items={medias}
            strategy={rectSortingStrategy}
          >
            {medias.map((media) => (
              <FieldMediaOrganiserItem
                key={media.id}
                media={media}
                onDelete={() => {
                  setMedias((prev) => prev.filter((m) => m.id !== media.id));
                  setTimeout(() => {
                    writeLatestValue();
                  }, 0);
                }}
                size={itemSize}
                disableDrag={disableDrag}
              />
            ))}
          </SortableContext>
        </DndContext>
      </View>
    </div>
  );
}

const styles = StyleSheet.create({
  dottedOutline: {
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: Color.lightGrey,
    padding: Dimen.spacing * 0.25,
  },
  dropzone: {
    backgroundColor: Color.lightGrey,
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  grid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    // marginHorizontal: -Dimen.spacing * 0.125,
  },
});
