import { Image, StyleSheet, View } from 'react-native';

import {
  LRText,
  LinkButton,
  PaddedArea,
  PressableLink,
  Spacer,
} from '../../../components';
import { IconAvatar } from '../../../components/icons';
import { Color, Dimen, Style } from '../../../constants';
import { useLayoutContext } from '../../../contexts';
import { useUserOverview } from '../../../hooks';

export default function TrusteeButton() {
  const { myUserFeaturedTrustees, myUserTrusteesCount } = useUserOverview();
  const { isDesktopLayout } = useLayoutContext();

  const trusteesToShow =
    myUserFeaturedTrustees.length > 4
      ? myUserFeaturedTrustees.slice(0, 4)
      : myUserFeaturedTrustees;

  if (trusteesToShow.length === 0) {
    return (
      <PaddedArea
        style={[
          Style.alignCenter,
          styles.container,
          {
            backgroundColor: isDesktopLayout
              ? Color.accent400
              : Color.whiteHigh,
          },
        ]}
        h
        v
      >
        <LRText
          color={isDesktopLayout ? 'whiteHigh' : 'accent400'}
          typeface="h2"
        >
          Your Trustees
        </LRText>
        <Spacer size={0.5} />
        <LRText
          typeface="body2"
          textAlign="center"
          color={isDesktopLayout ? 'whiteHigh' : 'accent400'}
        >
          Add a trustee to protect your room now and in the future
        </LRText>
        <Spacer size={0.5} />
        <LinkButton
          buttonSize="small"
          buttonType={isDesktopLayout ? 'hollowWhite' : 'hollowAccent'}
          title="Add trustee"
          to={{ screen: 'Trustees.AddTrustee' }}
        />
      </PaddedArea>
    );
  }

  return (
    <PaddedArea style={styles.container}>
      <PressableLink
        to={{
          screen: 'Trustees.TrusteesList',
        }}
        style={({ hovered }) => [
          styles.button,
          isDesktopLayout && styles.desktopButton,
          hovered && styles.buttonHover,
        ]}
      >
        <View style={[Style.fill, Style.centerBoth]}>
          <LRText typeface="h3">Your Trustees</LRText>
          <View style={[Style.row, trusteesToShow.length > 1 && { left: 30 }]}>
            {trusteesToShow.map((trustee, i) => {
              const avatarImage = trustee.avatar?.pathAvatar;
              if (avatarImage) {
                return (
                  <Image
                    key={trustee['@id']}
                    source={avatarImage as any}
                    style={[styles.avatar, { left: -15 * i }]}
                  />
                );
              }
              return (
                <View
                  key={trustee['@id']}
                  style={[
                    styles.avatar,
                    Style.centerBoth,
                    {
                      left: -15 * i,
                    },
                  ]}
                >
                  <IconAvatar size={46} />
                </View>
              );
            })}
            {myUserTrusteesCount > 5 && (
              <View
                style={[
                  Style.centerBoth,
                  styles.avatar,
                  styles.additionalFriendsPlaceholder,
                ]}
              >
                <LRText>{`+${
                  myUserTrusteesCount - trusteesToShow.length
                }`}</LRText>
              </View>
            )}
          </View>
        </View>
      </PressableLink>
    </PaddedArea>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: Dimen.cornerRadiusLarge,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.08,
    shadowRadius: 14,
    backgroundColor: Color.whiteHigh,
  },
  button: {
    paddingVertical: Dimen.spacing,
    paddingRight: Dimen.spacing,
    flexDirection: 'row',
    alignItems: 'center',
    transitionProperty: 'background-color',
    transitionDuration: '200ms',
    borderRadius: Dimen.cornerRadiusLarge,
  },
  roomIcon: {
    width: 65,
    height: 60,
  },
  desktopButton: {
    paddingLeft: Dimen.spacing,
  },
  buttonHover: {
    backgroundColor: Color.lightGrey,
  },
  avatar: {
    height: 52,
    width: 52,
    border: `3px solid ${Color.whiteHigh}`,
    borderRadius: 26,
    backgroundColor: Color.lightGrey,
  },
  additionalFriendsPlaceholder: {
    left: -45,
  },
});
