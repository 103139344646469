import { NavigationProp, RouteProp } from '@react-navigation/native';
import { format } from 'date-fns';
import React from 'react';
import { toast } from 'react-hot-toast';
import { View } from 'react-native';

import { Api, useApiCallable } from '../../../api';
import {
  ActionsDropdown,
  Button,
  ConfirmationModal,
  LRText,
  PaddedArea,
  ScreenError,
  ScreenLoading,
  Seperator,
  Spacer,
} from '../../../components';
import { IconDelete, IconLock, IconPencil } from '../../../components/icons';
import { Color, Style } from '../../../constants';
import { MainStackParamList } from '../../../navigation';
import { FileDetails } from '../components';

type Props = {
  navigation: NavigationProp<MainStackParamList>;
  route: RouteProp<MainStackParamList, 'Vault.ViewFile'>;
};

export default function ViewFileScreen({ route, navigation }: Props) {
  const [deletedFileId, setDeletedFileId] = React.useState<string | null>(null);
  const { fileId } = route.params;
  const callApi = useApiCallable();

  const api = Api.VaultEntry.useReadItem(fileId);
  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ScreenLoading />;

  const vaultEntry = api.data;

  async function onDeleteConfirm() {
    const response = await callApi(`/vault-entries/${vaultEntry.id}`, {
      method: 'DELETE',
    });
    if (response) {
      setDeletedFileId(null);
      toast.success('File deleted');
      navigation.goBack();
    }
  }

  return (
    <>
      <PaddedArea v h>
        <View style={[Style.row, Style.spaceBetween, { zIndex: 0 }]}>
          <LRText typeface="h1" color="extraDarkGray">
            {vaultEntry.title}
          </LRText>
          <ActionsDropdown
            actions={[
              {
                label: 'Edit details',
                icon: IconPencil,
                to: {
                  screen: 'Vault.EditFile',
                  params: { fileId: vaultEntry.id },
                },
              },
              {
                label: 'Delete file',
                icon: IconDelete,
                onPress: () => {
                  setDeletedFileId(vaultEntry.id);
                },
              },
            ]}
          />
        </View>
        <Spacer />
        <LRText typeface="body1" color="darkGray">
          Uploaded file
        </LRText>
        <Spacer size={0.5} />
        <Seperator color={Color.lightGrey} />
        <Spacer />
        <FileDetails
          fileName={vaultEntry.secureUpload.fileOriginalName ?? ''}
          createdAt={format(new Date(), 'dd/MM/yyyy')}
        />
        <Spacer size={1.5} />
        {vaultEntry.isLocked ? (
          <View style={[Style.row, Style.alignCenter]}>
            <IconLock />
            <Spacer />
            <LRText typeface="body2">
              This file is <b>locked</b> until the passing of the owner.
            </LRText>
          </View>
        ) : (
          <View style={Style.alignStart}>
            <Button
              title="Download file"
              onPress={async () => {
                const response = await callApi<any, any>(
                  `/vault-entries/${vaultEntry.id}/download`,
                  { method: 'POST', jsonBody: {} }
                );
                if (response) {
                  window.location.href = response?.data.temporaryUrl;
                }
              }}
            />
          </View>
        )}
        <Spacer size={2.5} />
        {vaultEntry.instructions && (
          <>
            <LRText typeface="body1" color="darkGray">
              Notes and instructions
            </LRText>
            <Spacer size={0.5} />
            <Seperator color={Color.lightGrey} />
            <Spacer />
            <LRText typeface="body1" color="extraDarkGray">
              {vaultEntry.instructions}
            </LRText>
          </>
        )}
      </PaddedArea>
      <ConfirmationModal
        title={`Are you sure you want to delete ${vaultEntry.title}?`}
        visible={!!deletedFileId}
        onCancel={() => setDeletedFileId(null)}
        onConfirm={onDeleteConfirm}
      />
    </>
  );
}
