import React from 'react';
import {
  Animated,
  Pressable,
  StyleSheet,
  useWindowDimensions,
} from 'react-native';

import { Color, Dimen } from '../../constants';
import { useLayoutContext } from '../../contexts';
import PaddedArea from '../PaddedArea';
import SidebarContent from './SidebarContent';

type Props = {
  visible: boolean;
  onDismiss: () => void;
};

export default function AnimatedDrawer({ visible, onDismiss }: Props) {
  const { isMobileLayout } = useLayoutContext();
  const { width } = useWindowDimensions();

  const [zIndex, setZIndex] = React.useState(-1);
  const animatedValue = React.useRef(new Animated.Value(0)).current;

  React.useEffect(() => {
    const toValue = visible ? 1 : 0;
    Animated.timing(animatedValue, {
      toValue,
      duration: 300,
    }).start(() => {
      if (!visible) {
        setZIndex(-1); // Set zIndex to 1000 when finished animating out
      }
    });

    if (visible) {
      setZIndex(100);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  if (!isMobileLayout) {
    return null;
  }

  return (
    <Animated.View
      style={[
        styles.overlay,
        {
          backgroundColor: animatedValue.interpolate({
            inputRange: [0, 1],
            outputRange: ['rgba(0,0,0,0.0)', 'rgba(0,0,0,0.7)'],
          }),
          zIndex: visible ? 100 : zIndex,
        },
      ]}
    >
      <Pressable onPress={onDismiss} style={styles.dismissPressable} />
      <Animated.View
        style={[
          styles.drawerContent,
          {
            left: animatedValue.interpolate({
              inputRange: [0, 1],
              outputRange: [width, 0],
            }),
          },
        ]}
      >
        <PaddedArea v h>
          <SidebarContent />
        </PaddedArea>
      </Animated.View>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    top: Dimen.headerHeight,
    right: 0,
    bottom: 0,
    left: 0,
    flexDirection: 'row',
    overflow: 'hidden',
  },
  dismissPressable: {
    width: Dimen.spacing * 4,
  },
  drawerContent: {
    flex: 1,
    backgroundColor: Color.whiteHigh,
  },
});
