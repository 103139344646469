import { useLinkProps } from '@react-navigation/native';
import { Image, ImageSourcePropType, StyleSheet, View } from 'react-native';

import { LRText, PressableLink, Spacer } from '../../../components';
import { IconCaret, IconLock } from '../../../components/icons';
import { Color, Dimen, Style } from '../../../constants';
import { useLayoutContext } from '../../../contexts';
import { useUserOverview } from '../../../hooks';
import { MainStackParamList } from '../../../navigation';
import { To } from '../../../types';

const getLabelFromTriggerType = (
  numOfTriggers: number,
  triggerType?: string
) => {
  switch (triggerType) {
    case 'welcome_note':
    default:
      return undefined;
    case 'wall_picture':
    case 'photo_frame':
    case 'book':
      return `${numOfTriggers} / 3`;
  }
};

type Props = {
  image: ImageSourcePropType;
  title: string;
  disabled?: boolean;
} & (
  | {
      roomTriggerType: string;
      to?: undefined;
    }
  | {
      roomTriggerType?: undefined;
      to: Parameters<typeof useLinkProps<MainStackParamList>>[0]['to'];
    }
);

export default function RoomFeatureButton({
  image,
  title,
  roomTriggerType,
  disabled = false,
  to,
}: Props) {
  const { managingUser } = useUserOverview();
  const { isDesktopLayout } = useLayoutContext();

  function numTriggersByType(type: string) {
    return managingUser.roomTriggers.filter((t) => t.type === type).length;
  }

  const link: To = to ?? {
    screen: 'Albums.AlbumsList',
    params: { roomTriggerType },
  };

  return (
    <PressableLink
      to={disabled ? { screen: 'Home.Billing' } : link}
      style={({ hovered }) => [
        styles.button,
        isDesktopLayout && styles.desktopButton,
        hovered && styles.buttonHover,
      ]}
    >
      <Image source={image} style={styles.roomIcon} />
      <Spacer size={0.5} />
      <View style={Style.fill}>
        <LRText typeface="h3">{title}</LRText>
        {roomTriggerType && (
          <View>
            <Spacer size={0.25} />
            {roomTriggerType && (
              <LRText typeface="h4">
                {getLabelFromTriggerType(
                  numTriggersByType(roomTriggerType),
                  roomTriggerType
                )}
              </LRText>
            )}
          </View>
        )}
      </View>
      {!isDesktopLayout && !disabled && <IconCaret />}
      {disabled && <IconLock />}
    </PressableLink>
  );
}

const styles = StyleSheet.create({
  button: {
    paddingVertical: Dimen.spacing,
    paddingRight: Dimen.spacing,
    flexDirection: 'row',
    alignItems: 'center',
    transitionProperty: 'background-color',
    transitionDuration: '200ms',
  },
  roomIcon: {
    width: 65,
    height: 60,
  },
  lockIcon: {
    height: 30,
    width: 30,
    opacity: 1,
  },
  desktopButton: {
    paddingLeft: Dimen.spacing,
  },
  buttonHover: {
    backgroundColor: Color.lightGrey,
  },
  disabled: {
    opacity: 0.5,
  },
});
