import { Color } from '../../constants';

type Props = {
  color?: string;
  size?: number;
};

export default function IconCopy({ color = Color.black, size = 24 }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7 17.1667C13.6279 17.1655 14.5175 16.792 15.1736 16.1281C15.8298 15.4642 16.1989 14.564 16.2 13.625V7.42213C16.2011 7.04981 16.1291 6.68096 15.9883 6.33698C15.8475 5.993 15.6406 5.68073 15.3796 5.41826L13.8102 3.83018C13.5508 3.56609 13.2422 3.35673 12.9023 3.21423C12.5624 3.07172 12.1978 2.99891 11.8299 3.00001H8.5C7.57208 3.00114 6.68249 3.37464 6.02635 4.03858C5.37022 4.70253 5.00111 5.60271 5 6.54168V13.625C5.00111 14.564 5.37022 15.4642 6.02635 16.1281C6.68249 16.792 7.57208 17.1655 8.5 17.1667H12.7ZM6.4 13.625V6.54168C6.4 5.97809 6.62125 5.43759 7.01508 5.03908C7.4089 4.64056 7.94305 4.41668 8.5 4.41668C8.5 4.41668 11.9433 4.42659 12 4.43368V5.83334C12 6.20907 12.1475 6.5694 12.4101 6.83508C12.6726 7.10075 13.0287 7.25001 13.4 7.25001H14.7832C14.7902 7.30738 14.8 13.625 14.8 13.625C14.8 14.1886 14.5788 14.7291 14.1849 15.1276C13.7911 15.5261 13.257 15.75 12.7 15.75H8.5C7.94305 15.75 7.4089 15.5261 7.01508 15.1276C6.62125 14.7291 6.4 14.1886 6.4 13.625ZM19 8.66667V16.4583C18.9989 17.3973 18.6298 18.2975 17.9736 18.9614C17.3175 19.6254 16.4279 19.9989 15.5 20H9.2C9.01435 20 8.8363 19.9254 8.70503 19.7925C8.57375 19.6597 8.5 19.4795 8.5 19.2917C8.5 19.1038 8.57375 18.9236 8.70503 18.7908C8.8363 18.658 9.01435 18.5833 9.2 18.5833H15.5C16.057 18.5833 16.5911 18.3595 16.9849 17.9609C17.3788 17.5624 17.6 17.0219 17.6 16.4583V8.66667C17.6 8.47881 17.6737 8.29865 17.805 8.16581C17.9363 8.03297 18.1143 7.95834 18.3 7.95834C18.4857 7.95834 18.6637 8.03297 18.795 8.16581C18.9263 8.29865 19 8.47881 19 8.66667Z"
        fill={color}
      />
    </svg>
  );
}
