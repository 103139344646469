export default function isNetworkError(err: unknown) {
  if (typeof err === 'object' && (err as any)?.code === 'NetworkError') {
    return true;
  }
  if (err instanceof Error && err.message === 'Network request failed') {
    return true;
  }
  if (err instanceof Error && err.message === 'Cross-Origin Request Blocked') {
    return true;
  }
  return false;
}
