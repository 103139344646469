import { ParamListBase } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { LinearGradient } from 'expo-linear-gradient';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { NavHeader, NavSidebar, PaddedArea } from '../components';
import { Color, Dimen, Style } from '../constants';
import { useLayoutContext } from '../contexts';
import { useTypedSelector } from '../reducers';

type Props = {
  navigation: NativeStackNavigationProp<ParamListBase>;
  children: React.ReactNode;
  currentRoute: string;
};

function UnauthView({ children }: { children: React.ReactNode }) {
  return (
    <View style={Style.fill}>
      <PaddedArea style={Style.fill} v h>
        <View style={styles.unauthedAreaMain}>{children}</View>
      </PaddedArea>
    </View>
  );
}

function UnauthedLinearGradientView({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <View style={Style.fill}>
      <LinearGradient
        end={{ y: 0, x: -1 }}
        colors={[Color.gradientStart, Color.gradientEnd]}
        style={styles.unauthedHeader}
      />
      <PaddedArea v h>
        <View style={styles.unauthedAreaMain}>{children}</View>
      </PaddedArea>
    </View>
  );
}

const AuthView = ({
  children,
  currentRoute,
}: {
  children: React.ReactNode;
  currentRoute: string;
}) => {
  const { isDesktopLayout } = useLayoutContext();
  const { user: authUser } = useTypedSelector((state) => state.auth);

  const screensWithoutNav = [
    'Account.UploadProfilePhoto',
    'Account.VerificationRequired',
  ];
  const showMainNav = authUser && !screensWithoutNav.includes(currentRoute);

  return (
    <View style={Style.fill}>
      {showMainNav && <NavHeader />}
      {isDesktopLayout && showMainNav && <NavSidebar />}
      <View
        style={[
          styles.areaMain,
          isDesktopLayout && showMainNav && styles.areaMainDesktop,
        ]}
      >
        {children}
      </View>
    </View>
  );
};

function AuthedLinearGradientView({
  children,
  currentRoute,
}: {
  children: React.ReactNode;
  currentRoute: string;
}) {
  const { isDesktopLayout } = useLayoutContext();
  const { user: authUser } = useTypedSelector((state) => state.auth);

  const screensWithoutNav = ['Home.UploadProfilePhoto'];
  const showMainNav = authUser && !screensWithoutNav.includes(currentRoute);

  return (
    <View style={Style.fill}>
      {showMainNav && <NavHeader />}
      {isDesktopLayout && showMainNav && <NavSidebar />}
      <LinearGradient
        end={{ y: 0, x: -1 }}
        colors={[Color.gradientStart, Color.gradientEnd]}
        style={styles.authedHeader}
      />
      <View
        style={[
          styles.areaMain,
          isDesktopLayout && showMainNav && styles.areaMainDesktop,
        ]}
      >
        {children}
      </View>
    </View>
  );
}

export default function Layout({ children, currentRoute }: Props) {
  const { user: authUser } = useTypedSelector((state) => state.auth);
  const { isDesktopLayout } = useLayoutContext();
  const screensWithLinearGradientBackground = [
    'Home.Home',
    'Home.Billing',
    'Auth.CreateAccount',
    'Auth.Login',
    'Auth.ForgotPassword',
    'Auth.ResetPassword',
    'Account.UploadProfilePhoto',
  ];
  const showLinearGradientView =
    screensWithLinearGradientBackground.includes(currentRoute);

  if (authUser) {
    if (currentRoute === 'Home.PreviewRoom') {
      return (
        <>
          <NavHeader />
          {children}
        </>
      );
    } else if (showLinearGradientView && !isDesktopLayout) {
      return (
        <AuthedLinearGradientView currentRoute={currentRoute}>
          {children}
        </AuthedLinearGradientView>
      );
    } else {
      return <AuthView currentRoute={currentRoute}>{children}</AuthView>;
    }
  } else {
    if (showLinearGradientView) {
      return (
        <UnauthedLinearGradientView>{children}</UnauthedLinearGradientView>
      );
    } else {
      return <UnauthView>{children}</UnauthView>;
    }
  }
}

const styles = StyleSheet.create({
  authedHeader: {
    position: 'absolute',
    zIndex: -1,
    width: '100%',
    height: Dimen.headerLinearGradientHeight,
    top: Dimen.headerHeight,
  },
  unauthedHeader: {
    position: 'absolute',
    zIndex: -1,
    top: 0,
    left: 0,
    right: 0,
    height: 250,
  },
  unauthedAreaMain: {
    flex: 1,
    alignSelf: 'center',
    width: '100%',
    maxWidth: 437,
    alignItems: 'stretch',
  },
  areaMain: {
    flex: 1,
    alignSelf: 'center',
    width: '100%',
    maxWidth: 820,
    alignItems: 'stretch',
  },
  areaMainDesktop: {
    paddingLeft: Dimen.sidebarTotalWidth,
    minWidth: 600 + Dimen.sidebarTotalWidth,
    maxWidth: Dimen.contentMaxWidth + Dimen.sidebarTotalWidth,
  },
});
