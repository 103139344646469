import { format, parseISO } from 'date-fns';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { StyleSheet, View } from 'react-native';
import { FieldProp } from 'react-typed-form';

import { Api, useApiCallable } from '../api';
import {
  LRText,
  PaddedArea,
  ScreenLoading,
  Seperator,
  Spacer,
} from '../components';
import { IconPlus } from '../components/icons';
import { Color } from '../constants';
import { useUserOverview } from '../hooks';
import { FileDetails } from '../pods/vault/components';

type Props = {
  field: FieldProp<string | typeof BUSY | null>;
  label?: string;
  hint?: string;
};

const BUSY = '_busy';

export default function FieldSecureFileUpload({
  field,
  label = 'File',
  hint,
}: Props) {
  const [uploadedFile, setUploadedFile] = React.useState<File | null>(null);
  const { managingUser } = useUserOverview();
  const callApi = useApiCallable();

  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    field.handleValueChange(BUSY);
    const response = await callApi<Api.Upload.BaseView, unknown>(
      `/secure-uploads?userId=${managingUser.id}`,
      {
        method: 'POST',
        uploadFile: file,
      },
      {
        errorHandler: (err) => {
          console.log(err);
        },
      }
    );

    if (response) {
      field.handleValueChange(response.data['@id']);
      setUploadedFile(file);
    } else {
      field.handleValueChange(null);
      setUploadedFile(null);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  if (field.value === BUSY) {
    return <ScreenLoading />;
  }

  return (
    <View>
      {uploadedFile ? (
        <View>
          <PaddedArea h={0.5}>
            <LRText color="darkGray" typeface="body1">
              {label}
            </LRText>
            <Spacer />
            <FileDetails
              fileName={uploadedFile.name}
              createdAt={format(
                parseISO(new Date().toISOString()),
                'dd/MM/yyyy'
              )}
            />
          </PaddedArea>
          <Spacer size={0.5} />
          <Seperator color={Color.gray} />
        </View>
      ) : (
        <View>
          <PaddedArea v={0.25} h={0.25} style={styles.dottedOutline}>
            <LRText color="darkGray" textAlign="center" typeface="captionR">
              Upload File
            </LRText>
            <View style={styles.dropzone}>
              <div {...getRootProps({ isDragActive })} style={styles.wrapper}>
                <input {...getInputProps()} />
                <IconPlus color={Color.darkGray} size={30} />
              </div>
            </View>
          </PaddedArea>
          {hint && (
            <PaddedArea h v={0.5}>
              <LRText color="darkGray" typeface="captionR">
                {hint}
              </LRText>
            </PaddedArea>
          )}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  dottedOutline: {
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: Color.lightGrey,
  },
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropzone: {
    backgroundColor: Color.lightGrey,
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
});
