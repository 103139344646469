export default function IconLock() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2030_27291"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="30"
        height="30"
      >
        <rect width="30" height="30" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2030_27291)">
        <path
          opacity="1"
          d="M7.88463 26.8749C7.26323 26.8749 6.73128 26.6536 6.28878 26.2111C5.84626 25.7686 5.625 25.2366 5.625 24.6152V12.8846C5.625 12.2632 5.84626 11.7312 6.28878 11.2887C6.73128 10.8462 7.26323 10.6249 7.88463 10.6249H9.375V8.12494C9.375 6.56406 9.92227 5.23636 11.0168 4.14181C12.1114 3.04727 13.4391 2.5 15 2.5C16.5608 2.5 17.8886 3.04727 18.9831 4.14181C20.0777 5.23636 20.6249 6.56406 20.6249 8.12494V10.6249H22.1153C22.7367 10.6249 23.2687 10.8462 23.7112 11.2887C24.1537 11.7312 24.3749 12.2632 24.3749 12.8846V24.6152C24.3749 25.2366 24.1537 25.7686 23.7112 26.2111C23.2687 26.6536 22.7367 26.8749 22.1153 26.8749H7.88463ZM15 20.9374C15.6073 20.9374 16.1238 20.7246 16.5492 20.2992C16.9747 19.8737 17.1874 19.3573 17.1874 18.7499C17.1874 18.1425 16.9747 17.6261 16.5492 17.2007C16.1238 16.7752 15.6073 16.5624 15 16.5624C14.3926 16.5624 13.8762 16.7752 13.4507 17.2007C13.0252 17.6261 12.8125 18.1425 12.8125 18.7499C12.8125 19.3573 13.0252 19.8737 13.4507 20.2992C13.8762 20.7246 14.3926 20.9374 15 20.9374ZM11.25 10.6249H18.75V8.12494C18.75 7.08327 18.3854 6.19785 17.6562 5.46869C16.9271 4.73952 16.0416 4.37494 15 4.37494C13.9583 4.37494 13.0729 4.73952 12.3437 5.46869C11.6146 6.19785 11.25 7.08327 11.25 8.12494V10.6249Z"
          fill="#5C62B7"
        />
      </g>
    </svg>
  );
}
