import { NavigationProp, RouteProp } from '@react-navigation/native';
import { View } from 'react-native';
import { useForm } from 'react-typed-form';

import { Api, useApiFormSubmit } from '../../../api';
import { LRText, LinkButton, PaddedArea, Spacer } from '../../../components';
import { Style } from '../../../constants';
import {
  FieldCheckbox,
  FieldSecureFileUpload,
  FieldText,
  FieldTextRequired,
  SubmitButton,
} from '../../../form';
import { useUserOverview } from '../../../hooks';
import { MainStackParamList } from '../../../navigation';

type FormShape = Omit<Api.VaultEntry.W_Create, 'secureUpload'> & {
  secureUpload: string | null;
};

type Props = {
  navigation: NavigationProp<MainStackParamList>;
  route: RouteProp<MainStackParamList, 'Vault.AddFile'>;
};

export default function AddFileScreen({ route, navigation }: Props) {
  const { categoryId, parentCategoryId } = route.params;
  const { managingUser } = useUserOverview();
  const onSubmit = useApiFormSubmit<FormShape>('POST', '/vault-entries', {
    onSuccess: () => {
      navigation.navigate('Vault.CategoryExplore', {
        categoryId: parentCategoryId,
      });
    },
  });
  const form = useForm<FormShape>({
    defaultValues: {
      user: managingUser['@id'],
      category: `/vault-categories/${categoryId}`,
      title: '',
      secureUpload: null,
      instructions: null,
      isLocked: false,
    },
    onSubmit,
  });

  return (
    <PaddedArea v h>
      <LRText typeface="h1" color="accent">
        Add File
      </LRText>
      <Spacer />
      <FieldTextRequired field={form.getField('title')} />
      <Spacer />
      <FieldSecureFileUpload
        label="Uploaded File"
        hint="Upload a photo, or PDF for related document"
        field={form.getField('secureUpload')}
      />
      <Spacer />
      <FieldText
        label="Notes and Instructions"
        multiline
        field={form.getField('instructions')}
      />
      <Spacer size={1.5} />
      <View style={[Style.row, Style.alignCenter]}>
        <FieldCheckbox
          field={form.getField('isLocked')}
          label=""
          style={{ alignItems: 'flex-start' }}
        />
        <Spacer />
        <View>
          <LRText>Permanently lock file</LRText>
          <LRText typeface="body2" color="gray7">
            You won't be able to open this file again, until the passing of your
            estate to your Trustee
          </LRText>
        </View>
      </View>
      <Spacer size={1.5} />
      <View style={Style.row}>
        <View style={{ flex: 2 }}>
          <LinkButton
            title="Cancel"
            buttonType="tertiary"
            to={{
              screen: 'Vault.CategoryExplore',
              params: { categoryId },
            }}
          />
        </View>
        <Spacer size={0.5} />
        <View style={{ flex: 3 }}>
          <SubmitButton
            {...form}
            forceDisabled={form.getField('secureUpload').value === null}
            title="Add"
          />
        </View>
      </View>
    </PaddedArea>
  );
}
