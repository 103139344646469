import { NavigationProp, RouteProp } from '@react-navigation/native';
import { View } from 'react-native';
import { useForm } from 'react-typed-form';

import { Api, useApiFormSubmit } from '../../api';
import { LRText, LinkButton, PaddedArea, Spacer } from '../../components';
import { Style } from '../../constants';
import {
  FieldMediaOrganiser,
  FieldText,
  FieldTextRequired,
  SubmitButton,
} from '../../form';
import { useUserOverview } from '../../hooks';
import { MainStackParamList } from '../../navigation';

type Props = {
  navigation: NavigationProp<MainStackParamList>;
  route: RouteProp<MainStackParamList, 'Albums.CreateAlbumFriendship'>;
};

/**
 * Create an album based around a Friendship
 */
export default function CreateAlbumFriendshipScreen({
  navigation,
  route,
}: Props) {
  const { friendshipId, friendName } = route.params;

  const { managingUser } = useUserOverview();

  const onSubmit = useApiFormSubmit<Api.Album.W_Create, Api.Album.V_Detail>(
    'POST',
    '/albums',
    {
      onSuccess: (_, data) => {
        navigation.navigate('Albums.ViewAlbum', { albumId: data.id });
      },
    }
  );

  const form = useForm<Api.Album.W_Create>({
    defaultValues: {
      user: managingUser['@id'],
      title: `${managingUser.firstName} and ${friendName}`,
      story: '',
      albumMedias: [],
      roomTrigger: null,
      friendship: `/friendships/${friendshipId}`,
    },
    onSubmit,
  });

  return (
    <PaddedArea v h>
      <LRText typeface="h1" color="accent">
        Create Shared Album
      </LRText>
      <Spacer size={0.5} />
      <LRText typeface="body2" color="gray7">
        Add up to 50 photos/videos
      </LRText>
      <Spacer size={0.5} />

      <LRText typeface="body2" color="gray7">
        These photos and videos will be shown in your room. They will also be suggested to your friend {' '}
        {friendName}. {' '} {friendName} will also be able to suggest photos for this album, but
        they won't appear in your room without your approval. 
      </LRText>
      <Spacer size={0.5} />
      <FieldMediaOrganiser
        // @ts-expect-error
        field={form.getField('albumMedias')}
      />
      <Spacer size={1.5} />
      <FieldTextRequired field={form.getField('title')} disabled />
      <Spacer size={0.5} />
      <FieldText field={form.getField('story')} />
      <Spacer />
      <View style={Style.row}>
        <View style={{ flex: 2 }}>
          <LinkButton
            title="Cancel"
            buttonType="tertiary"
            to={{ screen: 'FriendsWall.FriendsWall' }}
          />
        </View>
        <Spacer size={0.5} />
        <View style={{ flex: 3 }}>
          <SubmitButton
            {...form}
            forceDisabled={
              (form.getField('albumMedias').value?.length ?? 0) === 0
            }
            title="Publish"
          />
        </View>
      </View>
    </PaddedArea>
  );
}
