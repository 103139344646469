import * as React from 'react';
import { Pressable } from 'react-native';

import { ApiError, useApiCallable } from '../../../api';
import { Button, LRText, PaddedArea, Spacer } from '../../../components';
import { Style } from '../../../constants';
import { useUserOverview } from '../../../hooks';
import { useTypedDispatch } from '../../../reducers';

export default function VerificationRequiredScreen() {
  const callApi = useApiCallable();
  const dispatch = useTypedDispatch();
  const { myUser } = useUserOverview();

  function request() {
    callApi(
      '/email-verification/request',
      {
        jsonBody: {},
        method: 'POST',
      },
      {
        errorHandler: (err, originalHandler) => {
          if (err instanceof ApiError && err.response.status === 400) {
            // No action needed
          } else {
            originalHandler(err);
          }
        },
      }
    );
  }

  React.useEffect(() => {
    request();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PaddedArea v h style={[Style.fill, Style.centerBoth]}>
      <LRText color="extraDarkGray" textAlign="center" typeface="h1">
        Please verify your email
      </LRText>
      <Spacer size={2} />
      <LRText color="extraDarkGray" textAlign="center">
        You're almost done! We have sent an email to
        <Spacer size={0.25} />
        <LRText color="darkGray" textAlign="center">
          {myUser.email}
        </LRText>
        <Spacer size={0.25} />
        containing a link to verify your email and complete your sign up.
        <Spacer size={0.25} />
        <LRText color="darkGray" textAlign="center">
          Dont see it? Try checking your spam folder.
        </LRText>
      </LRText>
      <Spacer />
      <LRText color="darkGray" typeface="body2" textAlign="center">
        Still don't see it?
      </LRText>
      <Spacer />
      <Button
        title="Resend email"
        onPress={() => {
          request();
        }}
      />
      <Spacer />
      <Pressable
        onPress={() => {
          dispatch({ type: 'LOGOUT' });
        }}
      >
        <LRText typeface="body2" color="gray7">
          Log out of this account
        </LRText>
      </Pressable>
    </PaddedArea>
  );
}
