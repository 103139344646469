import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { Image,StyleSheet } from 'react-native';
import { LRText } from '../../../components';
import { Api } from '../../../api';
import {
  PaddedArea,
  ScreenError,
  ScreenHeading,
  ScreenLoading,
  ScreenOnboarding,
  Spacer,
} from '../../../components';
import { MainStackParamList } from '../../../navigation';
import { useTypedSelector } from '../../../reducers';
import { RootCategoryButton } from '../components';

type Props = NativeStackScreenProps<MainStackParamList, 'Vault.RootList'>;

export default function RootListScreen({}: Props) {
  const { completedScreens } = useTypedSelector((state) => state.onboarding);

  const [showOnboarding, setShowOnboarding] = React.useState(
    !completedScreens.includes('digital_vault')
  );

  const api = Api.VaultCategory.useReadCollectionRootList();
  const api2 = Api.Sponsor.useReadItemSponsor('vault');
  
  if (api.error || api2.error) return <ScreenError {...api} />;
  if (!api.data) return <ScreenLoading />;

  const categories = api.data['hydra:member'];
  const sponsor = api2.data ?? null;

  const sponsor_logo = (sponsor && sponsor.logo) ? "https://api.legacyroom.com/img/logo/" +  sponsor.logo : null;


  if (showOnboarding) {
    return (
      <PaddedArea v h>
        <ScreenOnboarding
          screen="digital_vault"
          onAfterDismiss={() => setShowOnboarding(false)}
        />
      </PaddedArea>
    );
  }

  return (
    <PaddedArea h v>
      <ScreenHeading
        title="Digital Vault"
        onHelpPress={() => setShowOnboarding(true)}
      />
      <Spacer />
      {sponsor.name && (
        <LRText typeface="h2" color="accent" textAlign="center">
          Sponsored by {sponsor.name}
        </LRText>
      )}
      {sponsor_logo && (
        <a href={sponsor.url} target="_blank">
            <Image
            source={sponsor_logo}
            style={styles.sponsor_logo}
          />
        </a>
      )}
      {sponsor.description && (
        <LRText typeface="body2" textAlign="center">
          {sponsor.description}
        </LRText>
      )}
      <Spacer />
      {categories.map((category) => {
        return (
          <>
            <RootCategoryButton category={category} />
            <Spacer size={0.5} />
          </>
        );
      })}
    </PaddedArea>
  );
}

const styles = StyleSheet.create({
  sponsor_logo: {
    width: 200,
    height: 200,
    resizeMode: 'contain',
    alignItems: 'center',
    justifyContent: 'center',
    margin:'auto',
  },
});
