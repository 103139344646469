import * as React from 'react';
import {
  StyleProp,
  StyleSheet,
  Text,
  TextInputProps,
  View,
  ViewStyle,
} from 'react-native';
import type { FieldProp } from 'react-typed-form';

import { LRTextInput, PaddedArea, Spacer, typefaceStyles } from '../components';
import LRText from '../components/LRText';

type Props = {
  dayField: FieldProp<string>;
  monthField: FieldProp<string>;
  yearField: FieldProp<string>;
  label?: string;
  textInputStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
} & TextInputProps;

export default function FieldDate({
  dayField,
  monthField,
  yearField,
  label = 'Date of birth',
  textInputStyle,
  containerStyle,
  disabled,
  onSubmitEditing,
  ...otherProps
}: Props) {
  const isInputDisabled =
    dayField.isLoading ||
    monthField.isLoading ||
    yearField.isLoading ||
    disabled;

  return (
    <View style={containerStyle}>
      <PaddedArea h={0.8} v={0}>
        <LRText typeface="captionM" color="darkGray">
          {label}
        </LRText>
      </PaddedArea>
      <Spacer size={0.5} />
      <View style={styles.inputContainer}>
        <LRTextInput
          editable={!isInputDisabled}
          placeholder="DD"
          inputMode="numeric"
          textInputStyle={[
            styles.input,
            styles.dayOrMonthInput,
            textInputStyle,
          ]}
          onChangeText={(text) => {
            dayField.handleValueChange(text.replace(/[^0-9]/g, ''));
          }}
          maxLength={2}
          value={dayField.value}
          {...otherProps}
        />
        <Spacer size={0.6} />
        <LRTextInput
          editable={!isInputDisabled}
          placeholder="MM"
          inputMode="numeric"
          textInputStyle={[
            styles.input,
            styles.dayOrMonthInput,
            textInputStyle,
          ]}
          onChangeText={(text) => {
            monthField.handleValueChange(text.replace(/[^0-9]/g, ''));
          }}
          maxLength={2}
          value={monthField.value}
          {...otherProps}
        />
        <Spacer size={0.6} />
        <LRTextInput
          editable={!isInputDisabled}
          placeholder="YYYY"
          inputMode="numeric"
          textInputStyle={[styles.input, styles.yearInput, textInputStyle]}
          onChangeText={(text) => {
            yearField.handleValueChange(text.replace(/[^0-9]/g, ''));
          }}
          maxLength={4}
          value={yearField.value}
          onSubmitEditing={onSubmitEditing}
          {...otherProps}
        />
      </View>
      {dayField.lastErrorList &&
        dayField.lastErrorList.map((text) => <Text key={text}>{text}</Text>)}
      {monthField.lastErrorList &&
        monthField.lastErrorList.map((text) => <Text key={text}>{text}</Text>)}
      {yearField.lastErrorList &&
        yearField.lastErrorList.map((text) => <Text key={text}>{text}</Text>)}
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    ...typefaceStyles.body2,
  },
  dayOrMonthInput: {
    width: 80,
  },
  yearInput: {
    width: 120,
  },
  inputContainer: {
    flexDirection: 'row',
  },
});
