import * as React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { Color, Dimen } from '../constants';
import PaddedArea from './PaddedArea';

type Props = {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
};

export default function CardPage({ children, style }: Props) {
  return (
    <View style={[styles.container, style]}>
      <PaddedArea style={styles.padding}>{children}</PaddedArea>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: Color.whiteHigh,
    borderRadius: Dimen.cornerRadiusLarge,
    shadowColor: Color.black,
    shadowOpacity: 0.08,
    shadowRadius: 10,
    shadowOffset: {
      height: 5,
      width: 0,
    },
  },
  padding: {
    paddingTop: Dimen.spacing * 2,
    paddingHorizontal: Dimen.spacing,
    paddingBottom: Dimen.spacing * 1.5,
  },
});
