import { StyleSheet, View } from 'react-native';

import { Dimen } from '../../constants';
import PaddedArea from '../PaddedArea';
import SidebarContent from './SidebarContent';

export default function NavSidebar() {
  return (
    <View style={styles.fixedOuter} pointerEvents="box-none">
      <PaddedArea v={1.5} style={styles.container}>
        <View style={{ width: Dimen.sidebarWidth }}>
          <SidebarContent />
        </View>
      </PaddedArea>
    </View>
  );
}

const styles = StyleSheet.create({
  fixedOuter: {
    position: 'absolute',
    top: Dimen.headerHeight,
    left: 0,
    right: 0,
    alignItems: 'center',
    marginRight: Dimen.contentMaxWidth,
    zIndex: 10,
  },
  container: {
    width: Dimen.sidebarTotalWidth,
  },
});
