import * as React from 'react';
import {
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputProps,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import { Color, Dimen } from '../constants';
import LRText from './LRText';
import PaddedArea from './PaddedArea';
import Spacer from './Spacer';

type Props = {
  label?: string;
  textInputStyle?: StyleProp<TextStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
} & TextInputProps;

export default function LRTextInput({
  label,
  textInputStyle,
  containerStyle,
  disabled,
  multiline,
  value,
  ...otherProps
}: Props) {
  const [scrollHeight, setScrollHeight] = React.useState(null);
  return (
    <View style={containerStyle}>
      {label && (
        <PaddedArea h={0.5}>
          <LRText color="darkGray" typeface="body1">
            {label}
          </LRText>
        </PaddedArea>
      )}
      <Spacer size={0.5} />
      <TextInput
        editable={!disabled}
        multiline={multiline}
        onChange={
          multiline
            ? (ev) => setScrollHeight((ev.target as any).scrollHeight + 1)
            : undefined
        }
        placeholderTextColor={Color.gray}
        style={[
          styles.textInput,
          disabled && styles.textInputDisabled,
          multiline && scrollHeight && { height: scrollHeight },
          textInputStyle,
        ]}
        value={value}
        {...otherProps}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  textInput: {
    paddingVertical: Dimen.spacing * 0.5,
    paddingHorizontal: Dimen.spacing * 0.8,
    borderColor: Color.gray,
    borderBottomWidth: 1,
    fontSize: 16,
    lineHeight: 19,
    fontFamily: 'DMSans_500Medium',
  },
  textInputDisabled: {
    color: Color.gray7,
  },
});
