import { Extras } from '@sentry/types';

import { Config } from '../constants';
import { logInfo } from '../util';

export type ApiRequestOptions<W> = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  sessionId?: string | null;
  jsonBody?: W;
  uploadFile?: File;
};

export type ApiResponse<R> = {
  status: number;
  headers: object;
  data: R;
};

export class ApiError extends Error {
  requestOptions: ApiRequestOptions<any>;
  response: ApiResponse<any>;
  constructor(
    path: string,
    requestOptions: ApiRequestOptions<any>,
    response: ApiResponse<any>
  ) {
    super(
      `ApiError: ${response.status} on ${
        requestOptions.method ?? 'GET'
      } ${path}`
    );
    this.requestOptions = requestOptions;
    this.response = response;
  }
}

/**
 * Given a url + options, resolve to an ApiResponse, or throw an ApiError
 */
export default async function lrApiRequest<R, W>(
  path: string,
  options: ApiRequestOptions<W>
): Promise<ApiResponse<R>> {
  const { method = 'GET', jsonBody, sessionId, uploadFile } = options;
  if (
    uploadFile &&
    (!(path.startsWith('/uploads') || path.startsWith('/secure')) ||
      method !== 'POST')
  ) {
    throw new Error('api should only be passed uploadFile on "POST /uploads"');
  }

  let body: undefined | string | FormData;
  if (jsonBody) body = JSON.stringify(jsonBody);
  if (uploadFile) {
    body = new FormData();
    body.append('file', uploadFile);
  }

  const headers = {
    Accept: 'application/ld+json',
    ...(sessionId ? { Authorization: sessionId } : {}),
    'Cache-Control': 'no-cache',
    ...(jsonBody ? { 'Content-Type': 'application/json' } : {}),
  };
  const fetchResponse = await fetch(`${Config.SERVER_ROOT}${path}`, {
    method,
    headers,
    body,
    credentials: 'omit',
  });

  const contentTypes = fetchResponse.headers.get('content-type') || '';
  if (
    !contentTypes.includes('application/json') &&
    !contentTypes.includes('application/ld+json') &&
    fetchResponse.status !== 204 // empty response might have misleading content type
  ) {
    const text = await fetchResponse.text();
    throw new Error(
      `${method} ${path} failed (${fetchResponse.status}); no json: ${text}`
    );
  }

  const data = fetchResponse.status === 204 ? {} : await fetchResponse.json();

  const apiResponse: ApiResponse<R> = {
    data,
    status: fetchResponse.status,
    headers: fetchResponse.headers,
  };

  // Debug: request
  logInfo(`>> ${method} ${path}`);
  if (!fetchResponse.ok) {
    for (const [k, v] of Object.entries(headers)) {
      logInfo(`>> ${k}: ${v}`);
    }
    body && logInfo(body instanceof FormData ? '<FormData>' : body);
    logInfo('');
  }

  // Debug: response
  logInfo(`<< ${fetchResponse.status}`);
  if (!fetchResponse.ok) {
    for (const [k, v] of (fetchResponse.headers as any).entries()) {
      logInfo(`<< ${k}: ${v}`);
    }
    logInfo(JSON.stringify(data, undefined, 2));
    logInfo('');
  }
  logInfo('');

  if (!fetchResponse.ok) {
    throw new ApiError(path, options, apiResponse);
  }

  return apiResponse;
}

export function apiErrorToSentryExtras(error: unknown): Extras {
  if (!(error instanceof ApiError)) return {};

  return {
    requestOptions: error.requestOptions,
    responseHeaders: error.response.headers,
    responseData: error.response.data,
  };
}
