import { LinearGradient } from 'expo-linear-gradient';
import { useMemo } from 'react';
import {
  Image,
  ImageBackground,
  StyleSheet,
  View,
  useWindowDimensions,
} from 'react-native';

import { Api } from '../../../api';
import {
  ActionsDropdown,
  Button,
  LRText,
  PaddedArea,
  Spacer,
} from '../../../components';
import { DropdownAction } from '../../../components/ActionsDropdown';
import {
  IconAvatar,
  IconDelete,
  IconEdit,
  IconFriend,
  IconPencil,
} from '../../../components/icons';
import { Color, Dimen, Style } from '../../../constants';
import { useLayoutContext } from '../../../contexts';
import { useUserOverview } from '../../../hooks';
import { iriToId } from '../../../util';

type Props = {
  friendship: NonNullable<Api.Friendship.V_UserSub>;
  onPress?: () => void;
  onCreateAlbum?: () => void;
  onRemoveFriend?: () => void;
  onEditCoverPhoto?: (mediaId: string) => void;
  onDelete?: () => void;
};

export default function FriendAlbumListItem({
  friendship,
  onRemoveFriend,
  onCreateAlbum,
  onPress,
  onDelete,
  onEditCoverPhoto,
}: Props) {
  const { width: windowWidth } = useWindowDimensions();
  const { isDesktopLayout } = useLayoutContext();
  const { managingUser } = useUserOverview();
  const containerWidth =
    Math.min(windowWidth, Dimen.contentMaxWidth) - Dimen.spacing * 2;
  const itemWidth = isDesktopLayout
    ? Math.floor((containerWidth - Dimen.spacing * 2) / 2)
    : undefined;
  const { album, friend, user } = friendship;
  const isOwner = managingUser.id === iriToId(friendship.user['@id']);

  const actions = useMemo(() => {
    return [
      ...(album && album.safeCover && onEditCoverPhoto
        ? [
            {
              label: 'Edit main photo',
              icon: IconEdit,
              onPress: () => {
                onEditCoverPhoto?.(iriToId(album.safeCover!['@id']));
              },
            },
          ]
        : []),
      ...(album
        ? [
            {
              label: isOwner ? 'Edit album' : 'Add suggested content',
              icon: IconPencil,
              to: isOwner
                ? { screen: 'Albums.EditAlbum', params: { albumId: album.id } }
                : {
                    screen: 'Albums.SuggestMedia',
                    params: { albumId: album.id, ownerName: user.firstName },
                  },
            } as DropdownAction,
          ]
        : []),
      ...(onRemoveFriend
        ? [
            {
              label: 'Remove friend',
              icon: IconFriend,
              onPress: () => {
                onRemoveFriend?.();
              },
            },
          ]
        : []),
      ...(onDelete
        ? [
            {
              label: 'Delete album',
              icon: IconDelete,
              onPress: () => {
                onDelete?.();
              },
            },
          ]
        : []),
    ];
  }, [
    album,
    isOwner,
    onDelete,
    onEditCoverPhoto,
    onRemoveFriend,
    user.firstName,
  ]);

  return (
    <View style={!album && [Style.row, Style.spaceBetween]}>
      <View style={[Style.row, Style.alignCenter]}>
        {isOwner ? (
          <>
            {friend.avatar ? (
              <Image
                source={friend.avatar.pathAvatar as any}
                style={styles.avatar}
              />
            ) : (
              <IconAvatar size={48} />
            )}
          </>
        ) : (
          <>
            {user.avatar ? (
              <Image
                source={user.avatar.pathAvatar as any}
                style={styles.avatar}
              />
            ) : (
              <IconAvatar size={48} />
            )}
          </>
        )}

        <Spacer size={0.5} />
        <LRText textAlign="center" typeface="h3">{`${
          isOwner ? friend.firstName : user.firstName
        } ${isOwner ? friend.lastName : user.lastName}`}</LRText>
      </View>
      <Spacer size={0.5} />
      {album ? (
        <View
          style={[styles.container, { width: itemWidth, maxWidth: itemWidth }]}
        >
          <LinearGradient
            colors={['#00000000', '#000000CC']}
            style={styles.linearGradient}
          >
            <PaddedArea style={styles.contentContainer} v={1.5} h={1.2}>
              <View style={styles.actionsAnchor}>
                <ActionsDropdown triggerColor="white" actions={actions} />
              </View>
              <Spacer fill />
              <LRText color="whiteHigh" typeface="h1" textAlign="center">
                {album.title}
              </LRText>
              <Spacer size={0.5} />
              <LRText typeface="body1" color="whiteHigh" textAlign="center">
                {album.mediasCount} Item{album.mediasCount === 1 ? '' : 's'}
              </LRText>
              <Spacer size={1.5} />
              <Button
                buttonSize="small"
                title="Explore"
                buttonType="hollowWhite"
                onPress={() => onPress?.()}
              />
            </PaddedArea>
          </LinearGradient>

          <ImageBackground
            resizeMode="cover"
            style={styles.backgroundImage}
            source={album?.safeCover?.upload.pathOrganiserCover as any}
          />
        </View>
      ) : (
        <Button
          title="Create Shared album"
          buttonSize={isDesktopLayout ? 'tiny' : 'small'}
          onPress={onCreateAlbum!}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    aspectRatio: '354/300',
    backgroundColor: Color.gray,
    borderRadius: 32,
    overflow: 'hidden',
    minWidth: 230,
  },
  linearGradient: {
    height: '100%',
    width: '100%',
  },
  avatar: {
    height: 48,
    width: 48,
    borderRadius: 24,
    backgroundColor: Color.gray2,
    boreder: `3px solid ${Color.lightGrey}`,
    overflow: 'hidden',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: -1,
  },
  actionsAnchor: {
    alignSelf: 'flex-end',
  },
  buttonContainer: {
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
});
