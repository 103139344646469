type Props = {
  color: string;
  orient: 'h' | 'v';
};

export default function IconEllipsisThick({ color, orient }: Props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${orient === 'v' ? 0 : 90}deg)` }}
    >
      <mask
        id="mask0_611_22520"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="21"
      >
        <rect y="0.5" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_611_22520)">
        <path
          d="M9.99992 17.1667C9.54159 17.1667 9.14936 17.0036 8.82325 16.6775C8.49659 16.3508 8.33325 15.9583 8.33325 15.5C8.33325 15.0417 8.49659 14.6492 8.82325 14.3225C9.14936 13.9964 9.54159 13.8333 9.99992 13.8333C10.4583 13.8333 10.8508 13.9964 11.1774 14.3225C11.5035 14.6492 11.6666 15.0417 11.6666 15.5C11.6666 15.9583 11.5035 16.3508 11.1774 16.6775C10.8508 17.0036 10.4583 17.1667 9.99992 17.1667ZM9.99992 12.1667C9.54159 12.1667 9.14936 12.0033 8.82325 11.6767C8.49659 11.3506 8.33325 10.9583 8.33325 10.5C8.33325 10.0417 8.49659 9.64918 8.82325 9.32251C9.14936 8.9964 9.54159 8.83334 9.99992 8.83334C10.4583 8.83334 10.8508 8.9964 11.1774 9.32251C11.5035 9.64918 11.6666 10.0417 11.6666 10.5C11.6666 10.9583 11.5035 11.3506 11.1774 11.6767C10.8508 12.0033 10.4583 12.1667 9.99992 12.1667ZM9.99992 7.16668C9.54159 7.16668 9.14936 7.00334 8.82325 6.67668C8.49659 6.35057 8.33325 5.95834 8.33325 5.50001C8.33325 5.04168 8.49659 4.64945 8.82325 4.32334C9.14936 3.99668 9.54159 3.83334 9.99992 3.83334C10.4583 3.83334 10.8508 3.99668 11.1774 4.32334C11.5035 4.64945 11.6666 5.04168 11.6666 5.50001C11.6666 5.95834 11.5035 6.35057 11.1774 6.67668C10.8508 7.00334 10.4583 7.16668 9.99992 7.16668Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
