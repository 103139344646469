import type { ReadConfig } from 'api-read-hook';
import { useApiRead } from 'api-read-hook';

import * as Upload from './Upload';

export type V_Detail = {
  '@id': string;
  '@type': 'AlbumMedia';
  id: string;
  description: string | null;
  album: {
    '@id': string;
    '@type': 'Album';
    id: string;
  };
  upload: Upload.BaseView;
  isCover: boolean;
  position: number;
};

export type W_Update = {
  description: string | null;
  upload: {
    rotation: number;
  };
  isCover: boolean;
};

export function useReadItem(id: string, options?: ReadConfig) {
  return useApiRead<V_Detail>(`/album-media/${id}`, options);
}
