import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Dispatch, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import type { Action } from '../actions';
import { Config } from '../constants';
import auth from './auth';
import onboarding from './onboarding';

const persistConfig = {
  debug: Config.APP_ENV === 'dev',
  keyPrefix: `uk.co.legacyroom.${Config.APP_ENV}.`,
  storage,
};

const reducers = combineReducers({
  auth: persistReducer({ ...persistConfig, key: 'auth' }, auth),
  onboarding: persistReducer(
    { ...persistConfig, key: 'onboarding' },
    onboarding
  ),
});

type RootState = ReturnType<typeof reducers>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useTypedDispatch: () => Dispatch<Action> = useDispatch;

export default reducers;
