import * as React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useForm } from 'react-typed-form';

import { Api, authApi, useApiFormSubmit } from '../../api';
import {
  CardPage,
  ErrorList,
  LRText,
  LinkButton,
  Spacer,
} from '../../components';
import { IconAvatar } from '../../components/icons';
import { Style } from '../../constants';
import {
  FieldCheckbox,
  FieldDate,
  FieldTextRequired,
  SubmitButton,
} from '../../form';

type FormShape = Omit<Api.User.W_Create, 'plainPassword' | 'dob'> & {
  plainPassword: string;
  day: string;
  month: string;
  year: string;
  acceptedTerms: boolean;
};

export default function CreateAccountScreen() {
  const attemptLogin = authApi.useAttemptLogin();

  const onSubmit = useApiFormSubmit<FormShape>('POST', '/users', {
    onSuccess: async ({ addSubmitError, setLoading }, data, values) => {
      setLoading(true);
      await attemptLogin(
        {
          email: values.email.trim().toLowerCase(),
          password: values.plainPassword.trim(),
        },
        addSubmitError
      );
    },
  });
  const form = useForm<FormShape>({
    defaultValues: {
      email: '',
      plainPassword: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      day: '',
      month: '',
      year: '',
      acceptedTerms: false,
      avatar: null,
    },
    postValidateTransform: ({ day, month, year, email, ...rest }) =>
      ({
        ...rest,
        email: email.trim().toLowerCase(),
        dob: `${year}-${month}-${day}`,
      } as any),
    onSubmit,
  });
  return (
    <View style={Style.alignCenter}>
      <Spacer />
      <IconAvatar size={54} />
      <Spacer size={0.25} />
      <LRText typeface="h1" textAlign="center" color="whiteHigh">
        Create your account
      </LRText>
      <Spacer />
      <CardPage>
        <FieldTextRequired
          autoCapitalize="none"
          field={form.getField('email')}
        />
        <Spacer size={0.5} />
        <FieldTextRequired
          label="Password"
          field={form.getField('plainPassword')}
          secureTextEntry
        />
        <Spacer size={0.5} />
        <FieldTextRequired field={form.getField('firstName')} />
        <Spacer size={0.5} />
        <FieldTextRequired field={form.getField('lastName')} />
        <Spacer size={0.5} />
        <FieldTextRequired field={form.getField('phoneNumber')} />
        <Spacer size={0.5} />
        <FieldDate
          containerStyle={Style.alignStart}
          dayField={form.getField('day')}
          monthField={form.getField('month')}
          yearField={form.getField('year')}
          onSubmitEditing={form.handleSubmit}
        />
        <Spacer />
        <FieldCheckbox
          style={Style.alignStart}
          field={form.getField('acceptedTerms')}
          customLabel={
            <LRText typeface="body2" color="gray9">
              I have read and agree to{' '}
              <Text style={styles.labelHighlighted}>Terms and Conditions</Text>,{' '}
              <Text style={styles.labelHighlighted}>Cookie Policy</Text> and{' '}
              <Text style={styles.labelHighlighted}>Privacy Policy</Text>
            </LRText>
          }
        />
        <ErrorList errorList={form.formErrorList} />
        <Spacer />
        <View style={Style.row}>
          <View style={{ flex: 2 }}>
            <LinkButton
              disabled={form.isLoading}
              title="Back"
              buttonType={'tertiary'}
              to={{ screen: 'Auth.Login' }}
            />
          </View>
          <Spacer size={0.3} />
          <View style={{ flex: 3 }}>
            <SubmitButton
              {...form}
              disabled={form.isLoading}
              title="Create Account"
            />
          </View>
        </View>
      </CardPage>
    </View>
  );
}

const styles = StyleSheet.create({
  labelHighlighted: {
    fontWeight: '700',
  },
});
