import { NavigationProp, RouteProp } from '@react-navigation/native';
import * as React from 'react';
import { View } from 'react-native';

import { Api, useApiCallable } from '../../../api';
import {
  ConfirmationModal,
  EmptyListScreen,
  IconLinkButton,
  LRText,
  PaddedArea,
  ScreenError,
  ScreenLoading,
  ScreenOnboarding,
  Spacer,
} from '../../../components';
import { IconPlus } from '../../../components/icons';
import { Color, Style } from '../../../constants';
import { useUserOverview } from '../../../hooks';
import { MainStackParamList } from '../../../navigation';
import { useTypedSelector } from '../../../reducers';
import TrusteeItem from '../TrusteeItem';

type Props = {
  route: RouteProp<MainStackParamList, 'Trustees.TrusteesList'>;
  navigation: NavigationProp<MainStackParamList>;
};

export default function TrusteesListScreen({ navigation }: Props) {
  const { myUser } = useUserOverview();
  const callApi = useApiCallable();
  const { completedScreens } = useTypedSelector((state) => state.onboarding);
  const [pendingDeletion, setPendingDeletion] =
    React.useState<Api.TrusteeListItem.V | null>(null);
  const shouldShowOnboarding = !completedScreens.includes('trustee');

  const api = Api.TrusteeListItem.useReadCollectionTrusteeListItems(myUser.id);
  if (api.error) return <ScreenError error={api.error} />;
  if (!api.data) return <ScreenLoading />;
  const trustees = api.data['hydra:member'];

  if (shouldShowOnboarding) {
    return (
      <PaddedArea style={Style.fill} v h>
        <ScreenOnboarding screen="trustee" />
      </PaddedArea>
    );
  }

  async function onDeleteConfirm() {
    if (!pendingDeletion) return;
    const response = pendingDeletion.isInvite
      ? await callApi(`/trustee-invites/${pendingDeletion.id}`, {
          method: 'DELETE',
        })
      : await callApi(`/users/${myUser.id}/trustees/${pendingDeletion.id}`, {
          method: 'DELETE',
        });
    if (response) {
      setPendingDeletion(null);
      api.invalidate();
    }
  }

  return (
    <>
      <PaddedArea v h style={Style.fill}>
        {trustees.length > 0 ? (
          <View>
            <View style={[Style.row, Style.alignCenter]}>
              <LRText typeface="h1" color="accent">
                Your Trustees
              </LRText>
              <Spacer fill />
              <IconLinkButton
                to={{
                  screen: 'Trustees.AddTrustee',
                }}
              >
                <IconPlus color={Color.whiteHigh} />
              </IconLinkButton>
            </View>
            <Spacer />
            {trustees.map((trustee, index) => (
              <View style={{ zIndex: -index }}>
                <TrusteeItem
                  key={trustee['@id']}
                  trustee={trustee}
                  onRemove={(toDelete) => setPendingDeletion(toDelete)}
                />
                <Spacer size={0.5} />
              </View>
            ))}
          </View>
        ) : (
          <EmptyListScreen
            roomTriggerType="trustee"
            onAdd={() => navigation.navigate('Trustees.AddTrustee')}
          />
        )}
      </PaddedArea>
      {pendingDeletion && (
        <ConfirmationModal
          visible
          title={
            pendingDeletion.isInvite
              ? `Are you sure you want to cancel your invite for ${pendingDeletion.fullName}`
              : `Are you sure you want to remove ${pendingDeletion.fullName}?`
          }
          message={
            pendingDeletion.isInvite
              ? `${pendingDeletion.fullName} will lose their option to manage your room to your room`
              : `${pendingDeletion.fullName} will lose their ability to manage your room`
          }
          confirmationText={
            pendingDeletion.isInvite ? 'Cancel invite' : 'Remove trustee'
          }
          onCancel={() => setPendingDeletion(null)}
          onConfirm={onDeleteConfirm}
        />
      )}
    </>
  );
}
