import { format, parseISO } from 'date-fns';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Api } from '../../../api';
import { LRText, PaddedArea, PressableLink } from '../../../components';
import { IconPlus } from '../../../components/icons';
import { Color, Dimen, Style } from '../../../constants';
import FileDetails from './FileDetails';

type Props = {
  category: Api.VaultCategoryExplore.V['childCategories'][0];
  parentCategoryId: string;
};

export default function CategoryButton({ category, parentCategoryId }: Props) {
  return (
    <View style={styles.wrapper}>
      <View style={[styles.categoryRow, styles.categoryRowWithFiles]}>
        <PaddedArea
          style={[
            Style.row,
            Style.fullWidth,
            Style.alignCenter,
            Style.spaceBetween,
            styles.buttonPadding,
          ]}
        >
          <LRText typeface="h3">{category.name}</LRText>
          <PressableLink
            style={({ hovered }: any) => [
              styles.addButton,
              hovered && styles.addButtonHovered,
            ]}
            to={{
              screen: 'Vault.AddFile',
              params: {
                categoryId: category.id,
                parentCategoryId,
              },
            }}
          >
            <IconPlus color={Color.whiteHigh} />
          </PressableLink>
        </PaddedArea>
      </View>
      {category.userEntries?.map((vaultEntry) => (
        <PressableLink
          style={({ hovered }: any) => [
            styles.fileButton,
            hovered && styles.fileButtonHovered,
          ]}
          to={{
            screen: 'Vault.ViewFile',
            params: { fileId: vaultEntry.id },
          }}
        >
          <PaddedArea h v={0.5}>
            <FileDetails
              fileName={vaultEntry.title}
              createdAt={format(parseISO(vaultEntry.createdAt), 'dd/MM/yyyy')}
            />
          </PaddedArea>
        </PressableLink>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: Color.accent50,
    borderRadius: Dimen.cornerRadiusLarge,
    overflow: 'hidden',
  },
  categoryRow: {
    backgroundColor: Color.lightGrey,
    transitionDuration: '200ms',
  },
  categoryRowWithFiles: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  fileButton: {
    ['transitionDuration' as any]: '0.25s',
  },
  fileButtonHovered: {
    backgroundColor: Color.lightGreyHovered,
  },
  fileHovered: {
    backgroundColor: Color.accentHovered,
  },
  buttonPadding: {
    paddingVertical: Dimen.spacing,
    paddingLeft: Dimen.spacing,
    paddingRight: Dimen.spacing * 0.5,
  },
  addButton: {
    backgroundColor: Color.accent400,
    height: 30,
    width: 30,
    borderRadius: Dimen.cornerRadiusExtraLarge,
    alignItems: 'center',
    justifyContent: 'center',
  },
  addButtonHovered: {
    backgroundColor: Color.accent700,
  },
});
