export default function IconDoorOpen() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_193_18363"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_193_18363)">
        <path
          d="M10.9994 12.7692C11.2186 12.7692 11.4017 12.6926 11.5485 12.5394C11.6952 12.3862 11.7686 12.2064 11.7686 12C11.7686 11.7808 11.6952 11.5978 11.5485 11.451C11.4017 11.3042 11.2186 11.2308 10.9994 11.2308C10.793 11.2308 10.6132 11.3042 10.46 11.451C10.3068 11.5978 10.2302 11.7808 10.2302 12C10.2302 12.2064 10.3068 12.3862 10.46 12.5394C10.6132 12.6926 10.793 12.7692 10.9994 12.7692ZM6.99941 20V19L13.9994 18.3077V6.45193C13.9994 6.07372 13.8763 5.74616 13.6302 5.46923C13.384 5.19231 13.0802 5.03718 12.7186 5.00385L7.61479 4.5V3.5L12.8456 4.01537C13.4635 4.08461 13.977 4.34936 14.386 4.80963C14.7949 5.26988 14.9994 5.80897 14.9994 6.42692V19.1885L6.99941 20ZM4.46094 20V19H5.99941V5.11537C5.99941 4.65127 6.15678 4.26602 6.47151 3.95962C6.78626 3.65321 7.16735 3.5 7.61479 3.5H16.384C16.8481 3.5 17.2334 3.65321 17.5398 3.95962C17.8462 4.26602 17.9994 4.65127 17.9994 5.11537V19H19.5379V20H4.46094ZM6.99941 19H16.9994V5.11537C16.9994 4.93589 16.9417 4.78846 16.8263 4.67308C16.711 4.55769 16.5635 4.5 16.384 4.5H7.61479C7.4353 4.5 7.28787 4.55769 7.17249 4.67308C7.0571 4.78846 6.99941 4.93589 6.99941 5.11537V19Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
}
