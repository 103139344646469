import { StyleSheet, View } from 'react-native';

import { LRText, PaddedArea } from '../../../components';
import { IconTick } from '../../../components/icons';
import { Color, Style } from '../../../constants';

type Props = {
  title: string;
  free?: boolean;
  core?: boolean;
};

export default function PlanRow({ title, free, core }: Props) {
  return (
    <View style={[Style.fullWidth, Style.row]}>
      <View style={{ flex: 10 }}>
        {free ? (
          <PaddedArea
            style={[Style.row, Style.spaceBetween, Style.alignCenter]}
            h={1}
            v={0.5}
          >
            <LRText
              style={Style.alignCenter}
              typeface="body2"
              color="extraDarkGray"
              textAlign="right"
            >
              {title}
            </LRText>
            <IconTick />
          </PaddedArea>
        ) : (
          <PaddedArea style={styles.row} h={1} v={0.5}>
            <LRText typeface="body2" color="extraDarkGray">
              {title}
            </LRText>
          </PaddedArea>
        )}
      </View>
      <View style={{ flex: 3, backgroundColor: Color.lightGrey }}>
        <PaddedArea style={[styles.row, Style.alignCenter]} h={0.5} v={0.5}>
          <View style={styles.tickContainer}>
            {core && <IconTick color={Color.whiteHigh} />}
          </View>
        </PaddedArea>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  row: {
    height: 44,
    justifyContent: 'center',
  },
  tickContainer: {
    backgroundColor: Color.accent400,
    width: 24,
    height: 24,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
