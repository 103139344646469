import { CaptureContext } from '@sentry/types';
import * as Sentry from 'sentry-expo';

export default function logError(
  err: Error,
  origin: string,
  captureContext?: CaptureContext
): string {
  if (__DEV__) {
    console.error(`Logged error[${origin}]: ${err.message}`);
    return 'sentry-disabled-in-dev';
  } else {
    return Sentry.Browser.captureException(err, {
      ...captureContext,
      tags: {
        origin,
        ...(captureContext && 'tags' in captureContext
          ? captureContext?.tags
          : {}),
      },
    });
  }
}
