import React from 'react';
import { Image, StyleSheet, View } from 'react-native';

import { Api } from '../../api';
import { ActionsDropdown, LRText, PaddedArea, Spacer } from '../../components';
import { DropdownAction } from '../../components/ActionsDropdown';
import { IconAvatar, IconHelp, IconTick } from '../../components/icons';
import { IconDelete } from '../../components/icons';
import { Color, Dimen, Style } from '../../constants';

type Props = {
  trustee: Api.TrusteeListItem.V;
  onRemove: (trustee: Api.TrusteeListItem.V) => void;
};

export default function TrusteeItem({ trustee, onRemove }: Props) {
  const trusteeAvatar = trustee.avatar?.pathAvatar ?? undefined;

  return (
    <PaddedArea
      v={0.5}
      h={0.5}
      style={[
        Style.row,
        Style.alignCenter,
        {
          backgroundColor: Color.lightGrey,
          borderRadius: Dimen.cornerRadiusLarge,
        },
      ]}
    >
      {trusteeAvatar ? (
        <Image source={trusteeAvatar as any} style={styles.avatar} />
      ) : (
        <IconAvatar size={40} />
      )}
      <Spacer size={0.5} />
      <View>
        <LRText typeface="h3">{trustee.fullName}</LRText>
        <View style={[Style.row, Style.alignCenter]}>
          {trustee.isInvite ? (
            <>
              <IconHelp />
              <Spacer size={0.1} />
            </>
          ) : (
            <IconTick size={20} color={Color.accent400} />
          )}
          <LRText
            style={Style.alignCenter}
            color="darkGray"
            typeface="captionM"
          >
            {trustee.isInvite ? 'Pending invitation' : 'Invitation aproved'}
          </LRText>
        </View>
      </View>
      <Spacer fill />
      <ActionsDropdown
        actions={[
          {
            label: trustee.isInvite ? 'Cancel invite' : 'Remove trustee',
            icon: IconDelete,
            onPress: () => onRemove(trustee),
          } as DropdownAction,
        ]}
      />
    </PaddedArea>
  );
}

const styles = StyleSheet.create({
  avatar: {
    height: 40,
    width: 40,
    borderRadius: 20,
    backgroundColor: Color.gray2,
    overflow: 'hidden',
  },
});
