import { RouteProp, useNavigation } from '@react-navigation/native';
import { toast } from 'react-hot-toast';
import { View } from 'react-native';
import { useForm } from 'react-typed-form';

import { Api, useApiFormSubmit } from '../../api';
import {
  ErrorList,
  LRText,
  LinkButton,
  PaddedArea,
  Spacer,
} from '../../components';
import { Style } from '../../constants';
import { FieldMediaOrganiser, SubmitButton } from '../../form';
import { MainStackParamList } from '../../navigation';

type formShape = Api.SuggestedMediaBatch.W;

type Props = {
  route: RouteProp<MainStackParamList, 'Albums.SuggestMedia'>;
};

export default function SuggestAlbumMediaScreen({ route }: Props) {
  const { albumId, ownerName } = route.params;
  const navigation = useNavigation();

  const onSubmit = useApiFormSubmit<formShape>(
    'POST',
    '/suggested-media-batch',
    {
      onSuccess: () => {
        toast.success('Photos sent for approval');
        navigation.navigate('Albums.ViewAlbum', { albumId });
      },
    }
  );
  const form = useForm<formShape>({
    defaultValues: {
      album: `/albums/${albumId}`,
      suggestedMedias: [],
    },
    onSubmit: (values, _form) => {
      if (values.suggestedMedias.length === 0) {
        form.addSubmitError('suggestedMedias', 'must upload at least 1 item');
      } else {
        onSubmit(values, _form);
      }
    },
  });

  return (
    <PaddedArea v h>
      <LRText typeface="h1" color="accent">
        {`Add photos to ${ownerName}'s album`}
      </LRText>
      <Spacer size={0.5} />
      <LRText typeface="body2" color="gray7">
        {`These photos will be sent to ${ownerName} for publishing approval.`}
      </LRText>
      <FieldMediaOrganiser
        // @ts-expect-error
        field={form.getField('suggestedMedias')}
        disableDrag
      />
      <ErrorList errorList={form.errors.suggestedMedias} />
      <Spacer />
      <View style={Style.row}>
        <View style={{ flex: 2 }}>
          <LinkButton
            title="Cancel"
            buttonType="tertiary"
            to={{ screen: 'Albums.ViewAlbum', params: { albumId: albumId } }}
          />
        </View>
        <Spacer size={0.5} />
        <View style={{ flex: 3 }}>
          <SubmitButton {...form} disabled={form.isLoading} title="Save" />
        </View>
      </View>
    </PaddedArea>
  );
}
