import { StyleSheet, View } from 'react-native';

import { LRText, Spacer } from '../../../components';
import { IconCircleTick } from '../../../components/icons';
import { Color, Style } from '../../../constants';

type Props = {
  title: string;
  label: string;
  color?: string;
};

export default function HowItWorksRow({
  title,
  label,
  color = Color.accent700,
}: Props) {
  return (
    <View style={[Style.row]}>
      <View style={[Style.centerBoth]}>
        <IconCircleTick color={color} />
        <View style={styles.line} />
      </View>
      <Spacer />
      <View>
        <LRText style={styles.h4Bold} color="extraDarkGray" typeface="h4">
          {title}
        </LRText>
        <Spacer size={0.5} />
        <LRText typeface="body1" color="darkGray">
          {label}
        </LRText>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  line: {
    height: 68,
    width: 2,
    backgroundColor: Color.accent100,
  },
  h4Bold: {
    fontFamily: 'DMSans_700Bold',
  },
});
