import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import * as React from 'react';
import { ActivityIndicator, Pressable, StyleSheet, View } from 'react-native';

import { LRText } from '../../components';
import { IconMediaDelete, IconMediaDrag } from '../../components/icons';
import { Dimen, Style } from '../../constants';
import type { Media } from './FieldMediaOrganiser';

type Props = {
  media: Media;
  size: number;
  onDelete: () => void;
  disableDrag?: boolean;
};

export default function FieldMediaOrganiserItem({
  media,
  size,
  onDelete,
  disableDrag,
}: Props) {
  const imagePreview = React.useMemo(() => {
    if (media.albumMediaId && media.uploadData?.pathOrganiserThumb) {
      return media.uploadData.pathOrganiserThumb;
    }
    return media.file?.type.startsWith('image/')
      ? URL.createObjectURL(media.file)
      : null;
  }, [media.albumMediaId, media.file, media.uploadData?.pathOrganiserThumb]);
  const videoPreview = React.useMemo(() => {
    if (media.albumMediaId && media.uploadData?.pathOrganiserThumb) {
      return null;
    }
    return media.file?.type.startsWith('video/')
      ? URL.createObjectURL(media.file)
      : null;
  }, [media.albumMediaId, media.file, media.uploadData?.pathOrganiserThumb]);

  React.useEffect(() => {
    // Revoke data uris to avoid memory leaks on unmount
    return () => {
      imagePreview && URL.revokeObjectURL(imagePreview);
      videoPreview && URL.revokeObjectURL(videoPreview);
    };
  }, [imagePreview, videoPreview]);

  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: media.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 100 : 10,
  };

  const imgSrc = imagePreview;

  return (
    <div
      style={{
        boxSizing: 'border-box',
        width: size,
        height: size,
        padding: Dimen.spacing * 0.125,
        position: 'relative',
        ...style,
      }}
      ref={setNodeRef}
    >
      <View
        style={[
          styles.containerVisible,
          {
            width: size - Dimen.spacing * 0.25,
            height: size - Dimen.spacing * 0.25,
          },
        ]}
      >
        {imgSrc && (
          <img
            src={imgSrc}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              opacity: media.uploadData ? 1 : 0.5,
            }}
            // Revoke data uri after image is loaded
            onLoad={() => {
              imagePreview && URL.revokeObjectURL(imagePreview);
            }}
          />
        )}
        {videoPreview && (
          <video
            autoPlay={false}
            src={videoPreview}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              opacity: media.uploadData ? 1 : 0.5,
            }}
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(videoPreview);
            }}
          />
        )}
        {!media.uploadData && (
          <View style={[StyleSheet.absoluteFill, Style.centerBoth]}>
            {media.uploadError ? (
              <LRText color="red900">Error</LRText>
            ) : (
              <ActivityIndicator />
            )}
          </View>
        )}
        <Pressable
          onPress={onDelete}
          style={({ hovered }: any) => [
            styles.button,
            { position: 'absolute', top: 7, right: 7 },
            hovered && styles.buttonHovered,
          ]}
        >
          <IconMediaDelete />
        </Pressable>
        {!disableDrag && (
          <div
            {...attributes}
            {...listeners}
            style={{ position: 'absolute', top: 7, left: 7, cursor: 'move' }}
          >
            <View style={styles.button}>
              <IconMediaDrag />
            </View>
          </div>
        )}
      </View>
    </div>
  );
}

const styles = StyleSheet.create({
  containerVisible: {
    backgroundColor: '#eee',
    position: 'relative',
    overflow: 'hidden',
  },
  button: {
    width: 26,
    height: 24,
    backgroundColor: '#fffa',
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    transitionProperty: 'background-color',
    transitionDuration: '200ms',
  },
  buttonHovered: {
    backgroundColor: '#fff',
  },
});
