import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { toast } from 'react-hot-toast';
import { Image, StyleSheet, View } from 'react-native';

import { Api, useApiCallable } from '../../api';
import {
  Button,
  LRText,
  LinkButton,
  PaddedArea,
  ScreenError,
  ScreenLoading,
  Spacer,
} from '../../components';
import { Color, Dimen, Style } from '../../constants';
import { useLRDataContext } from '../../contexts';
import { MainStackParamList } from '../../navigation';
import { useTypedDispatch, useTypedSelector } from '../../reducers';
import Cookies from 'universal-cookie';
import { useUserOverview } from '../../hooks';

type Props = NativeStackScreenProps<MainStackParamList, 'Home.Invites'>;

export default function InvitesScreen({ route, navigation }: Props) {
  const { user: authUser } = useTypedSelector((state) => state.auth);
  const { referralCode } = useTypedSelector((state) => state.auth);
  const cookies = new Cookies();
  const friendCode = route.params?.friendInviteCode ?? referralCode ?? cookies.get('lr_friend_invite');

  const callApi = useApiCallable();
  const { userOverview } = useLRDataContext();
  const dispatch = useTypedDispatch();

  React.useEffect(() => {
    if (!authUser && friendCode) {
      cookies.set('lr_friend_invite', friendCode, { path: '/', sameSite: 'strict'});
      dispatch({
        type: 'FRIEND_REFERRAL',
        payload: { referralCode: friendCode },
      });
      navigation.navigate('Auth.Login');
    }
  }, [authUser, dispatch, friendCode, navigation]);

  if (!friendCode) {
    return (
      <PaddedArea style={[Style.centerBoth, Style.fill]}>
        <LRText>Error: No friend code in URL</LRText>
      </PaddedArea>
    );
  }

  const api = Api.User.useReadItemFriendsInvite(friendCode);
  const friend = api.data;
  const { managingUserFeaturedFriends }  = useUserOverview();

  if (friend?.id === authUser?.id) {
    return (
      <PaddedArea h={2} v={2} style={[Style.fill, Style.centerBoth]}>
        <LRText textAlign="center" typeface="h1">
          You can't accept your own friend request
        </LRText>
      </PaddedArea>
    );
  }

  if (managingUserFeaturedFriends.find(element => element?.id === friend?.id) ) {
    return (
      <PaddedArea h={2} v={2} style={[Style.fill, Style.centerBoth]}>
        <LRText textAlign="center" typeface="h1">
          {`You are already friend with ${friend.firstName} ${friend.lastName}`}
        </LRText>
      </PaddedArea>
    );
  }

  if (!friend) {
    return <ScreenLoading />;
  }

  if (api.error && !friend) {
    return <ScreenError {...api} />;
  }

  if (cookies.get('lr_friend_invite')) {
    cookies.set('lr_friend_invite', '',{ expires: new Date('2000-01-01')});
  }
  return (
    <PaddedArea style={styles.container} h v>
      <Spacer fill />
      <LRText textAlign="center" typeface="h1" color={'extraDarkGray'}>
        Friend request
      </LRText>
      <Spacer size={1.5} />
      <Image source={friend.avatar?.pathAvatar as any} style={styles.avatar} />
      <Spacer />
      <LRText textAlign="center">{`${friend.firstName} ${friend.lastName} wants to add you to his Friends Wall.`}</LRText>
      <Spacer size={0.5} />
      <LRText textAlign="center">
        After accepting, you can both share and add media files to albums,
        allowing you to share your memories together.
      </LRText>
      <Spacer fill />
      <View style={Style.fullWidth}>
        <Button
          title="Accept Request"
          onPress={async () => {
            const response = await callApi('/friendships', {
              method: 'POST',
              jsonBody: {
                user: userOverview?.managingUser['@id'],
                friend: friend['@id'],
              },
            });
            if (response) {
              const latestFriendshipApi =  await callApi(userOverview?.managingUser['@id'] + '/friendships/latest');
              const latestFriendship = latestFriendshipApi.data['hydra:member'];
              navigation.navigate('Albums.CreateAlbumFriendship', {
                friendshipId: latestFriendship[0].id,
                friendName: friend.firstName,
              });
              toast.success(
                `You were added to ${friend.firstName} ${friend.lastName} friend wall`
              );
            }
          }}
        />
        <Spacer size={0.5} />
        <LinkButton
          buttonType="tertiary"
          title="Reject"
          to={{ screen: 'Home.Home' }}
          onPress={() => {
            dispatch({
              type: 'REJECT_FRIEND_REFERRAL',
            });
          }}
        />
      </View>
    </PaddedArea>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    width: 84,
    height: 84,
    borderRadius: 42,
    backgroundColor: Color.gray2,
    border: `3px solid ${Color.lightGrey}`,
  },
  card: {
    backgroundColor: 'white',
    borderRadius: Dimen.cornerRadiusLarge,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.08,
    shadowRadius: 14,
  },
});
