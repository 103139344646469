import { useTypedSelector } from '../reducers';

/**
 * Only use this when you're on a screen that can ONLY be seen by logged in users.
 */
export default function useManaging() {
  const { managingUserId, user } = useTypedSelector((state) => state.auth);

  if (!user) {
    throw new Error(
      "useManaging: auth.user is not set.  This hook should only be used where you're sure the user is logged in"
    );
  }

  return {
    actingAsTrustee: managingUserId && managingUserId !== user.id,
    managingId: managingUserId ?? user.id,
  };
}
