import type { ReadConfig } from 'api-read-hook';
import { useApiRead } from 'api-read-hook';

export type V = {
  '@id': string;
  '@type': 'Sponsor';
  id: string;
  name: string;
  description:string;
  url:string;
  logo:string;
};

export function useReadItemSponsor(
  roomType: string,
  options?: ReadConfig
) {
  return useApiRead<V>(
    `/sponsor/${roomType}`,
    options
  );
}
