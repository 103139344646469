import type { Action } from '../actions';
import { OnboardingScreenName } from '../components/ScreenOnboarding';

type OnboardingState = Readonly<{
  completedScreens: OnboardingScreenName[];
}>;

const initialState = {
  completedScreens: [],
};

export default function onboarding(
  state: OnboardingState = initialState,
  action: Action
): OnboardingState {
  switch (action.type) {
    case 'ONBOARDING_COMPLETE':
      return {
        ...state,
        completedScreens: [...state.completedScreens, action.payload.screen],
      };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}
