import type { ReadConfig } from 'api-read-hook';
import { useApiRead } from 'api-read-hook';

import type { HydraCollection } from '../../types';

export type V_RootList = {
  '@id': string;
  '@type': 'VaultCategory';
  id: string;
  name: string;
  description: string | null;
};

export function useReadCollectionRootList(options?: ReadConfig) {
  return useApiRead<HydraCollection<V_RootList>>(
    '/vault-categories/root-list',
    options
  );
}
