import { NavigationProp, RouteProp } from '@react-navigation/native';
import * as React from 'react';
import { View } from 'react-native';
import { useForm } from 'react-typed-form';

import { Api, ApiError, useApiFormSubmit } from '../../../api';
import { Button, ErrorList, LRText, PaddedArea, Spacer } from '../../../components';
import { Style } from '../../../constants';
import { useLayoutContext } from '../../../contexts';
import { FieldCode, SubmitButton } from '../../../form';
import { useUserOverview } from '../../../hooks';
import { MainStackParamList } from '../../../navigation';

type Props = {
  route: RouteProp<MainStackParamList, 'Account.TwoFactorConfirmCode'>;
  navigation: NavigationProp<MainStackParamList>;
};

export default function TwoFactorConfirmCodeScreen({ navigation }: Props) {
  const { isDesktopLayout } = useLayoutContext();
  const { myUser } = useUserOverview();

  const form = useForm<{ code: string }>({
    defaultValues: {
      code: '',
    },
    onSubmit: useApiFormSubmit<{
      code: string;
    }>('POST', '/2fa/confirm', {
      onSuccess: () => {
        navigation.navigate('Vault.RootList');
      },
      errorHandlerCreator:
        ({ addSubmitError }) =>
        (err, originalHandler) => {
          if (
            err instanceof ApiError &&
            err.response.status === 400
          ) {
            addSubmitError(
              '_form',
              'The code you submitted is invalid. Please try again'
            );
          } else {
            originalHandler(err);
          }
        },
    }),
  });

  let pn = myUser.phoneNumber ?? '';
  let phoneNumberEnd = pn.substring(pn.length - 3);
  return (
    <PaddedArea style={Style.fill} v h>
      <View style={[Style.alignCenter, Style.fill]}>
        {!isDesktopLayout && <Spacer fill />}
        <LRText textAlign="center" typeface="h1">
          Enter verification code
        </LRText>
        <Spacer size={0.5} />
        <View style={[Style.row, Style.alignCenter]}>
          <LRText color="darkGray" textAlign="center" typeface="body1">
            {`Sent to ${phoneNumberEnd}`}
          </LRText>
          { !myUser.phoneverified &&
          <>
          <Spacer size={0.5} />
          <Button
            buttonType="text"
            buttonSize="tiny"
            title="Edit"
            onPress={() => {
              navigation.goBack();
            }}
          />
          </>
          }
        </View>
        <Spacer size={3} />
        <FieldCode field={form.getField('code')} />
        <Spacer size={1.5} />
        <View style={[Style.row, Style.alignCenter]}>
          <LRText color="darkGray" textAlign="center" typeface="body2">
            Didn't receive it?
          </LRText>
          <Button
            buttonType="link"
            buttonSize="tiny"
            title="Resend"
            onPress={() => {
              navigation.goBack();
            }}
          />
        </View>
        <ErrorList errorList={form.formErrorList} />
        <Spacer size={2} fill={!isDesktopLayout} />
        <View style={[Style.row, Style.fullWidth]}>
          <View style={{ flex: 2 }}>
            <Button
              title="Back"
              buttonType="tertiary"
              onPress={() => {
                navigation.goBack();
              }}
            />
          </View>
          <Spacer size={0.5} />
          <View style={{ flex: 3 }}>
            <SubmitButton
              disabled={form.getField('code').value?.length !== 6}
              {...form}
              title="Submit"
            />
          </View>
        </View>
      </View>
    </PaddedArea>
  );
}
