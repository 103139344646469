import { NavigationProp, RouteProp } from '@react-navigation/native';

import { Api } from '../../../api';
import { ScreenError, ScreenLoading } from '../../../components';
import { MainStackParamList } from '../../../navigation';
import { EditFileLoaded } from '../components';

type Props = {
  navigation: NavigationProp<MainStackParamList>;
  route: RouteProp<MainStackParamList, 'Vault.EditFile'>;
};

export default function EditFileScreen({ route }: Props) {
  const { fileId } = route.params;

  const api = Api.VaultEntry.useReadItem(fileId);

  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ScreenLoading />;

  const exisiting = api.data;

  return <EditFileLoaded exisiting={exisiting} />;
}
