export default function IconClose() {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99956 3.93906L8.71206 0.226562L9.77256 1.28706L6.06006 4.99956L9.77256 8.71206L8.71206 9.77256L4.99956 6.06006L1.28706 9.77256L0.226562 8.71206L3.93906 4.99956L0.226562 1.28706L1.28706 0.226562L4.99956 3.93906Z"
        fill="#09121F"
      />
    </svg>
  );
}
