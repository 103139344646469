import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Api } from '../../../api';
import { LRText, PaddedArea, PressableLink, Spacer } from '../../../components';
import { IconCaretSmall } from '../../../components/icons';
import { Color, Dimen, Style } from '../../../constants';

type Props = {
  category: Api.VaultCategory.V_RootList;
};

export default function RootCategoryButton({ category }: Props) {
  return (
    <View style={styles.wrapper}>
      <PressableLink
        style={({ hovered }: any) => [
          styles.button,
          hovered && styles.buttonHovered,
        ]}
        to={{
          screen: 'Vault.CategoryExplore',
          params: { categoryId: category.id },
        }}
      >
        <PaddedArea
          style={[
            Style.row,
            Style.fullWidth,
            Style.alignCenter,
            Style.spaceBetween,
            styles.buttonPadding,
          ]}
        >
          <View>
            <LRText typeface="h3">{category.name}</LRText>
            {category.description && (
              <>
                <Spacer size={0.25} />
                <LRText typeface="body2" color="darkGray">
                  {category.description}
                </LRText>
              </>
            )}
          </View>
          <View style={styles.iconContainer}>
            <IconCaretSmall direction="right" />
          </View>
        </PaddedArea>
      </PressableLink>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: Color.accent50,
    borderRadius: Dimen.cornerRadiusLarge,
    overflow: 'hidden',
  },
  button: {
    backgroundColor: Color.lightGrey,
    transitionDuration: '200ms',
  },
  buttonHovered: {
    backgroundColor: Color.lightGreyHovered,
  },
  buttonPadding: {
    paddingVertical: Dimen.spacing * 0.75,
    paddingLeft: Dimen.spacing,
    paddingRight: Dimen.spacing * 0.5,
  },
  iconContainer: {
    backgroundColor: Color.accent400,
    height: 30,
    width: 30,
    borderRadius: Dimen.cornerRadiusExtraLarge,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
