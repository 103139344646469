import { View } from 'react-native';

import { Config, Style } from '../../constants';
import { useUserOverview } from '../../hooks';

export default function PreviewRoomScreen() {
  const { managingUser } = useUserOverview();

  return (
    <View style={[Style.fill]}>
      <iframe
        frameBorder={0}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          border: 'none',
          width: '100%',
          height: '100%',
        }}
        src={`${Config.VISITOR_ROOT}/${managingUser.roomSlug}`}
      />
    </View>
  );
}
