import { NavigationProp, RouteProp } from '@react-navigation/native';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { RoomTriggerType } from '../../../types';
import { Api, useApiCallable } from '../../api';
import {
  ConfirmationModal,
  EmptyListScreen,
  IconLinkButton,
  PaddedArea,
  ScreenError,
  ScreenHeading,
  ScreenLoading,
  ScreenOnboarding,
  Spacer,
} from '../../components';
import { IconPlus } from '../../components/icons';
import { OnboardingScreenName } from '../../components/ScreenOnboarding';
import { Color, Dimen, Style } from '../../constants';
import { useLayoutContext } from '../../contexts';
import { useDynamicPageTitle, useUserOverview } from '../../hooks';
import { Model } from '../../models';
import { MainStackParamList } from '../../navigation';
import { useTypedSelector } from '../../reducers';
import { AlbumListItem } from './components';

type Props = {
  route: RouteProp<MainStackParamList, 'Albums.AlbumsList'>;
  navigation: NavigationProp<MainStackParamList>;
};

export default function AlbumsListScreen({ route, navigation }: Props) {
  const { roomTriggerType } = route.params;
  const callApi = useApiCallable();
  const { managingUser } = useUserOverview();
  const { isDesktopLayout } = useLayoutContext();
  const { completedScreens } = useTypedSelector((state) => state.onboarding);

  const [showOnboarding, setShowOnboarding] = React.useState(
    !completedScreens.includes(roomTriggerType as RoomTriggerType)
  );
  const [deleteModalRoomTriggerId, setDeleteModalRoomTriggerId] =
    React.useState<string | null>(null);
  const albumType = React.useMemo(
    () => Model.RoomTrigger.typeToReadableName(roomTriggerType),
    [roomTriggerType]
  );
  useDynamicPageTitle(`View ${albumType} Album`);

  const api = Api.RoomTrigger.useReadCollectionUserSub(managingUser.id, {
    type: roomTriggerType,
  });

  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ScreenLoading />;
  const roomTriggers = api.data['hydra:member'];

  if (roomTriggerType === 'welcome_note' && roomTriggers.length > 0) {
    navigation.navigate('Albums.ViewAlbum', {
      albumId: roomTriggers[0].album!.id,
    });
  }

  let nextAvailableSlot = 1;
  while (roomTriggers.some((rt) => rt.slot === nextAvailableSlot)) {
    nextAvailableSlot += 1;
  }

  async function onDeleteConfirm() {
    if (!deleteModalRoomTriggerId) return;
    await callApi(`/room-triggers/${deleteModalRoomTriggerId}`, {
      method: 'DELETE',
    });
    api.invalidate();
    setDeleteModalRoomTriggerId(null);
  }

  if (showOnboarding) {
    return (
      <PaddedArea v h style={Style.fill}>
        <ScreenOnboarding
          screen={roomTriggerType as OnboardingScreenName}
          onAfterDismiss={() => setShowOnboarding(false)}
        />
      </PaddedArea>
    );
  }

  return (
    <>
      <PaddedArea v h style={Style.fill}>
        {roomTriggers.length > 2 && (
          <>
            <ScreenHeading
              title={Model.RoomTrigger.typeToReadableName(albumType, {
                plural: true,
              })}
              onHelpPress={() => setShowOnboarding(true)}
            />
            <Spacer />
          </>
        )}  
        {(roomTriggers.length > 0 && roomTriggers.length < 3)  && (
          <>
            <ScreenHeading
              title={Model.RoomTrigger.typeToReadableName(albumType, {
                plural: true,
              })}
              onHelpPress={() => setShowOnboarding(true)}
              rightButton={
                <IconLinkButton
                  to={{
                    screen: 'Albums.CreateAlbumRoomTrigger',
                    params: {
                      roomTriggerType: roomTriggerType,
                      roomTriggerSlot: nextAvailableSlot,
                    },
                  }}
                >
                  <IconPlus color={Color.whiteHigh} />
                </IconLinkButton>
              }
            />
            <Spacer />
          </>
        )}
        {roomTriggers.length === 0 ? (
          <EmptyListScreen
            roomTriggerType={roomTriggerType as RoomTriggerType}
            onAdd={() => {
              navigation.navigate('Albums.CreateAlbumRoomTrigger', {
                roomTriggerType: roomTriggerType,
                roomTriggerSlot: nextAvailableSlot,
              });
            }}
          />
        ) : (
          <View
            style={[
              isDesktopLayout && styles.desktopAlbumsContainer,
              styles.albumsContainer,
            ]}
          >
            {roomTriggers.map((roomTrigger) => {
              const album = roomTrigger.album;
              if (!album) {
                throw new Error(
                  `${roomTrigger['@id']} is ${roomTriggerType} but missing album`
                );
              }
              return (
                <AlbumListItem
                  to={{
                    screen: 'Albums.ViewAlbum',
                    params: { albumId: album.id },
                  }}
                  label={`${albumType} ${roomTrigger.slot ?? ''}`}
                  album={album}
                  onDeletePress={() =>
                    setDeleteModalRoomTriggerId(roomTrigger.id)
                  }
                />
              );
            })}
          </View>
        )}
      </PaddedArea>
      <ConfirmationModal
        visible={!!deleteModalRoomTriggerId}
        onConfirm={onDeleteConfirm}
        onCancel={() => setDeleteModalRoomTriggerId(null)}
        title="Are you sure?"
        message="All media in this album and the album itself will be permanently
        deleted."
      />
    </>
  );
}

const styles = StyleSheet.create({
  albumsContainer: {
    gap: Dimen.spacing,
  },
  desktopAlbumsContainer: {
    flexDirection: 'row',
    overflow: 'hidden',
    flexWrap: 'wrap',
  },
});
