import * as React from 'react';
import { Image, StyleSheet, View } from 'react-native';

import { Style } from '../constants';
import { useLayoutContext } from '../contexts';
import { useTypedDispatch } from '../reducers';
import { Button, LRText, Spacer } from '.';

type Props = {
  screen: OnboardingScreenName;
  onAfterDismiss?: () => void;
};

export type OnboardingScreenName =
  | 'book'
  | 'wall_picture'
  | 'photo_frame'
  | 'friends_wall'
  | 'digital_vault'
  | 'trustee'
  | 'welcome_note';

const BookshelfOnboardingImage = require('../../assets/img/pods/albums/onboarding-bookshelf.png');
const DesktopVideoOnboardingImage = require('../../assets/img/pods/albums/onboarding-desktop-video.png');
const FriendsWallOnboardingImage = require('../../assets/img/pods/friends/onboarding-friends-wall.png');
const PictureFramesOnboardingImage = require('../../assets/img/pods/albums/onboarding-picture-frames.png');
const WallPicturesOnboardingImage = require('../../assets/img/pods/albums/onboarding-wall-picture.png');
const DigitalVaultOnboardingImage = require('../../assets/img/pods/digitalVault/onboarding-digital-vault.png');
const TrusteeOnboardingImage = require('../../assets/img/pods/trustees/onboarding-trustee.png');

const getImageForScreen = (screen: OnboardingScreenName) => {
  switch (screen) {
    case 'book':
      return <Image style={styles.image} source={BookshelfOnboardingImage} />;
    case 'welcome_note':
      return (
        <Image style={styles.image} source={DesktopVideoOnboardingImage} />
      );
    case 'friends_wall':
      return <Image style={styles.image} source={FriendsWallOnboardingImage} />;
    case 'photo_frame':
      return (
        <Image style={styles.image} source={PictureFramesOnboardingImage} />
      );
    case 'wall_picture':
      return (
        <Image style={styles.image} source={WallPicturesOnboardingImage} />
      );
    case 'digital_vault':
      return (
        <Image style={styles.image} source={DigitalVaultOnboardingImage} />
      );
    case 'trustee':
      return (
        <Image style={styles.trusteeImage} source={TrusteeOnboardingImage} />
      );
    default:
      return (
        <Image style={styles.image} source={PictureFramesOnboardingImage} />
      );
  }
};

const getLabelForScreen = (screen: OnboardingScreenName) => {
  switch (screen) {
    case 'book':
      return 'Create your lifestory using photos and video as visuals and adding up to 200 words per page. Each page must contain a picture and only up to 200 words per picture.';
    case 'welcome_note':
      return 'Make a great first impression with our welcome video. Upload a personalised video that will greet visitors when they enter your virtual room.';
    case 'friends_wall':
      return 'Add friends to your Friends Wall and start to share your mutual memories together by creating digital albums.';
    case 'photo_frame':
      return 'Frame your special people or moments, as you would in a room at home.';
    case 'trustee':
      return 'A trustee is a special person who will be able to manage the room and carry out wishes.';
    case 'digital_vault':
      return 'You can securely store important information to your digital vault that will only be accessible when the time comes';
    case 'wall_picture':
    default:
      return 'Showcase your favorite photos on the wall of your Legacy Room and change them whenever you like.';
  }
};

const getTitleForScreen = (screen: OnboardingScreenName) => {
  switch (screen) {
    case 'book':
      return 'Preserve your memories';
    case 'welcome_note':
      return 'Welcome Note';
    case 'friends_wall':
      return 'Friends Wall';
    case 'photo_frame':
      return 'Share your significant events';
    case 'wall_picture':
      return 'Showcase your most treasured memories';
    case 'trustee':
      return 'Your Trustees';
    case 'digital_vault':
        return 'Store your important information';
    default:
      return 'Showcase your most treasured memories';
  }
};

export default function ScreenOnboarding({ screen, onAfterDismiss }: Props) {
  const { isDesktopLayout } = useLayoutContext();
  const dispatch = useTypedDispatch();

  return (
    <View style={[Style.alignCenter, Style.fill]}>
      <Spacer size={4} />
      {getImageForScreen(screen)}
      <Spacer />
      <LRText textAlign="center" typeface="subheader">
        {getTitleForScreen(screen)}
      </LRText>
      <Spacer />
      <LRText textAlign="center" typeface="body1">
        {getLabelForScreen(screen)}
      </LRText>
      <Spacer size={2} fill={!isDesktopLayout} />
      <View style={!isDesktopLayout && { width: '100%' }}>
        <Button
          title={'Continue'}
          onPress={() => {
            dispatch({
              type: 'ONBOARDING_COMPLETE',
              payload: {
                screen,
              },
            });
            onAfterDismiss?.();
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  image: {
    width: 200,
    height: 200,
    resizeMode: 'contain',
  },
  trusteeImage: {
    width: 220,
    height: 56,
    resizeMode: 'contain',
  },
});
