import { useNavigation } from '@react-navigation/native';
import * as React from 'react';

/**
 * Set the document title when it's based of data fetched within the page.
 * Null will leave the original page title, anything else will override it.
 * Once the title has been overidden, you won't be able to get the default back.
 */
export default function useDynamicPageTitle(pageTitle: string | null) {
  const navigation = useNavigation();

  React.useEffect(() => {
    if (!pageTitle) return;
    navigation.setOptions({
      title: pageTitle,
    });
  }, [pageTitle, navigation]);
}
