export default function IconLogout() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_193_18383"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_193_18383)">
        <path
          d="M5.79922 21C5.34922 21 4.96989 20.846 4.66122 20.538C4.35322 20.2293 4.19922 19.8417 4.19922 19.375V5.625C4.19922 5.15833 4.35322 4.771 4.66122 4.463C4.96989 4.15433 5.34922 4 5.79922 4H12.2242V5H5.79922C5.64922 5 5.51189 5.06267 5.38722 5.188C5.26189 5.31267 5.19922 5.45833 5.19922 5.625V19.375C5.19922 19.5417 5.26189 19.6873 5.38722 19.812C5.51189 19.9373 5.64922 20 5.79922 20H12.2242V21H5.79922ZM16.2742 16.05L15.5742 15.325L17.8742 13H9.22422V12H17.8742L15.5742 9.675L16.2742 8.95L19.7992 12.5L16.2742 16.05Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
}
