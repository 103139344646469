import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Button, LRModal, LRText, Spacer } from '../components';
import { Style } from '../constants';

type Props = {
  visible: boolean;
  onConfirm?: () => void;
  onCancel: () => void;
  title: string;
  confirmationText?: string;
  cancelText?: string;
  message?: string;
};

export default function ConfirmationModal({
  visible,
  onConfirm,
  onCancel,
  title,
  confirmationText = 'Confirm',
  cancelText = 'Cancel',
  message,
}: Props) {
  return (
    <LRModal onDismiss={onCancel} visible={visible}>
      <LRText typeface="h2" color="accent" textAlign="center">
        {title}
      </LRText>
      <Spacer />
      {message && (
        <>
          <LRText textAlign="center">{message}</LRText>
          <Spacer />
        </>
      )}
      <View style={Style.row}>
        <View style={styles.cancelButton}>
          <Button
            title={cancelText}
            buttonType={onConfirm ? 'tertiary' : 'primary'}
            onPress={onCancel}
          />
        </View>

        {onConfirm && (
          <>
            <Spacer size={0.5} />
            <View style={styles.confirmButton}>
              <Button
                title={confirmationText}
                buttonType="red900"
                onPress={onConfirm}
              />
            </View>
          </>
        )}
      </View>
    </LRModal>
  );
}

const styles = StyleSheet.create({
  cancelButton: {
    flex: 2,
  },
  confirmButton: {
    flex: 3,
  },
});
