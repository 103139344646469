import { NavigationAction, useLinkProps } from '@react-navigation/native';
import * as React from 'react';
import { Pressable, StyleProp, ViewStyle } from 'react-native';

import { To } from '../types';

type Props = Omit<
  React.ComponentProps<typeof Pressable>,
  'children' | 'style'
> & {
  to: To;
  action?: NavigationAction;
  style?:
    | StyleProp<ViewStyle>
    | ((options: {
        hovered?: boolean;
        pressed?: boolean;
      }) => StyleProp<ViewStyle>);
  children:
    | React.ReactNode
    | ((options: { hovered?: boolean; pressed?: boolean }) => React.ReactNode);
};

/**
 * Link react-navigation's built-in Link, but allows hover states
 *
 * @see https://reactnavigation.org/docs/link
 */
export default function PressableLink({
  to,
  action,
  children,
  disabled,
  ...rest
}: Props) {
  const linkProps = useLinkProps({ to: to ?? '/', action });
  const finalDisabled = disabled || !to;

  return (
    <Pressable
      {...rest}
      disabled={finalDisabled}
      {...(finalDisabled ? {} : linkProps)}
      // Even though this comes from linkProps, we need to make sure this never
      // gets removed, otherwise you can get stuck with hover states enabled
      // when a button gets disabled
      accessibilityRole="link"
    >
      {children}
    </Pressable>
  );
}
