const sidebarWidth = 270;
const spacing = 20;

export default {
  contentMaxWidth: 820,

  cornerRadiusExtraLarge: 20,
  cornerRadiusLarge: 10,
  cornerRadiusSmall: 5,

  headerHeight: 70,
  headerLinearGradientHeight: 150,

  sidebarWidth,
  sidebarTotalWidth: sidebarWidth + spacing * 2,

  spacing,
};
