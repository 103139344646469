import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useCallback, useEffect } from 'react';
import React from 'react';
import { Linking, StyleSheet, View } from 'react-native';

import { useApiCallable } from '../../api';
import {
  Button,
  LRText,
  PaddedArea,
  ScreenHeading,
  Spacer,
} from '../../components';
import { Color, Dimen, Style } from '../../constants';
import { useLayoutContext } from '../../contexts';
import { useManaging } from '../../hooks';
import { MainStackParamList } from '../../navigation';
import { HowItWorksRow, PlanRow } from './components';

type Props = NativeStackScreenProps<MainStackParamList, 'Home.Billing'>;

export default function BillingScreen({}: Props) {
  const { isDesktopLayout } = useLayoutContext();
  const { actingAsTrustee } = useManaging();

  const [checkoutLink, setCheckoutLink] = React.useState<null | string>(null);
  const callApi = useApiCallable();
  const getCheckoutLink = useCallback(async () => {
    const response = await callApi<{ url: string }, unknown>(
      '/subscription/checkout',
      {
        method: 'POST',
        jsonBody: {
          key: 'core',
        },
      }
    );

    if (response && response.data) {
      setCheckoutLink(response.data.url);
    }
  }, [callApi, setCheckoutLink]);

  useEffect(() => {
    if (!actingAsTrustee) {
      getCheckoutLink();
    }
  }, [actingAsTrustee, getCheckoutLink]);

  if (actingAsTrustee) {
    return (
      <PaddedArea style={Style.fill} h v>
        <ScreenHeading title="Billing" />
        <Spacer />
        <LRText>Sorry, only the Account Owner can manage Billing.</LRText>
      </PaddedArea>
    );
  }

  return (
    <PaddedArea style={styles.container} h v>
      <View>
        <LRText
          typeface="h1"
          textAlign="center"
          color={!isDesktopLayout ? 'whiteHigh' : 'accent'}
        >
          Get 30 days of the Core plan for free
        </LRText>
        <Spacer />
        <View style={styles.card}>
          <View style={[Style.fullWidth, Style.row]}>
            <View style={styles.billingPlanHeaderLeft}>
              <PaddedArea v={0.5} h={0.75}>
                <LRText
                  typeface="body2"
                  color="extraDarkGray"
                  textAlign="right"
                >
                  Free
                </LRText>
              </PaddedArea>
            </View>
            <View style={styles.billingPlanHeaderRight}>
              <PaddedArea h={0.5} v={0.5}>
                <LRText
                  typeface="body2"
                  color="extraDarkGray"
                  textAlign="center"
                >
                  Core
                </LRText>
              </PaddedArea>
            </View>
          </View>
          <PlanRow title={'Friends Wall'} free core />
          <PlanRow title={'Welcome Note'} free core />
          <PlanRow title={'Photo Frames'} core />
          {/* <PlanRow title={'Travel Tracker'} core /> */}
          <PlanRow title={'Digital Vault'} core />
          <PlanRow title={'Wall Pictures'} core />
          <PlanRow title={'Bookshelf'} core />
          {/* <PlanRow title={'Passions and Pastimes'} core />
          <PlanRow title={'Time Capsule Messages'} core /> */}
          <View style={[Style.fullWidth, Style.row]}>
            <View style={styles.billingPlanHeaderLeft} />
            <View style={styles.billingPlanFooterRight} />
          </View>
        </View>
        <Spacer />
        <PaddedArea h={2}>
          <LRText typeface="body2" textAlign="center" color="extraDarkGray">
            The Core Plan is free for 30 days. Then £25/month
          </LRText>
        </PaddedArea>
        <Spacer size={1.5} />
        <Button
          loading={!checkoutLink}
          onPress={() => {
            if (checkoutLink) {
              Linking.openURL(checkoutLink);
            }
          }}
          title="Start my 30-Day Free Trial now"
        />
        {/* <Spacer size={0.5} />
        <LRText textAlign="center" typeface="captionM" color="darkGray">
          No add card details required
        </LRText> */}
      </View>
      <Spacer size={2} />
      <View>
        <LRText color="extraDarkGray" typeface="h3">
          How it works
        </LRText>
        <Spacer />
        <HowItWorksRow
          title="Today"
          label={
            'We unlock the standard features for you to\nexplore Legacy Room fully.'
          }
        />
        <Spacer />
        <HowItWorksRow
          title="In 28 days"
          label={'Receive a reminder about when your\ntrial will end. '}
          color={Color.accent100}
        />
        <Spacer />
        <HowItWorksRow
          title="In 30 days"
          label={
            'Choose a plan to keep your\nbelongings safe in the Legacy Room.'
          }
          color={Color.accent100}
        />
      </View>
    </PaddedArea>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
  },
  card: {
    backgroundColor: 'white',
    overflow: 'hidden',
    borderRadius: Dimen.cornerRadiusLarge,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.08,
    shadowRadius: 14,
  },
  billingPlanHeaderLeft: {
    flex: 10,
  },
  billingPlanHeaderRight: {
    flex: 3,
    backgroundColor: Color.lightGrey,
  },
  billingPlanFooterLeft: {
    flex: 10,
    height: 10,
  },
  billingPlanFooterRight: {
    flex: 3,
    height: 10,
    backgroundColor: Color.lightGrey,
  },
});
