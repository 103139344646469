import React from 'react';
import { StyleSheet, View } from 'react-native';

import { LRText, Spacer } from '../../../components';
import { IconPaperClip } from '../../../components/icons';
import { Style } from '../../../constants';

type Props = {
  fileName: string;
  createdAt: string;
};

export default function FileDetails({ fileName, createdAt }: Props) {
  const fileNameParts = fileName.split('.');
  const extension = fileNameParts.pop();
  const name = fileNameParts.join('.');
  return (
    <View>
      <View style={[Style.row, Style.alignCenter]}>
        <LRText
          style={styles.text}
          numberOfLines={1}
          color="extraDarkGray"
          typeface="body1"
        >
          {name}
        </LRText>
        <LRText color="extraDarkGray" typeface="body1">
          {`${name && '.'}${extension}`}
        </LRText>
        <Spacer size={0.5} />
        <IconPaperClip />
      </View>
      <Spacer size={0.5} />
      <LRText color="darkGray" typeface="captionM">
        {createdAt}
      </LRText>
    </View>
  );
}

const styles = StyleSheet.create({
  text: {
    maxWidth: 200,
  },
});
