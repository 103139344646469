import { ActivityIndicator, Image, StyleSheet, View } from 'react-native';

import { Api } from '../../../api';
import { LRText, Spacer } from '../../../components';
import { Color, Style } from '../../../constants';
import { Model } from '../../../models';

type Props = {
  media: Api.Album.V_Detail['albumMedias'][0] | Api.AlbumMedia.V_Detail;
};

export default function MediaView({ media }: Props) {
  let videoEncoding = null;
  if (Model.Upload.isVideo(media.upload)) {
    videoEncoding =
      media.upload.videoEncodings?.[media.upload.videoEncodings.length - 1];
  }
  return (
    <View style={styles.fixedHeight}>
      {Model.Upload.isVideo(media.upload) && media.upload.videoUrlTemplate ? (
        <>
          {videoEncoding ? (
            <video
              controls
              src={media.upload.videoUrlTemplate.replace(
                '__ENCODING__',
                videoEncoding.toString()
              )}
              style={styles.fixedHeight}
            />
          ) : (
            <View style={[Style.centerBoth, styles.fixedHeight]}>
              <ActivityIndicator color={Color.accent} size="large" />
              <Spacer />
              <LRText textAlign="center" color="gray">
                Your video is being processed, check back later
              </LRText>
            </View>
          )}
        </>
      ) : (
        <Image
          source={media.upload.pathOrganiserCover as any}
          resizeMode="contain"
          style={styles.fixedHeight}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  fixedHeight: {
    height: 320,
  },
});
