const envs = {
  prod: {
    APP_ENV: 'prod',
    SERVER_ROOT: 'https://api.legacyroom.com',
    VISITOR_ROOT: 'https://my.legacyroom.com',
  },
  staging: {
    APP_ENV: 'staging',
    SERVER_ROOT: 'https://api.lroomstaging.com',
    VISITOR_ROOT: 'https://my.lroomstaging.com',
  },
  dev: {
    APP_ENV: 'dev',
    SERVER_ROOT: 'http://localhost:8000',
    VISITOR_ROOT: 'https://localhost:3000',
  },
};

const env: keyof typeof envs =
  (process.env.EXPO_PUBLIC_APP_ENV as any) ?? 'staging';

const config = {
  APP_ENV: envs[env].APP_ENV,
  SERVER_ROOT: envs[env].SERVER_ROOT,
  VISITOR_ROOT: envs[env].VISITOR_ROOT,
};

export default config;
