import { NavigationProp } from '@react-navigation/native';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { useForm } from 'react-typed-form';

import { Api, useApiFormSubmit } from '../../../api';
import { LRText, PaddedArea, Spacer } from '../../../components';
import { Dimen, Style } from '../../../constants';
import { useLayoutContext } from '../../../contexts';
import { FieldAvatar, SubmitButton } from '../../../form';
import { useUserOverview } from '../../../hooks';
import { MainStackParamList } from '../../../navigation';
import { capitalize } from '../../../util';

type Props = {
  navigation: NavigationProp<MainStackParamList>;
};

type FormShape = Pick<Api.User.W_Update, 'avatar'>;

export default function ProfilePhotoUploadScreen({ navigation }: Props) {
  const { managingUser } = useUserOverview();
  const { isDesktopLayout } = useLayoutContext();

  const onSubmit = useApiFormSubmit<FormShape>(
    'PUT',
    `/users/${managingUser.id}`,
    { onSuccess: () => navigation.navigate('Home.Home') }
  );
  const form = useForm<FormShape>({
    defaultValues: {
      avatar: managingUser.avatar?.['@id'] ?? null,
    },
    onSubmit,
  });

  return (
    <PaddedArea v h style={Style.fill}>
      {isDesktopLayout && <Spacer fill />}
      <LRText
        typeface="h1"
        color={isDesktopLayout ? 'accent' : 'whiteHigh'}
        textAlign="center"
      >
        Upload your photo
      </LRText>
      <Spacer />
      <View style={styles.card}>
        <PaddedArea v={1.5} h={3}>
          <LRText
            typeface="h2"
            color="accent400"
            textAlign="center"
          >{`${capitalize(managingUser.firstName)} ${capitalize(
            managingUser.lastName
          )}`}</LRText>
          <Spacer />
          <FieldAvatar
            field={form.getField('avatar')}
            existingImageUrl={managingUser.avatar?.pathAvatar}
          />
        </PaddedArea>
      </View>
      <Spacer fill />
      <SubmitButton {...form} title="Continue" />
    </PaddedArea>
  );
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: 'white',
    overflow: 'hidden',
    borderRadius: Dimen.cornerRadiusLarge,
    alignItems: 'center',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.08,
    shadowRadius: 14,
  },
});
