import * as Linking from 'expo-linking';
import * as Sharing from 'expo-sharing';
import * as React from 'react';
import { View } from 'react-native';

import {
  CopyableField,
  Button as LRButton,
  LRText,
  PaddedArea,
  Spacer,
} from '../../../components';
import { Style } from '../../../constants';
import { useUserOverview } from '../../../hooks';

export default function AddFriendScreen() {
  const [isSharingAvailable, setIsSharingAvailable] = React.useState(false);
  const { managingUser } = useUserOverview();

  const shareLink = Linking.createURL(
    `/invites/${managingUser.friendsInviteCode}`
  );

  React.useEffect(() => {
    Sharing.isAvailableAsync().then((value) => {
      setIsSharingAvailable(value);
    });
  }, []);

  return (
    <PaddedArea v h style={Style.fill}>
      <Spacer size={5} />
      <View style={[Style.alignCenter, Style.fill]}>
        <LRText textAlign="center" typeface="h1" color="accent400">
          Your link is ready!
        </LRText>
        <Spacer />
        <LRText textAlign="center" typeface="body1">
          Send this link to anyone you want to, and invite them to add photos,
          video and text to your Legacy Room
        </LRText>
        <Spacer />

        <View style={[Style.fullWidth, Style.fill]}>
          <CopyableField text={shareLink} />
          <Spacer fill />
          {isSharingAvailable && (
            <LRButton
              title={'Share'}
              onPress={() => {
                Sharing.shareAsync(shareLink);
              }}
            />
          )}
        </View>
      </View>
    </PaddedArea>
  );
}
