import * as React from 'react';
import { View } from 'react-native';

import { Dimen, Style } from '../constants';

type Props = {
  size?: number;
  fill?: boolean;
};

export default function Spacer({ size = 1, fill }: Props) {
  const space = (size || 1) * Dimen.spacing;
  return <View style={[{ width: space, height: space }, fill && Style.fill]} />;
}
