import * as React from 'react';
import { View } from 'react-native';
import { useForm } from 'react-typed-form';

import { Api, useApiFormSubmit } from '../../api';
import {
  Button,
  CardPage,
  ErrorList,
  LRText,
  LinkButton,
  Spacer,
} from '../../components';
import { Style } from '../../constants';
import { FieldTextRequired } from '../../form';

type FormShape = Api.ResetPasswordRequest.W;

export default function ForgotPasswordScreen() {
  const [passwordLinkSent, setPasswordLinkSent] = React.useState(false);

  const onSubmit = useApiFormSubmit<FormShape>(
    'POST',
    '/reset-password/request',
    {
      onSuccess: () => {
        setPasswordLinkSent(true);
      },
    }
  );

  const form = useForm<FormShape>({
    defaultValues: {
      email: '',
    },
    onSubmit,
  });

  return (
    <View>
      <Spacer size={2} />
      {passwordLinkSent ? (
        <>
          <View style={Style.alignCenter}>
            <View style={{ maxWidth: 200 }}>
              <LRText typeface="h1" textAlign="center" color="whiteHigh">
                Check your inbox
              </LRText>
            </View>
          </View>
          <Spacer />
          <CardPage>
            <LRText typeface="body1">
              We've sent an email to the address provided. Can't find it? Check
              your spam folder...
            </LRText>
            <Spacer />
            <LinkButton
              disabled={form.isLoading}
              title="Back to login"
              buttonType="hollowAccent"
              to={{ screen: 'Auth.Login' }}
            />
          </CardPage>
        </>
      ) : (
        <>
          <View style={Style.alignCenter}>
            <View
              style={{
                maxWidth: 200,
              }}
            >
              <LRText typeface="h1" textAlign="center" color="whiteHigh">
                Reset your password
              </LRText>
            </View>
          </View>
          <Spacer />
          <CardPage>
            <LRText typeface="body1" color="darkGray">
              We'll send you an email with a link to create a new password
            </LRText>
            <Spacer />
            <FieldTextRequired
              autoCapitalize="none"
              field={form.getField('email')}
            />
            <ErrorList errorList={form.formErrorList} />
            <Spacer />
            <Button
              disabled={form.isLoading}
              title="Send reset link"
              onPress={form.handleSubmit}
            />
            <Spacer size={0.6} />
            <LinkButton
              disabled={form.isLoading}
              title="Back to login"
              buttonType="hollowAccent"
              to={{ screen: 'Auth.Login' }}
            />
          </CardPage>
        </>
      )}
    </View>
  );
}
