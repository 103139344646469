import { Color } from '../../constants';

type Props = {
  color: string;
  size: number;
};

export default function IconDelete({ color = Color.gray5, size }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_194_18748"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_194_18748)">
        <path
          d="M5.9987 9.33203H12.6654L10.3654 6.33203L8.83203 8.33203L7.7987 6.9987L5.9987 9.33203ZM5.33203 11.9987C4.96536 11.9987 4.65159 11.8683 4.3907 11.6074C4.12936 11.346 3.9987 11.032 3.9987 10.6654V2.66536C3.9987 2.2987 4.12936 1.9847 4.3907 1.72336C4.65159 1.46248 4.96536 1.33203 5.33203 1.33203H13.332C13.6987 1.33203 14.0127 1.46248 14.274 1.72336C14.5349 1.9847 14.6654 2.2987 14.6654 2.66536V10.6654C14.6654 11.032 14.5349 11.346 14.274 11.6074C14.0127 11.8683 13.6987 11.9987 13.332 11.9987H5.33203ZM5.33203 10.6654H13.332V2.66536H5.33203V10.6654ZM2.66536 14.6654C2.2987 14.6654 1.98492 14.5349 1.72403 14.274C1.4627 14.0127 1.33203 13.6987 1.33203 13.332V3.9987H2.66536V13.332H11.9987V14.6654H2.66536Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
