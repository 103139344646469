import { NavigationProp, RouteProp } from '@react-navigation/native';
import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import { Api, useApiCallable } from '../../api';
import {
  ConfirmationModal,
  EmptyListScreen,
  IconLinkButton,
  LRText,
  PaddedArea,
  ScreenError,
  ScreenLoading,
  ScreenOnboarding,
  Seperator,
  Spacer,
} from '../../components';
import { IconPlus } from '../../components/icons';
import { Color, Dimen, Style } from '../../constants';
import { useLayoutContext } from '../../contexts';
import { useUserOverview } from '../../hooks';
import { MainStackParamList } from '../../navigation';
import { useTypedSelector } from '../../reducers';
import { iriToId } from '../../util';
import { FriendAlbumListItem, SuggestedMediaModal } from './components';

type Props = {
  route: RouteProp<MainStackParamList, 'FriendsWall.FriendsWall'>;
  navigation: NavigationProp<MainStackParamList>;
};

export default function FriendsWallScreen({ navigation }: Props) {
  const { isDesktopLayout } = useLayoutContext();
  const { managingUser } = useUserOverview();
  const callApi = useApiCallable();
  const { completedScreens } = useTypedSelector((state) => state.onboarding);
  const [removedFriend, setRemovedFriend] =
    React.useState<Api.Friendship.V_UserSub | null>(null);
  const [deletedAlbum, setDeletedAlbum] =
    React.useState<Api.Friendship.V_UserSub | null>(null);
  const shouldShowOnboarding = !completedScreens.includes('friends_wall');

  const ownedAlbumsApi = Api.Friendship.useReadCollectionFriendships(
    managingUser.id
  );
  const friendsAlbumsApi = Api.Friendship.useReadCollectionFriendshipsmirrored(
    managingUser.id
  );
  const suggestedMediaApi = Api.SuggestedMedia.useReadCollectionSuggestedMedias(
    managingUser.id
  );
  if (
    ownedAlbumsApi.error ||
    friendsAlbumsApi.error ||
    suggestedMediaApi.error
  ) {
    return (
      <ScreenError
        error={
          ownedAlbumsApi.error ??
          friendsAlbumsApi.error ??
          suggestedMediaApi.error
        }
      />
    );
  }
  if (
    !ownedAlbumsApi.data ||
    !friendsAlbumsApi.data ||
    !suggestedMediaApi.data
  ) {
    return <ScreenLoading />;
  }

  const friends = ownedAlbumsApi.data['hydra:member'];
  const friendsShared = friendsAlbumsApi.data['hydra:member'];
  const suggestedMedia = suggestedMediaApi.data['hydra:member'];

  const friendshipsWithAlbums = friends.filter(
    (friend) => friend.album !== null
  );
  const friendshipsWithoutAlbums = friends.filter(
    (friend) =>
      friend.album === null && iriToId(friend.user['@id']) === managingUser.id
  );
  const friendsAlbums = friendsShared.filter(
    (friend) =>
      friend.album !== null && iriToId(friend.friend['@id']) === managingUser.id
  );

  async function onRemoveConfirm() {
    const response = await callApi(
      `/friendships/${iriToId(removedFriend!['@id'])}`,
      {
        method: 'DELETE',
      }
    );
    if (response) {
      setRemovedFriend(null);
      ownedAlbumsApi.invalidate();
      friendsAlbumsApi.invalidate();
    }
  }

  async function onDeleteConfirm() {
    const response = await callApi(`/albums/${iriToId(deletedAlbum!['@id'])}`, {
      method: 'DELETE',
    });
    if (response) {
      setDeletedAlbum(null);
      ownedAlbumsApi.invalidate();
    }
  }

  return (
    <>
      <PaddedArea v h style={Style.fill}>
        {friends.length > 0 ? (
          <View>
            <View style={[Style.row, Style.alignCenter]}>
              <LRText typeface="h1" color="extraDarkGray">
                Friends Wall
              </LRText>
              <Spacer fill />
              <IconLinkButton
                to={{
                  screen: 'Account.AddFriend',
                }}
              >
                <IconPlus color={Color.whiteHigh} />
              </IconLinkButton>
            </View>
            <Spacer size={0.5} />
            <LRText color="extraDarkGray" typeface="body2">
              Albums you create here will appear on your Friends Wall in your
              room. Your friend can suggest photos, but they will only be
              included with your approval.
            </LRText>
            <Spacer />
            {friendshipsWithoutAlbums.length > 0 && (
              <>
                {friendshipsWithoutAlbums.map((friendship, index) => (
                  <View key={friendship.id}>
                    <FriendAlbumListItem
                      friendship={friendship}
                      onCreateAlbum={() => {
                        navigation.navigate('Albums.CreateAlbumFriendship', {
                          friendshipId: friendship.id,
                          friendName: friendship.friend.firstName,
                        });
                      }}
                    />
                    {(index < friendshipsWithoutAlbums.length - 1 ||
                      friendshipsWithAlbums) && (
                      <>
                        <Spacer />
                        {!isDesktopLayout && (
                          <Seperator color={Color.lightGrey} />
                        )}
                      </>
                    )}
                  </View>
                ))}
                <Spacer />
              </>
            )}
            <View
              style={[
                isDesktopLayout && styles.desktopAlbumsContainer,
                styles.albumsContainer,
              ]}
            >
              {friendshipsWithAlbums.map((friendship, index) => (
                <View key={friendship.album!['@id']}>
                  <FriendAlbumListItem
                    friendship={friendship}
                    onRemoveFriend={() => {
                      setRemovedFriend(friendship);
                    }}
                    onEditCoverPhoto={(mediaId) =>
                      navigation.navigate('Albums.EditMedia', {
                        mediaId,
                      })
                    }
                    onPress={() => {
                      navigation.navigate('Albums.ViewAlbum', {
                        albumId: friendship.album!.id,
                      });
                    }}
                    onDelete={() => {
                      setDeletedAlbum(friendship);
                    }}
                  />
                  {index < friendshipsWithAlbums.length - 1 && (
                    <>
                      {isDesktopLayout ? (
                        <Spacer size={0.5} />
                      ) : (
                        <>
                          <Spacer />
                          <Seperator color={Color.lightGrey} />
                        </>
                      )}
                    </>
                  )}
                </View>
              ))}
            </View>
            <Spacer size={3} />
            {friendsAlbums.length > 0 && (
              <>
                <LRText color="extraDarkGray" typeface="h1">
                  Your friends' albums
                </LRText>
                <Spacer size={0.5} />
                <LRText color="extraDarkGray" typeface="body2">
                  Add photos to your friend's album and, if your friend approves
                  them, they'll appear on their Friends Wall in their room.
                </LRText>
                <Spacer />
                <View
                  style={[
                    isDesktopLayout && styles.desktopAlbumsContainer,
                    styles.albumsContainer,
                  ]}
                >
                  {friendsAlbums.map((friendship, index) => (
                    <View key={friendship.album!['@id']}>
                      <FriendAlbumListItem
                        friendship={friendship}
                        onPress={() => {
                          navigation.navigate('Albums.ViewAlbum', {
                            albumId: friendship.album!.id,
                            ownerName: friendship.user.firstName,
                          });
                        }}
                      />
                      {index < friendsAlbums.length - 1 && !isDesktopLayout && (
                        <>
                          <Spacer />
                          <Seperator color={Color.lightGrey} />
                        </>
                      )}
                    </View>
                  ))}
                </View>
              </>
            )}
          </View>
        ) : (
          <>
            {shouldShowOnboarding ? (
              <ScreenOnboarding screen="friends_wall" />
            ) : (
              <EmptyListScreen
                roomTriggerType="friends_wall"
                onAdd={() => navigation.navigate('Account.AddFriend')}
              />
            )}
          </>
        )}
      </PaddedArea>
      {removedFriend && (
        <ConfirmationModal
          visible
          title={`Are you sure you want to remove ${
            managingUser.id === iriToId(removedFriend.friend['@id'])
              ? removedFriend!.user.firstName
              : removedFriend!.friend.firstName
          } from your friends list?`}
          confirmationText="Remove friend"
          onCancel={() => setRemovedFriend(null)}
          onConfirm={onRemoveConfirm}
        />
      )}
      {deletedAlbum && (
        <ConfirmationModal
          visible
          title={`Are you sure you want to delete ${deletedAlbum.album?.title}?`}
          message={'Your shared album will be deleted permanently.'}
          confirmationText="Delete album"
          onCancel={() => setDeletedAlbum(null)}
          onConfirm={onDeleteConfirm}
        />
      )}
      <SuggestedMediaModal
        suggestedMedia={suggestedMedia}
        onFinish={() => {
          ownedAlbumsApi.invalidate();
          suggestedMediaApi.invalidate();
        }}
      />
    </>
  );
}

const styles = StyleSheet.create({
  albumsContainer: {
    gap: Dimen.spacing,
  },
  desktopAlbumsContainer: {
    flexDirection: 'row',
    overflow: 'hidden',
    flexWrap: 'wrap',
  },
});
