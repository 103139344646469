import type { ReadConfig } from 'api-read-hook';
import { useApiRead } from 'api-read-hook';

import type { HydraCollection } from '../../types';
import * as Upload from './Upload';

export type V = {
  '@id': string;
  '@type': 'TrusteeListItem';
  id: string;
  fullName: string;
  isInvite: boolean;
  avatar: Upload.BaseView | null;
};

export function useReadCollectionTrusteeListItems(
  id: string,
  options?: ReadConfig
) {
  return useApiRead<HydraCollection<V>>(
    `/users/${id}/trustee-list-items`,
    options
  );
}
