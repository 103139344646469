import { NavigationProp, RouteProp } from '@react-navigation/native';
import * as React from 'react';
import { View } from 'react-native';
import { useForm } from 'react-typed-form';

import { Api, useApiFormSubmit } from '../../../api';
import { LRText, LinkButton, PaddedArea, Spacer } from '../../../components';
import { Style } from '../../../constants';
import { useLayoutContext } from '../../../contexts';
import { FieldTextRequired, SubmitButton } from '../../../form';
import { useUserOverview } from '../../../hooks';
import { MainStackParamList } from '../../../navigation';

type Props = {
  route: RouteProp<MainStackParamList, 'Account.TwoFactorRequestCode'>;
  navigation: NavigationProp<MainStackParamList>;
};

export default function TwoFactorRequestCodeScreen({ navigation }: Props) {
  const { isDesktopLayout } = useLayoutContext();
  const { myUser } = useUserOverview();
  const form = useForm<{ phoneNumber: string }>({
    defaultValues: {
      phoneNumber: myUser.phoneNumber ?? '',
    },
    onSubmit: useApiFormSubmit<Api.TwoFactorRequest.W>('POST', '/2fa/request', {
      onSuccess: () => {
        navigation.navigate('Account.TwoFactorConfirmCode');
      },
    }),
  });

  let pn = myUser.phoneNumber ?? '';
  let phoneNumberEnd = pn.substring(pn.length - 3);
  
  return (
    <PaddedArea style={Style.fill} v h>
      <View style={Style.fill}>
        <LRText color="extraDarkGray" typeface="h1">
          Two Step verification
        </LRText>
        <Spacer size={1.5} />
        { myUser.phoneverified ? 
        
       <LRText typeface="body2" color="darkGray">
        We will send a verification code to your number ending in {phoneNumberEnd}
      </LRText>
        :
        <>
        <LRText color="extraDarkGray" typeface="h3">
          Add a phone number
        </LRText>
        <Spacer />
        <LRText typeface="body2" color="darkGray">
          We will send a verification code to this number. You’ll need it for the
          next step
        </LRText>
        <Spacer />
        <FieldTextRequired
          field={form.getField('phoneNumber')}
          label={null}
          keyboardType="numeric"
        />
        </>
        } 
        <Spacer size={2} fill={!isDesktopLayout} />
        <View style={Style.row}>
          <View style={{ flex: 2 }}>
            <LinkButton
              title="Cancel"
              buttonType="tertiary"
              to={{ screen: 'Home.Home' }}
            />
          </View>
          <Spacer size={0.5} />
          <View style={{ flex: 3 }}>
            <SubmitButton {...form} title="Send Code" />
          </View>
        </View>
      </View>
    </PaddedArea>
  );
}
