import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { format, parseISO } from 'date-fns';
import React from 'react';
import toast from 'react-hot-toast';
import { Image, Pressable, StyleSheet, View } from 'react-native';

// @ts-expect-error
import BookshelfIcon from '../../../assets/img/pods/home/illustrations/bookshelf_icon.svg';
// @ts-expect-error
import DesktopVideoIcon from '../../../assets/img/pods/home/illustrations/desktop_video_icon.svg';
// @ts-expect-error
import DigitalVaultIcon from '../../../assets/img/pods/home/illustrations/digital_vault_icon.svg';
// @ts-expect-error
import PictureFrameIcon from '../../../assets/img/pods/home/illustrations/frame_icon.svg';
// @ts-expect-error
import WallPictureIcon from '../../../assets/img/pods/home/illustrations/wall-picture_icon.svg';
import { useApiCallable } from '../../api';
import {
  Button,
  ConfirmationModal,
  LRText,
  PaddedArea,
  Seperator,
  Spacer,
} from '../../components';
import { IconAvatar } from '../../components/icons';
import { Color, Config, Dimen, Style } from '../../constants';
import { useLRDataContext, useLayoutContext } from '../../contexts';
import { useManaging, useUserOverview } from '../../hooks';
import { MainStackParamList } from '../../navigation';
import { FriendsWallButton, RoomFeatureButton } from './components';
import TrusteesButton from './components/TrusteesButton';

type Props = NativeStackScreenProps<MainStackParamList, 'Home.Home'>;

export default function HomeScreen({}: Props) {
  const { actingAsTrustee } = useManaging();
  const { managingUser, myUser } = useUserOverview();
  const { apiUserOverview } = useLRDataContext();
  const { isDesktopLayout } = useLayoutContext();
  const callApi = useApiCallable();
  const [
    shouldShowNotifyOfPassingConfirmationModal,
    setShouldShowNotifyOfPassingConfirmationModal,
  ] = React.useState(false);

  React.useEffect(() => {
    apiUserOverview.invalidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleNotifyOfPassing() {
    const response = await callApi(`/users/${managingUser.id}/notify-death`, {
      method: 'POST',
      jsonBody: {},
    });
    if (response) {
      toast.success('Notification sent');
      setShouldShowNotifyOfPassingConfirmationModal(false);
      apiUserOverview.invalidate();
    }
  }

  return (
    <>
      <PaddedArea h v>
        {!actingAsTrustee ? (
          <View style={styles.avatarContainer}>
            <LRText
              typeface="h1"
              color={isDesktopLayout ? 'accent' : 'whiteHigh'}
            >
              Welcome {myUser.firstName}
            </LRText>
            {myUser.avatar && (
              <Image
                source={myUser.avatar.pathAvatar as any}
                style={styles.avatar}
              />
            )}
          </View>
        ) : (
          <View style={styles.avatarContainer}>
            <View style={[Style.fill, Style.alignStart]}>
              <LRText
                typeface="h1"
                color={isDesktopLayout ? 'accent' : 'whiteHigh'}
              >
                {managingUser.firstName}'s Room
              </LRText>
              <LRText
                typeface="body2"
                color={isDesktopLayout ? 'accent' : 'whiteHigh'}
              >
                You are now managing {managingUser.firstName}'s room
              </LRText>
              <Spacer size={0.5} />
              {managingUser.deathNotifiedAt ? (
                <LRText
                  typeface="captionM"
                  color={isDesktopLayout ? 'accent' : 'whiteHigh'}
                  style={{ overflow: 'hidden', flexWrap: 'wrap' }}
                >
                  {`We were notified of ${managingUser.firstName} ${
                    managingUser.lastName
                  }'s passing on ${format(
                    parseISO(managingUser.deathNotifiedAt),
                    'dd/MM/yyyy'
                  )}. Main account access has been disabled, and we are communicating with Trustees to discuss further arrangements.`}
                </LRText>
              ) : (
                <Button
                  buttonSize="small"
                  buttonType={isDesktopLayout ? 'hollowAccent' : 'hollowWhite'}
                  title="Notify of Passing"
                  onPress={() =>
                    setShouldShowNotifyOfPassingConfirmationModal(true)
                  }
                />
              )}
            </View>
            {managingUser.avatar ? (
              <Image
                source={managingUser.avatar.pathAvatar as any}
                style={styles.avatar}
              />
            ) : (
              <IconAvatar size={40} />
            )}
          </View>
        )}
        {!actingAsTrustee && (
          <>
            <Spacer />
            <TrusteesButton />
          </>
        )}
        <Spacer size={2} />
        <LRText typeface="h1" color="accent">
          Room features
        </LRText>
        <Spacer />
        <FriendsWallButton />
        <Seperator color={Color.lightGrey} />
        <RoomFeatureButton
          title="Welcome Note"
          roomTriggerType="welcome_note"
          image={DesktopVideoIcon}
          disabled={!managingUser.featureEntitlements.includes('welcome_note')}
        />
        <Seperator color={Color.lightGrey} />
        <RoomFeatureButton
          title="Wall Pictures"
          roomTriggerType="wall_picture"
          image={WallPictureIcon}
          disabled={!managingUser.featureEntitlements.includes('wall_pictures')}
        />
        <Seperator color={Color.lightGrey} />
        <RoomFeatureButton
          title="Photo Frames"
          roomTriggerType="photo_frame"
          image={PictureFrameIcon}
          disabled={!managingUser.featureEntitlements.includes('photo_frames')}
        />
        <Seperator color={Color.lightGrey} />
        <RoomFeatureButton
          title="Bookshelf"
          roomTriggerType="book"
          disabled={!managingUser.featureEntitlements.includes('bookshelf')}
          image={BookshelfIcon}
        />
        <Seperator color={Color.lightGrey} />
        <RoomFeatureButton
          title="Digital Vault"
          to={{ screen: 'Vault.RootList' }}
          disabled={!managingUser.featureEntitlements.includes('vault')}
          image={DigitalVaultIcon}
        />
        {!isDesktopLayout && (
          <Pressable
            style={styles.button}
            {...{
              ['href' as any]: `${Config.VISITOR_ROOT}/${managingUser.roomSlug}`,
            }}
          >
            <PaddedArea v h>
              <LRText color="whiteHigh" typeface="button">
                Enter Room
              </LRText>
            </PaddedArea>
          </Pressable>
        )}
      </PaddedArea>
      <ConfirmationModal
        visible={shouldShowNotifyOfPassingConfirmationModal}
        title={`Are you sure you want to notify us of ${managingUser.firstName}'s passing?`}
        message="Once done, access to their account will be disabled and we will begin the process of contacting trustees to make further arrangements"
        onConfirm={handleNotifyOfPassing}
        onCancel={() => setShouldShowNotifyOfPassingConfirmationModal(false)}
      />
    </>
  );
}

const styles = StyleSheet.create({
  avatarContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    marginRight: Dimen.spacing * 0.5,
  },
  avatar: {
    width: 45,
    height: 45,
    borderRadius: 23,
    backgroundColor: Color.gray2,
  },
  card: {
    borderRadius: Dimen.cornerRadiusLarge,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.08,
    shadowRadius: 14,
  },
  button: {
    backgroundColor: Color.accent400,
    borderRadius: Dimen.cornerRadiusLarge,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    transitionDuration: '200ms',
  },
});
