import * as React from 'react';
import {
  Image,
  ImageSourcePropType,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';

import { Style } from '../constants';
import LRText from './LRText';
import Spacer from './Spacer';

type Props = {
  image: ImageSourcePropType;
  title?: string;
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
};

export default function ImageButton({
  image,
  title,
  style,
  onPress,
  disabled = false,
}: Props) {
  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
      style={[styles.container, disabled && Style.disabled, style]}
    >
      <Image
        style={{ height: 24, width: 24 }}
        resizeMode="contain"
        source={image}
      />
      <Spacer size={0.25} />
      {title && <LRText typeface="button">{title}</LRText>}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
