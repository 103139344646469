import { LinearGradient } from 'expo-linear-gradient';
import {
  ImageBackground,
  StyleSheet,
  View,
  useWindowDimensions,
} from 'react-native';

import { Api } from '../../../api';
import {
  ActionsDropdown,
  LRText,
  LinkButton,
  PaddedArea,
  Spacer,
} from '../../../components';
import { IconDelete, IconPencil } from '../../../components/icons';
import { Color, Dimen } from '../../../constants';
import { useLayoutContext } from '../../../contexts';
import { To } from '../../../types';

type Props = {
  album: NonNullable<Api.RoomTrigger.V_UserSub['album']>;
  label: string;
  onDeletePress: () => void;
  to: To;
};

export default function AlbumListItem({
  album,
  label,
  onDeletePress,
  to,
}: Props) {
  const { width: windowWidth } = useWindowDimensions();

  const containerWidth =
    Math.min(windowWidth, Dimen.contentMaxWidth) - Dimen.spacing * 2;
  const { isDesktopLayout } = useLayoutContext();
  const itemWidth = isDesktopLayout
    ? Math.floor((containerWidth - Dimen.spacing * 2) / 2)
    : undefined;

  return (
    <View
      style={[styles.container, { minWidth: itemWidth, maxWidth: itemWidth }]}
    >
      <LinearGradient
        colors={['#00000000', '#000000CC']}
        style={styles.linearGradient}
      >
        <PaddedArea style={styles.contentContainer} v={1.5} h={1.2}>
          <View style={styles.actionsAnchor}>
            <ActionsDropdown
              triggerColor="white"
              actions={[
                {
                  label: 'Edit album',
                  icon: IconPencil,
                  to: {
                    screen: 'Albums.EditAlbum',
                    params: { albumId: album.id },
                  },
                },
                {
                  label: 'Delete album',
                  icon: IconDelete,
                  onPress: () => onDeletePress(),
                },
              ]}
            />
          </View>
          <Spacer fill />
          <LRText color="whiteHigh" typeface="h1" textAlign="center">
            {album?.title}
          </LRText>
          <Spacer size={0.5} />
          <LRText typeface="body1" color="whiteHigh" textAlign="center">
            {label}
          </LRText>
          <Spacer size={1.5} />
          <LinkButton
            buttonSize="small"
            title="Explore"
            buttonType="hollowWhite"
            to={to}
          />
        </PaddedArea>
      </LinearGradient>
      <ImageBackground
        resizeMode="cover"
        style={styles.backgroundImage}
        source={album?.safeCover?.upload.pathOrganiserCover as any}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    aspectRatio: '354/300',
    backgroundColor: Color.gray,
    borderRadius: 32,
    overflow: 'hidden',
    minWidth: 230,
  },
  linearGradient: {
    height: '100%',
    width: '100%',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: -1,
  },
  actionsAnchor: {
    alignSelf: 'flex-end',
  },
});
