import * as React from 'react';
import { Modal, StyleSheet, View } from 'react-native';

import { Color, Dimen } from '../constants';
import PaddedArea from './PaddedArea';

type Props = {
  children: React.ReactNode;
  onDismiss: () => void;
  visible: boolean;
};

export default function LRModal({ children, onDismiss, visible }: Props) {
  return (
    <Modal visible={visible} transparent>
      <View style={styles.mask} {...{ onClick: onDismiss }}>
        <View {...{ onClick: (ev: any) => ev.stopPropagation() }}>
          <PaddedArea h={1.5} v={1.5} style={styles.modal}>
            {children}
          </PaddedArea>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  mask: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    backgroundColor: '#0007',
    paddingHorizontal: Dimen.spacing,
    alignItems: 'center',
  },
  modal: {
    backgroundColor: Color.whiteHigh,
    borderRadius: Dimen.cornerRadiusExtraLarge,
    width: '100%',
    maxWidth: 360,
  },
});
