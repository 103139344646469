import type { ReadConfig } from 'api-read-hook';
import { useApiRead } from 'api-read-hook';

import type { HydraCollection } from '../../types';
import { makeQueryString } from '../../util';
import * as Upload from './Upload';

export type V_Detail = {
  '@id': string;
  '@type': 'RoomTrigger';
  id: string;
  album: {
    '@id': string;
    '@type': 'Album';
    title: string;
    albumMedias: Array<string>;
  } | null;
  createdAt: string;
};

export type V_UserSub = {
  '@id': string;
  '@type': 'RoomTrigger';
  id: string;
  type: string;
  slot: number | null;
  album: {
    '@id': string;
    '@type': 'Album';
    id: string;
    title: string;
    mediasCount: number;
    safeCover: {
      '@id': string;
      '@type': 'AlbumMedia';
      upload: Upload.BaseView;
    } | null;
  } | null;
  createdAt: string;
};

export function useReadCollectionUserSub(
  userId: string,
  queryParams?: { type?: string; 'type[]'?: Array<string> },
  options?: ReadConfig
) {
  return useApiRead<HydraCollection<V_UserSub>>(
    `/users/${userId}/room-triggers${makeQueryString(queryParams)}`,
    options
  );
}

export function useReadItem(id: string, options?: ReadConfig) {
  return useApiRead<V_Detail>(`/room-triggers/${id}`, options);
}
