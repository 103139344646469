import config from '../config';

export default {
  ...config,
  ACCEPTED_MEDIA_TYPES: {
    IMAGE_TYPE: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
    },
    VIDEO_TYPE: {
      'video/mp4': ['.mp4', '.MP4'],
      'video/mov': ['.mov'],
      'video/wmv': ['.wmv'],
      'video/avi': ['.avi'],
    },
    FILE_TYPE: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
    },
  },
};
