import * as React from 'react';
import { StyleProp, TextInputProps, View, ViewStyle } from 'react-native';
import type { FieldProp } from 'react-typed-form';

import { ErrorList, LRTextInput } from '../components';

type Props = {
  field: FieldProp<string | null>;
  label?: string | null;
  textInputStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
} & TextInputProps;

export default function FieldText({
  field,
  label,
  textInputStyle,
  containerStyle,
  disabled,
  ...otherProps
}: Props) {
  const isInputDisabled = field.isLoading || disabled;

  return (
    <View style={containerStyle}>
      <LRTextInput
        textInputStyle={textInputStyle}
        label={label === null ? undefined : label ?? field.label}
        disabled={isInputDisabled}
        onChangeText={(text) => {
          if (text.trim() === '') {
            field.handleValueChange(null);
            return;
          }
          field.handleValueChange(text);
        }}
        value={field.value || ''}
        {...otherProps}
      />
      <ErrorList errorList={field.lastErrorList} />
    </View>
  );
}
