import {
  DefaultNavigatorOptions,
  ParamListBase,
  StackNavigationState,
  StackRouter,
  StackRouterOptions,
  createNavigatorFactory,
  useNavigationBuilder,
} from '@react-navigation/native';
import {
  NativeStackNavigationEventMap,
  NativeStackNavigationOptions,
} from '@react-navigation/native-stack';
import { NativeStackNavigationConfig } from '@react-navigation/native-stack/lib/typescript/src/types';
import * as React from 'react';
import { View } from 'react-native';

import { Style } from '../constants';
import Layout from './Layout';

type Props = DefaultNavigatorOptions<
  ParamListBase,
  StackNavigationState<ParamListBase>,
  NativeStackNavigationOptions,
  NativeStackNavigationEventMap
> &
  StackRouterOptions &
  NativeStackNavigationConfig;

function WebNavigator({ initialRouteName, children, screenOptions }: Props) {
  const { state, descriptors, navigation, NavigationContent } =
    useNavigationBuilder(StackRouter, {
      initialRouteName,
      children,
      screenOptions,
    });

  const currentRoute = state.routes[state.index];

  return (
    <NavigationContent>
      <View style={Style.fill}>
        <Layout
          currentRoute={currentRoute.name}
          // @ts-ignore
          navigation={navigation}
        >
          {descriptors[currentRoute.key].render()}
        </Layout>
      </View>
    </NavigationContent>
  );
}

export const createWebNavigator = createNavigatorFactory<
  StackNavigationState<ParamListBase>,
  NativeStackNavigationOptions,
  NativeStackNavigationEventMap,
  typeof WebNavigator
>(WebNavigator);
