import * as React from 'react';

import { useTypedDispatch } from '../reducers';
import { ApiError } from './lr-api-request';
import useApiCallable from './use-api-callable';

export function useAttemptLogin() {
  const callApi = useApiCallable();
  const dispatch = useTypedDispatch();

  const attemptLogin = React.useCallback(
    async (
      values: {
        email: string;
        password: string;
      },
      addSubmitError: (field: '_form', error: string) => void
    ) => {
      const response = await callApi<
        { sessionId: string; user: { id: string; email: string } },
        { email: string; password: string }
      >(
        '/login',
        {
          method: 'POST',
          jsonBody: values,
        },
        {
          addSubmitError,
          errorHandler: (err, originalHandler) => {
            if (err instanceof ApiError && err.response.status === 401) {
              addSubmitError('_form', 'Your email or password was incorrect');
            } else {
              originalHandler(err);
            }
          },
        }
      );
      if (response) {
        const sessionId = response.data.sessionId;
        const { id, email } = response.data.user;
        dispatch({
          type: 'LOGIN',
          payload: { user: { id, email, sessionId } },
        });
      }
      return !!response;
    },
    [callApi, dispatch]
  );

  return attemptLogin;
}
