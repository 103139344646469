import { Color } from '../../constants';

type Props = {
  color?: string;
  size?: number;
};

export default function IconTick({
  color = Color.accent400,
  size = 24,
}: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.55156 17.9996L3.85156 12.2996L5.27656 10.8746L9.55156 15.1496L18.7266 5.97461L20.1516 7.39961L9.55156 17.9996Z"
        fill={color}
      />
    </svg>
  );
}
