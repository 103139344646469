import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { Image, StyleSheet, View } from 'react-native';
import { FieldProp } from 'react-typed-form';

import { Api, useApiCallable } from '../api';
import { LRText, PaddedArea, Spacer } from '../components';
import { IconAvatar } from '../components/icons';
import { Color, Config, Dimen } from '../constants';
import { useManaging } from '../hooks';

type Props = {
  field: FieldProp<string | typeof BUSY | null>;
  existingImageUrl: string | null | undefined;
};

const BUSY = '_busy';

export default function FieldAvatar({ field, existingImageUrl }: Props) {
  const callApi = useApiCallable();
  const { managingId } = useManaging();

  const [uploadingFile, setUploadingFile] = React.useState<File | null>(null);

  const imagePreview = React.useMemo(() => {
    if (uploadingFile) {
      return URL.createObjectURL(uploadingFile);
    } else if (field.value && existingImageUrl) {
      return existingImageUrl;
    }
    return null;
  }, [existingImageUrl, field.value, uploadingFile]);

  React.useEffect(() => {
    // Revoke data uris to avoid memory leaks on unmount
    return () => {
      imagePreview && URL.revokeObjectURL(imagePreview);
    };
  }, [imagePreview]);

  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    field.handleValueChange(BUSY);
    setUploadingFile(file);
    const response = await callApi<Api.Upload.BaseView, unknown>(
      `/uploads?type=avatar&userId=${managingId}`,
      {
        method: 'POST',
        uploadFile: file,
      }
    );

    if (response) {
      field.handleValueChange(response.data['@id']);
    } else {
      field.handleValueChange(null);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      ...Config.ACCEPTED_MEDIA_TYPES.IMAGE_TYPE,
    },
    multiple: false,
  });

  return (
    <View>
      {imagePreview ? (
        <Image style={styles.photo} source={imagePreview as any} />
      ) : (
        <View style={styles.photo}>
          <IconAvatar />
        </View>
      )}
      <Spacer size={1.25} />
      <div {...getRootProps()} style={styles.submitButton}>
        <PaddedArea h={0.5} v={0.5}>
          <input {...getInputProps()} />
          <LRText typeface="button" color="accent" textAlign="center">
            Upload photo
          </LRText>
        </PaddedArea>
      </div>
    </View>
  );
}

const styles = StyleSheet.create({
  photo: {
    height: 120,
    width: 120,
    borderRadius: 77.5,
    alignSelf: 'center',
  },
  photoPlaceholder: {
    backgroundColor: Color.gray5,
  },
  submitButton: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    borderRadius: Dimen.cornerRadiusSmall,
    textAlign: 'center',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: Color.accent,
  },
});
