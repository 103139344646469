import { useLRDataContext } from '../contexts';

/**
 * Only use this when you're on a screen that can ONLY be seen by logged in users.
 */
export default function useUserOverview() {
  const { userOverview } = useLRDataContext();

  if (!userOverview) {
      return false;
  }

  return userOverview;
}
