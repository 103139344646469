import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';

import { Api } from '../../../api';
import {
  LRText,
  PaddedArea,
  ScreenError,
  ScreenLoading,
  Spacer,
} from '../../../components';
import { useDynamicPageTitle, useManaging } from '../../../hooks';
import { MainStackParamList } from '../../../navigation';
import { CategoryButton } from '../components';

type Props = NativeStackScreenProps<
  MainStackParamList,
  'Vault.CategoryExplore'
>;

export default function CategoryExploreScreen({ route }: Props) {
  const { categoryId } = route.params;

  const api =
    Api.VaultCategoryExplore.useReadItemVaultCategoriesCategoryIdexplore(
      useManaging().managingId,
      categoryId
    );

  useDynamicPageTitle(
    api.data ? `Digital Vault Category: ${api.data.name}` : null
  );

  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ScreenLoading />;

  const category = api.data;

  return (
    <PaddedArea h v>
      <LRText typeface="h1" color="accent">
        {category.name}
      </LRText>
      <Spacer />
      {category.childCategories.map((childCategory) => {
        return (
          <>
            <CategoryButton
              category={childCategory}
              parentCategoryId={categoryId}
            />
            <Spacer size={0.5} />
          </>
        );
      })}
    </PaddedArea>
  );
}
