import * as React from 'react';

import { useTypedDispatch, useTypedSelector } from '../reducers';
import {
  alertNetworkError,
  alertUnhandledError,
  isNetworkError,
  logError,
} from '../util';
import lrApiRequest, {
  ApiError,
  ApiRequestOptions,
  ApiResponse,
} from './lr-api-request';

/**
 * Wrap `lrApiRequest` with app-specific customisations:
 *
 * - If user is logged in, add their JWT to the request
 * - Default error handling logic
 *
 * The returned function (callApi) returns the API response on success, or
 * undefined on failure.  Pass in an `errorHandler` option to override default
 * error handling behaviour.
 */
export default function useApiCallable() {
  const user = useTypedSelector((state) => state.auth.user);
  const dispatch = useTypedDispatch();

  const sessionId = user?.sessionId;
  return React.useCallback(
    async function callApi<R, W>(
      path: string,
      requestOptions?: ApiRequestOptions<W>,
      moreOptions?: {
        errorHandler?: (
          error: ApiError | Error,
          originalHandler: (err: ApiError | Error) => void
        ) => void;
        addSubmitError?: (field: any, error: string) => void;
      }
    ): Promise<ApiResponse<R> | undefined> {
      function handleError(err: ApiError | Error) {
        const errData = (err as any).response?.data || {};

        if (err instanceof ApiError && err.response?.status === 401) {
          dispatch({ type: 'LOGOUT' });
        } else if (errData['@type'] === 'ConstraintViolationList') {
          if (!moreOptions?.addSubmitError) {
            throw new Error(
              'handleError: Got a ConstraintViolationList but no addSubmitError option'
            );
          }
          errData.violations.forEach((violation: any) => {
            if (!moreOptions?.addSubmitError) {
              throw new Error(
                'handleError: Got a ConstraintViolationList but no addSubmitError option'
              );
            }
            moreOptions?.addSubmitError(
              violation.propertyPath || '_form',
              violation.message
            );
          });
        } else if (isNetworkError(err)) {
          alertNetworkError();
        } else {
          const eventId = logError(err, 'callApi.handleError');
          alertUnhandledError(eventId);
        }
      }

      try {
        const response = await lrApiRequest<R, W>(path, {
          sessionId,
          ...requestOptions,
        });
        return response;
      } catch (err: any) {
        moreOptions?.errorHandler
          ? moreOptions.errorHandler(err, handleError)
          : handleError(err);
        return undefined;
      }
    },
    [dispatch, sessionId]
  );
}
