import * as React from 'react';
import { View } from 'react-native';

import LRText from './LRText';
import Spacer from './Spacer';

type Props = {
  errorList?: string[];
};

export default function ErrorList({ errorList }: Props) {
  if (!errorList || errorList.length === 0) {
    return null;
  }

  return (
    <>
      {errorList.map((errorMessage) => (
        <View key={errorMessage}>
          <Spacer size={0.5} />
          <LRText typeface="body2" color="red900">
            {errorMessage}
          </LRText>
        </View>
      ))}
    </>
  );
}
