import * as React from 'react';
import { FormObject } from 'react-typed-form';

import { ApiError } from './lr-api-request';
import useApiCallable from './use-api-callable';

export default function useApiFormSubmit<W extends {}, R = {}>(
  method: 'PUT' | 'POST',
  path: string | ((values: W) => string),
  extras?: {
    errorHandlerCreator?: (
      form: FormObject<W>
    ) => (
      error: ApiError | Error,
      originalHandler: (err: ApiError | Error) => void
    ) => void;
    onSuccess?: (form: FormObject<W>, data: R, finalValues: W) => void;
  }
) {
  const callApi = useApiCallable();

  return React.useCallback(
    async function onSubmit(values: W, form: FormObject<W>): Promise<boolean> {
      const { setLoading, addSubmitError } = form;
      setLoading(true);

      // Trim whitespace from any string values in the form.  We're assuming
      // any leading/trailing spaces on typical forms (those submitted with
      // useApiFormSubmit) are always entered by mistake.
      const transformedValues = Object.fromEntries(
        Object.entries(values).map(([k, v]) => [
          k,
          typeof v === 'string' ? v.trim() : v,
        ])
      );

      const response = await callApi<R, W>(
        typeof path === 'string' ? path : path(values),
        {
          method,
          jsonBody: transformedValues as any,
        },
        { addSubmitError, errorHandler: extras?.errorHandlerCreator?.(form) }
      );
      setLoading(false);
      if (response) {
        extras?.onSuccess?.(form, response.data, transformedValues as any);
        return true;
      } else {
        return false;
      }
    },
    [callApi, method, path, extras]
  );
}
