import * as React from 'react';
import { useWindowDimensions } from 'react-native';

import { Dimen } from '../constants';

const LayoutContext = React.createContext<{
  isMobileLayout: boolean;
  isDesktopLayout: boolean;
}>({
  isMobileLayout: false,
  isDesktopLayout: true,
});

type Props = {
  children: React.ReactNode;
};

export function LayoutContextProvider({ children }: Props) {
  const { width } = useWindowDimensions();
  const isMobile = width < Dimen.contentMaxWidth + Dimen.sidebarTotalWidth;

  return (
    <LayoutContext.Provider
      value={{ isMobileLayout: isMobile, isDesktopLayout: !isMobile }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

export function useLayoutContext() {
  return React.useContext(LayoutContext);
}
