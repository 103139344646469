import type { ReadConfig } from 'api-read-hook';
import { useApiRead } from 'api-read-hook';

import * as Upload from './Upload';

export type V_Detail = {
  '@id': string;
  '@type': 'Album';
  id: string;
  title: string;
  story: string | null;
  friendship: {
    '@id': string;
    '@type': 'Friendship';
    user: {
      '@id': string;
      '@type': 'User';
      firstName: string;
      lastName: string;
      avatar: Upload.BaseView | null;
    };
    friend: {
      '@id': string;
      '@type': 'User';
      firstName: string;
      lastName: string;
      avatar: Upload.BaseView | null;
    };
  } | null;
  roomTrigger: {
    '@id': string;
    '@type': 'RoomTrigger';
    type: string;
    slot: number | null;
  } | null;
  albumMedias: Array<{
    '@id': string;
    '@type': 'AlbumMedia';
    id: string;
    description: string | null;
    upload: Upload.BaseView;
    isCover: boolean;
    position: number;
  }>;
  safeCover: {
    '@id': string;
    '@type': 'AlbumMedia';
    id: string;
    description: string | null;
    upload: Upload.BaseView;
    isCover: boolean;
    position: number;
  } | null;
};

export type W_Create = {
  user: string;
  title: string;
  story: string | null;
  friendship: string | null;
  roomTrigger: {
    user: string;
    type: string;
    slot: number | null;
  } | null;
  albumMedias: Array<{
    description: string | null;
    upload: {
      rotation: number;
    };
    position: number;
  }>;
};

export type W_Update = {
  title: string;
  story: string | null;
  albumMedias: Array<{
    description: string | null;
    upload: {
      rotation: number;
    };
    isCover: boolean;
    position: number;
  }>;
};

export function useReadItem(id: string, options?: ReadConfig) {
  return useApiRead<V_Detail>(`/albums/${id}`, options);
}
