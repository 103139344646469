export default function IconMenu() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_496_1999"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
      >
        <rect width="40" height="40" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_496_1999)">
        <path
          d="M6.08594 28.4154V27.4154H33.9193V28.4154H6.08594ZM6.08594 20.4987V19.4987H33.9193V20.4987H6.08594ZM6.08594 12.582V11.582H33.9193V12.582H6.08594Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
}
