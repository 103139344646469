import { useNavigation } from '@react-navigation/native';
import { StyleSheet, View } from 'react-native';
import { useForm } from 'react-typed-form';

import { Api, useApiFormSubmit } from '../../../api';
import { Button, LinkButton, PaddedArea, Spacer } from '../../../components';
import { Color, Style } from '../../../constants';
import { FieldText, SubmitButton } from '../../../form';
import { Model } from '../../../models';
import MediaView from './MediaView';

type Props = {
  media: Api.AlbumMedia.V_Detail;
};

type FormType = Omit<Api.AlbumMedia.W_Update, 'upload'> & { rotation: number };

export default function EditMediaLoaded({ media }: Props) {
  const navigation = useNavigation();

  const onSubmit = useApiFormSubmit<FormType>(
    'PUT',
    `/album-media/${media.id}`,
    {
      onSuccess: () => {
        navigation.navigate('Albums.ViewMedia', {
          albumId: media.album.id,
          position: media.position,
        });
      },
    }
  );
  const form = useForm<FormType>({
    defaultValues: {
      ...media,
      rotation: media.upload.rotation,
    },
    postValidateTransform: (values) => {
      return {
        ...values,
        upload: {
          id: media.upload.id,
          rotation: (values.rotation + 360 * 100) % 360,
        },
      };
    },
    onSubmit,
  });

  const currentRotation = form.getField('rotation').value ?? 0;
  function updateRotation(diff: number) {
    form.getField('rotation').handleValueChange(currentRotation + diff);
  }

  return (
    <PaddedArea v h>
      {!Model.Upload.isVideo(media.upload) && (
        <View style={[Style.row]}>
          <View style={Style.fill}>
            <Button
              title="Rotate left"
              onPress={() => updateRotation(-90)}
              buttonType="tertiary"
            />
          </View>
          <Spacer size={0.5} />
          <View style={Style.fill}>
            <Button
              title="Rotate right"
              onPress={() => updateRotation(90)}
              buttonType="tertiary"
            />
          </View>
        </View>
      )}
      <Spacer />
      <View style={Style.alignCenter}>
        <View style={[styles.viewerFrame]}>
          <View
            style={[
              styles.viewerFrameInner,
              {
                transform: [
                  { rotate: `${currentRotation - media.upload.rotation}deg` },
                ],
              },
            ]}
          >
            <MediaView media={media} />
          </View>
        </View>
      </View>
      <Spacer />
      <FieldText field={form.getField('description')} multiline />
      <Spacer />
      <View style={Style.row}>
        <View style={{ flex: 2 }}>
          <LinkButton
            title="Cancel"
            buttonType="tertiary"
            to={{
              screen: 'Albums.ViewMedia',
              params: { albumId: media.album.id, position: media.position },
            }}
          />
        </View>
        <Spacer size={0.5} />
        <View style={{ flex: 3 }}>
          <SubmitButton {...form} disabled={form.isLoading} title="Save" />
        </View>
      </View>
    </PaddedArea>
  );
}

const styles = StyleSheet.create({
  viewerFrame: {
    width: 320,
    backgroundColor: Color.lightGrey,
    overflow: 'hidden',
  },
  viewerFrameInner: {
    ['transitionDuration' as any]: '200ms',
  },
});
