import { Color } from '../../constants';

type Props = {
  color?: string;
};

export default function IconCheck({ color = Color.black }: Props) {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.13183 4.2627H2.13201C2.21131 4.26279 2.28956 4.28085 2.36088 4.31552C2.43216 4.35016 2.49466 4.40049 2.54372 4.46274L2.13183 4.2627ZM2.13183 4.2627H1.31269C1.10906 4.2627 0.995352 4.49729 1.12107 4.65674L1.12112 4.6568L4.33089 8.72321L4.33093 8.72327C4.54122 8.98941 4.94443 8.98868 5.15542 8.72371L5.15583 8.7232L10.8784 1.47152C10.8786 1.47131 10.8788 1.47111 10.8789 1.4709C11.0071 1.31047 10.8886 1.07754 10.6877 1.07754H9.86855C9.70816 1.07754 9.55543 1.15104 9.45635 1.27798C9.45626 1.27809 9.45617 1.2782 9.45609 1.27831L4.74278 7.2491M2.13183 4.2627L4.74278 7.2491M4.74278 7.2491L2.54381 4.46285L4.74278 7.2491Z"
        fill={color}
        stroke={color}
        strokeWidth="0.3"
      />
    </svg>
  );
}
