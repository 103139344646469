type Props = {
  dir: 'left' | 'right';
  color: string;
};

export default function IconArrow({ dir, color }: Props) {
  return (
    <svg
      width="18"
      height="32"
      viewBox="0 0 18 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${dir === 'left' ? 0 : 180}deg)` }}
    >
      <path
        d="M15.5128 31.5127L0 15.9999L15.5128 0.487061L17.1037 2.08431L3.16667 15.9999L17.1037 29.9155L15.5128 31.5127Z"
        fill={color}
      />
    </svg>
  );
}
