import * as React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { Color } from '../constants';

type Props = {
  style?: StyleProp<ViewStyle>;
  color?: string;
};

export default function Seperator({ color = Color.black, style }: Props) {
  return <View style={[styles.container, { backgroundColor: color }, style]} />;
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 1,
    zIndex: -10,
  },
});
