import * as React from 'react';
import { toast } from 'react-hot-toast';
import { Image, StyleSheet, View } from 'react-native';

import { Api, useApiCallable } from '../../../api';
import { Button, LRModal, LRText, Spacer } from '../../../components';
import { Style } from '../../../constants';

type Props = {
  suggestedMedia: Api.SuggestedMedia.V[];
  onFinish?: () => void;
};

export default function SuggestedMediaModal({
  suggestedMedia,
  onFinish,
}: Props) {
  const [index, setIndex] = React.useState(0);
  const callApi = useApiCallable();

  const isLast = React.useMemo(() => {
    if (index + 1 < suggestedMedia.length) {
      return false;
    }
    return true;
  }, [index, suggestedMedia]);

  const currentMedia = React.useMemo(() => {
    if (suggestedMedia.length >= index + 1) {
      return suggestedMedia[index];
    }
    return undefined;
  }, [index, suggestedMedia]);

  return (
    <LRModal onDismiss={() => {}} visible={suggestedMedia.length > 0}>
      <LRText typeface="h3" textAlign="center">
        {`${currentMedia?.album.friendship?.friend.firstName} would like to post photos in the ${currentMedia?.album.title} album.`}
      </LRText>
      <Spacer />
      <LRText textAlign="center">
        Do you approve publishing this photo to your shared album on Friends
        Wall?
      </LRText>
      <Spacer />
      <Image
        source={currentMedia?.upload.pathOrganiserThumb as any}
        style={styles.image}
      />
      <Spacer />
      <LRText typeface="captionM" textAlign="center">{`${index + 1}/${
        suggestedMedia.length
      }`}</LRText>
      <Spacer />
      <View style={Style.row}>
        <View style={styles.rejectButtonContainer}>
          <Button
            title="Reject"
            buttonType="tertiary"
            onPress={async () => {
              const response = await callApi(
                `/suggested-medias/${currentMedia?.id}/reject`,
                {
                  method: 'DELETE',
                  jsonBody: {},
                }
              );
              if (response) {
                toast.success('Photo rejected');
                setIndex(index + 1);
                if (isLast) {
                  onFinish?.();
                }
              }
            }}
          />
        </View>
        <Spacer size={0.5} />
        <View style={styles.approveButtonContainer}>
          <Button
            title="Approve"
            onPress={async () => {
              const response = await callApi(
                `/suggested-medias/${currentMedia?.id}/approve`,
                {
                  method: 'POST',
                  jsonBody: {},
                }
              );
              if (response) {
                toast.success('Photo approved');
                setIndex(index + 1);
                if (isLast) {
                  onFinish?.();
                }
              }
            }}
          />
        </View>
      </View>
    </LRModal>
  );
}

const styles = StyleSheet.create({
  image: {
    height: 175,
    width: 175,
    alignSelf: 'center',
  },
  rejectButtonContainer: {
    flex: 2,
  },
  approveButtonContainer: {
    flex: 3,
  },
});
