import * as React from 'react';

const DropdownMutexContext = React.createContext<{
  currentId: string | null;
  registerId: (id: string) => void;
  unregisterId: (id: string) => void;
  generateId: () => string;
}>(undefined as any);

type Props = {
  children: React.ReactNode;
};

export function DropdownMutexContextProvider({ children }: Props) {
  const [currentId, setCurrentId] = React.useState<string | null>(null);

  React.useEffect(() => {
    const clearCurrentId = () => setCurrentId(null);
    currentId && window.addEventListener('click', clearCurrentId);
    return () => {
      currentId && window.removeEventListener('click', clearCurrentId);
    };
  }, [currentId]);

  const registerId = React.useCallback((id: string) => setCurrentId(id), []);
  const unregisterId = React.useCallback(
    (id: string) => id === currentId && setCurrentId(null),
    [currentId]
  );

  const generateId = React.useCallback(() => Math.random().toString(), []);

  return (
    <DropdownMutexContext.Provider
      value={{ currentId, registerId, unregisterId, generateId }}
    >
      {children}
    </DropdownMutexContext.Provider>
  );
}

/**
 * Mutually exclusive helper which can be used in a dropdown component to:
 *
 * - Ensure that only 1 dropdown ever shows at the same time.
 * - Close itself when clicked outside.
 */
export function useDropdownMutex() {
  const context = React.useContext(DropdownMutexContext);
  const [mutexId] = React.useState(context.generateId());

  const isOpened = context.currentId === mutexId;
  const show = () => context.registerId(mutexId);
  const hide = () => context.unregisterId(mutexId);

  return {
    isOpened,
    show,
    hide,
    toggle: () => (isOpened ? hide() : show()),
  };
}
