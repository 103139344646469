import type { Action } from '../actions';

export type UserState = Readonly<{
  email: string;
  id: string;
  sessionId: string;
}>;

type AuthState = Readonly<{
  user: UserState | null;
  managingUserId: string | null;
  referralCode: string | null;
}>;

const initialState = {
  user: null,
  managingUserId: null,
  referralCode: null,
};

export default function auth(
  state: AuthState = initialState,
  action: Action
): AuthState {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        managingUserId: null,
        user: {
          email: action.payload.user.email,
          id: action.payload.user.id,
          sessionId: action.payload.user.sessionId,
        },
      };
    case 'FRIEND_REFERRAL':
      return { ...state, referralCode: action.payload.referralCode };
    case 'REJECT_FRIEND_REFERRAL':
      return { ...state, referralCode: null };
    case 'SET_MANAGING_USER_ID':
      return { ...state, managingUserId: action.payload };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}
