import { Color } from '../../constants';

type Props = {
  color?: string;
};

export default function IconRadioFilled({ color = Color.accent400 }: Props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="7" fill="white" stroke={color} />
      <circle cx="8" cy="8" r="4" fill={color} />
    </svg>
  );
}
