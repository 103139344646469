import { Pressable, StyleSheet, View } from 'react-native';

import { Color, Style } from '../constants';
import { IconHelp } from './icons';
import LRText from './LRText';
import Spacer from './Spacer';

type Props = {
  title: string;
  onHelpPress?: () => void;
  rightButton?: React.ReactNode;
};

export default function ScreenHeading({
  title,
  onHelpPress,
  rightButton,
}: Props) {
  return (
    <View style={[Style.row, Style.alignCenter]}>
      <LRText typeface="h1" color="accent">
        {title}
      </LRText>
      {onHelpPress && (
        <>
          <Spacer size={0.25} />
          <Pressable
            onPress={onHelpPress}
            style={({ hovered }: any) => [
              styles.helpButton,
              hovered && styles.helpButtonHovered,
            ]}
          >
            {({ hovered }: any) => (
              <IconHelp color={hovered ? Color.accent700 : Color.darkGray} />
            )}
          </Pressable>
        </>
      )}
      {rightButton && (
        <>
          <Spacer fill />
          {rightButton}
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  helpButton: {
    width: 32,
    height: 32,
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
    transitionDuration: '0.25s',
  },
  helpButtonHovered: {
    backgroundColor: Color.accent100,
  },
});
