import { RouteProp } from '@react-navigation/native';

import { Api } from '../../api';
import { ScreenError, ScreenLoading } from '../../components';
import { MainStackParamList } from '../../navigation';
import { EditMediaLoaded } from './components';

type Props = {
  route: RouteProp<MainStackParamList, 'Albums.EditMedia'>;
};

export default function EditMediaScreen({ route }: Props) {
  const { mediaId } = route.params;

  const api = Api.AlbumMedia.useReadItem(mediaId);
  if (api.error) return <ScreenError {...api} />;
  if (!api.data) return <ScreenLoading />;

  return <EditMediaLoaded media={api.data} />;
}
