export default function IconPlay() {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="19" height="18" rx="9" fill="white" />
      <g>
        <path
          d="M13.7955 9.50048L9 6.30323V12.6977L13.7955 9.50048ZM16.032 9.81248L8.08275 15.112C8.02628 15.1496 7.96067 15.1711 7.89292 15.1744C7.82516 15.1776 7.7578 15.1624 7.698 15.1304C7.6382 15.0984 7.5882 15.0507 7.55334 14.9925C7.51847 14.9344 7.50004 14.8678 7.5 14.8V4.20098C7.50004 4.13315 7.51847 4.06659 7.55334 4.00841C7.5882 3.95023 7.6382 3.90259 7.698 3.87057C7.7578 3.83856 7.82516 3.82336 7.89292 3.8266C7.96067 3.82984 8.02628 3.8514 8.08275 3.88898L16.032 9.18848C16.0834 9.22272 16.1255 9.26912 16.1546 9.32355C16.1837 9.37797 16.199 9.43875 16.199 9.50048C16.199 9.56221 16.1837 9.62298 16.1546 9.67741C16.1255 9.73184 16.0834 9.77823 16.032 9.81248Z"
          fill="#09121F"
        />
      </g>
      <defs>
        <clipPath id="clip0_539_7829">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(1.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
