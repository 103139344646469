import * as React from 'react';

import { To } from '../types';
import Button from './Button';
import PressableLink from './PressableLink';

type Props = Omit<React.ComponentProps<typeof Button>, 'onPress'> & {
  to: To;
  onPress?: () => void;
};

export default function LinkButton({ to, onPress, ...rest }: Props) {
  return (
    <PressableLink to={to}>
      <Button
        {...rest}
        onPress={() => {
          onPress?.();
        }}
      />
    </PressableLink>
  );
}
