import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Color, Dimen } from '../../constants';
import { useLayoutContext } from '../../contexts';
import Button from '../Button';
import IconButton from '../IconButton';
import IconLinkButton from '../IconLinkButton';
import { IconCaret, IconMenu } from '../icons';
import Logotype from '../Logotype';
import PaddedArea from '../PaddedArea';
import Spacer from '../Spacer';
import AnimatedDrawer from './AnimatedDrawer';

export default function NavHeader() {
  const { isDesktopLayout } = useLayoutContext();
  const [menuVisible, setMenuVisible] = React.useState(false);

  const toggleDrawer = React.useCallback(() => {
    setMenuVisible(!menuVisible);
  }, [menuVisible]);

  return (
    <>
      <View style={styles.container}>
        <PaddedArea h style={styles.inner}>
          {!isDesktopLayout && (
            <>
              <IconButton
                style={{
                  backgroundColor: 'transparent',
                  transform: [{ rotate: '180deg' }],
                }}
                onPress={() => window.history.back()}
              >
                <IconCaret />
              </IconButton>
              <Spacer fill />
            </>
          )}

          <IconLinkButton
            style={{ backgroundColor: 'transparent' }}
            to={{ screen: 'Home.Home' }}
          >
            <Logotype size={isDesktopLayout ? 35 : 27} color={Color.accent} />
          </IconLinkButton>
          <Spacer fill />
          {!isDesktopLayout && (
            <Button
              buttonType="text"
              buttonSize="tiny"
              icon={<IconMenu />}
              onPress={() => toggleDrawer()}
            />
          )}
        </PaddedArea>
      </View>
      <View style={styles.fixedSpace} />
      <AnimatedDrawer
        visible={menuVisible}
        onDismiss={() => setMenuVisible(false)}
      />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'fixed' as any,
    top: 0,
    left: 0,
    right: 0,
    height: Dimen.headerHeight,
    borderBottomWidth: 1,
    borderBottomColor: Color.gray5,
    alignItems: 'center',
    backgroundColor: Color.whiteHigh,
    zIndex: 20,
  },
  inner: {
    width: '100%',
    maxWidth: Dimen.contentMaxWidth + Dimen.sidebarTotalWidth,
    height: Dimen.headerHeight,
    flexDirection: 'row',
    alignItems: 'center',
  },
  fixedSpace: {
    height: Dimen.headerHeight,
  },
});
