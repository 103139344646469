import * as React from 'react';

import { useTypedDispatch } from '../reducers';
import { ApiError, ApiRequestOptions } from './lr-api-request';
import useApiCallable from './use-api-callable';

/**
 * Hook providing a `reader` matching the `api-read-hook` spec
 */
export default function useReader() {
  const callApi = useApiCallable();
  const dispatch = useTypedDispatch();

  return React.useCallback(
    async function reader<R>(
      path: string,
      options: Omit<ApiRequestOptions<R>, 'url'> = {}
    ): Promise<R> {
      const response = await callApi<R, any>(path, options, {
        errorHandler: (err) => {
          if (err instanceof ApiError && err.response?.status === 401) {
            dispatch({ type: 'LOGOUT' });
          } else {
            throw err;
          }
        },
      });
      if (!response) {
        throw new Error('Logic error: useReader should always have a response');
      }
      return response.data;
    },
    [callApi, dispatch]
  );
}
