type Props = {
  color: string;
  size?: number;
};

export default function IconPlus({ color, size = 21 }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16675 9.66669V4.66669H10.8334V9.66669H15.8334V11.3334H10.8334V16.3334H9.16675V11.3334H4.16675V9.66669H9.16675Z"
        fill={color}
      />
    </svg>
  );
}
