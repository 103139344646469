import * as React from 'react';
import { Image, StyleSheet, View } from 'react-native';

import { RoomTriggerType } from '../../types';
import { Button, LRText, Spacer } from '../components';
import { Dimen, Style } from '../constants';
import { useLayoutContext } from '../contexts';

type Props = {
  roomTriggerType: RoomTriggerType;
  onAdd: () => void;
};

const WallPictureEmptyListPlaceholdrImage = require('../../assets/img/pods/albums/empty-wall-picture.jpg');
const BookshelfEmptyListPlaceholdrImage = require('../../assets/img/pods/albums/empty-bookshelf.png');
const PictureFrameEmptyListPlaceholdrImage = require('../../assets/img/pods/albums/empty-wall-picture.jpg');
const DesktopVideoEmptyListPlaceholdrImage = require('../../assets/img/pods/albums/empty-desktop-video.png');
const FriendsWallEmptyListPlaceholderImage = require('../../assets/img/pods/friends/empty-friends-wall.png');
const TrusteeEmptyListPlaceholderImage = require('../../assets/img/pods/friends/onboarding-friends-wall.png');

const getImageFromRoomTriggerType = (roomTriggerType: RoomTriggerType) => {
  switch (roomTriggerType) {
    case 'book':
      return BookshelfEmptyListPlaceholdrImage;
    case 'photo_frame':
      return PictureFrameEmptyListPlaceholdrImage;
    case 'friends_wall':
      return FriendsWallEmptyListPlaceholderImage;
    case 'trustee':
      return TrusteeEmptyListPlaceholderImage;
    case 'welcome_note':
      return DesktopVideoEmptyListPlaceholdrImage;
    case 'wall_picture':
    default:
      return WallPictureEmptyListPlaceholdrImage;
  }
};

const getPlaceholderTextFromRoomTriggerType = (roomTriggerType: string) => {
  switch (roomTriggerType) {
    case 'book':
      return 'Your books will be displayed on the bookshelf. You have the option to add a maximum of three books.';
    case 'friends_wall':
      return 'Invite friends to contribute to your Friends Wall and they can add their memories of your shared experiences.';
    case 'trustee':
      return 'Add trustee and start to share your mutual memories together';
    case 'welcome_note':
      return 'Make a great first impression with a personalised welcome desktop video that will greet visitors when they enter your room.';
    case 'photo_frame':
      return 'Frame your special people or moments, as you would in a room at home.';
    case 'wall_picture':
    default:
      return 'Showcase your favorite photos on the wall of your Legacy Room and change them whenever you like.';
  }
};

const getButtonText = (roomTriggerType: string) => {
  switch (roomTriggerType) {
    case 'book':
      return 'Book';
    case 'friends_wall':
      return 'Friend';
    case 'photo_frame':
      return 'Photo Frame';
    case 'trustee':
      return 'Trustee';
    case 'welcome_note':
      return 'Welcome Note';
    case 'wall_picture':
    default:
      return 'Wall Picture';
  }
};

export default function AlbumsListScreen({ roomTriggerType, onAdd }: Props) {
  const { isDesktopLayout } = useLayoutContext();

  return (
    <View style={[Style.alignCenter, Style.fill]}>
      <Spacer size={5} />
      <Image
        style={[styles.image, isDesktopLayout && styles.imageDesktop]}
        source={getImageFromRoomTriggerType(roomTriggerType)}
      />
      <Spacer />
      <LRText textAlign="center" typeface="subheader">
        {getButtonText(roomTriggerType)}
      </LRText>
      <Spacer />
      <LRText textAlign="center" typeface="body2">
        {getPlaceholderTextFromRoomTriggerType(roomTriggerType)}
      </LRText>
      <Spacer size={2} fill={!isDesktopLayout} />
      <View style={!isDesktopLayout && { width: '100%' }}>
        <Button
          title={`Add  ${getButtonText(roomTriggerType)}`}
          onPress={onAdd}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  image: {
    width: '100%',
    height: 224,
    borderRadius: Dimen.cornerRadiusLarge * 2,
    resizeMode: 'contain',
  },
  imageDesktop: {
    width: 340,
  },
  albumsContainer: {
    gap: Dimen.spacing,
  },
  desktopAlbumsContainer: {
    flexDirection: 'row',
    overflow: 'hidden',
    flexWrap: 'wrap',
  },
});
