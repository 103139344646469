import { NavigationProp, RouteProp } from '@react-navigation/native';
import { View } from 'react-native';
import { useForm } from 'react-typed-form';

import { Api, useApiFormSubmit } from '../../api';
import {
  Button,
  LRText,
  LinkButton,
  PaddedArea,
  Spacer,
} from '../../components';
import { Style } from '../../constants';
import { useLRDataContext } from '../../contexts';
import {
  FieldMediaOrganiser,
  FieldText,
  FieldTextRequired,
  SubmitButton,
} from '../../form';
import { useDynamicPageTitle, useUserOverview } from '../../hooks';
import { Model } from '../../models';
import { MainStackParamList } from '../../navigation';

type Props = {
  navigation: NavigationProp<MainStackParamList>;
  route: RouteProp<MainStackParamList, 'Albums.CreateAlbumRoomTrigger'>;
};

/**
 * Create an album based around a RoomTrigger
 */
export default function CreateAlbumRoomTriggerScreen({
  navigation,
  route,
}: Props) {
  const { roomTriggerType, roomTriggerSlot } = route.params;
  useDynamicPageTitle(
    `Create ${Model.RoomTrigger.typeToReadableName(roomTriggerType)} Album`
  );
  const { apiUserOverview } = useLRDataContext();
  const { managingUser } = useUserOverview();

  const onSubmit = useApiFormSubmit<Api.Album.W_Create, Api.Album.V_Detail>(
    'POST',
    '/albums',
    {
      onSuccess: () => {
        apiUserOverview.invalidate();
        navigation.navigate('Albums.AlbumsList', {
          roomTriggerType: roomTriggerType,
        });
      },
    }
  );

  const form = useForm<Api.Album.W_Create>({
    defaultValues: {
      user: managingUser['@id'],
      title: '',
      story: '',
      albumMedias: [],
      roomTrigger: {
        user: managingUser['@id'],
        type: roomTriggerType,
        slot: roomTriggerSlot,
      },
      friendship: null,
    },
    onSubmit,
  });

  return (
    <PaddedArea v h>
      <LRText typeface="h1" color="accent">
        Create Album
      </LRText>
      <Spacer size={0.5} />
      <LRText typeface="body2" color="gray7">
        {
          'Add up to 50 photos/videos\n\nThese photos and videos will be shown in your room.'
        }
      </LRText>
      <Spacer size={0.5} />
      <FieldMediaOrganiser
        // @ts-expect-error
        field={form.getField('albumMedias')}
      />
      <Spacer size={1.5} />
      <FieldTextRequired field={form.getField('title')} />
      {roomTriggerType === 'book' && (
        <>
          <Spacer size={0.5} />
          <View style={{ flexDirection: 'row' }}>
            <Button
              buttonSize="tiny"
              buttonType="lightGrey"
              onPress={() => {
                form.getField('title').handleValueChange('Family Tree');
              }}
              title="Family Tree"
            />
            <Spacer size={0.5} />
            <Button
              buttonSize="tiny"
              buttonType="lightGrey"
              onPress={() => {
                form.getField('title').handleValueChange('Biography');
              }}
              title="Biography"
            />
          </View>
        </>
      )}
      <Spacer size={0.5} />
      <FieldText field={form.getField('story')} />
      <Spacer />
      <View style={Style.row}>
        <View style={{ flex: 2 }}>
          <LinkButton
            title="Cancel"
            buttonType="tertiary"
            to={{
              screen: 'Albums.AlbumsList',
              params: { roomTriggerType },
            }}
          />
        </View>
        <Spacer size={0.5} />
        <View style={{ flex: 3 }}>
          <SubmitButton
            {...form}
            forceDisabled={
              (form.getField('albumMedias').value?.length ?? 0) === 0
            }
            title="Publish"
          />
        </View>
      </View>
    </PaddedArea>
  );
}
