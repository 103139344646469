import { ActivityIndicator } from 'react-native';

import { Color, Style } from '../constants';
import PaddedArea from './PaddedArea';

export default function ScreenLoading() {
  return (
    <PaddedArea h={2} v={2} style={[Style.fill, Style.centerBoth]}>
      <ActivityIndicator color={Color.lightGrey} size="large" />
    </PaddedArea>
  );
}
