import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import type { FieldProp } from 'react-typed-form';

import { ErrorList, LRText, Spacer } from '../components';
import { Color, Style } from '../constants';

type Props = {
  field: FieldProp<string>;
  cellCount?: number;
};

export default function FieldText({ field, cellCount = 6 }: Props) {
  const ref = useBlurOnFulfill({ value: field.value, cellCount: cellCount });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value: field.value,
    setValue: field.handleValueChange,
  });

  return (
    <View>
      <CodeField
        ref={ref}
        {...props}
        // Use `caretHidden={false}` when users can't paste a text value, because context menu doesn't appear
        value={field.value}
        onChangeText={field.handleValueChange}
        cellCount={cellCount}
        keyboardType="number-pad"
        textContentType="oneTimeCode"
        renderCell={({ index, symbol, isFocused }) => (
          <View style={Style.row}>
            <View
              key={index}
              onLayout={getCellOnLayoutHandler(index)}
              style={[styles.cell, isFocused && styles.focusCell]}
            >
              <LRText typeface="h2">
                {symbol || (isFocused ? <Cursor /> : null)}
              </LRText>
            </View>
            <Spacer />
          </View>
        )}
      />
      <ErrorList errorList={field.lastErrorList} />
    </View>
  );
}

const styles = StyleSheet.create({
  cell: {
    width: 70,
    height: 30,
    borderBottomWidth: 1,
    borderColor: Color.gray,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  focusCell: {
    borderColor: '#000',
  },
});
