import * as React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import { Color, Dimen } from '../constants';
import { useDropdownMutex } from '../contexts';
import { To } from '../types';
import { IconEllipsisThick, IconMenu } from './icons';
import LRText from './LRText';
import PressableLink from './PressableLink';
import Spacer from './Spacer';

export type DropdownAction = {
  label: string;
  icon: (options: { color: string; size: number }) => React.ReactNode;
  onPress?: () => void;
  to?: To;
};

type Props = {
  actions: Array<DropdownAction>;
  menuType?: 'ellipsis' | 'burger';
  triggerColor?: 'accent' | 'white';
};

export default function ActionsDropdown({
  actions,
  menuType = 'ellipsis',
  triggerColor = 'accent',
}: Props) {
  const dropdownMutex = useDropdownMutex();

  return (
    <View>
      {menuType === 'ellipsis' ? (
        <Pressable
          style={({ hovered }: any) => [
            styles.ellipsisButton,
            {
              backgroundColor:
                triggerColor === 'white' ? Color.whiteHigh : Color.accent,
            },
            hovered && styles.buttonHovered,
          ]}
          onPress={dropdownMutex.toggle}
        >
          <IconEllipsisThick
            orient={triggerColor === 'white' ? 'h' : 'v'}
            color={triggerColor === 'white' ? Color.darkGray : Color.whiteHigh}
          />
        </Pressable>
      ) : (
        <Pressable
          style={({ hovered }: any) => [
            styles.menuButton,
            hovered && styles.buttonHovered,
          ]}
          onPress={dropdownMutex.toggle}
        >
          <IconMenu />
        </Pressable>
      )}
      <View
        pointerEvents={dropdownMutex.isOpened ? 'auto' : 'none'}
        style={[
          styles.dropdown,
          dropdownMutex.isOpened && styles.dropdownOpened,
        ]}
      >
        {actions.map((action) => (
          <>
            {action.to ? (
              <PressableLink
                key={action.label}
                to={action.to}
                onPress={() => {
                  action.onPress?.();
                  dropdownMutex.hide();
                }}
                style={({ hovered }: any) => [
                  styles.action,
                  hovered && styles.actionHovered,
                ]}
              >
                {action.icon({ size: 16, color: Color.extraDarkGray })}
                <Spacer size={0.5} />
                <LRText typeface="captionM">{action.label}</LRText>
              </PressableLink>
            ) : (
              <Pressable
                key={action.label}
                onPress={() => {
                  action.onPress?.();
                  dropdownMutex.hide();
                }}
                style={({ hovered }: any) => [
                  styles.action,
                  hovered && styles.actionHovered,
                ]}
              >
                {action.icon({ size: 16, color: Color.extraDarkGray })}
                <Spacer size={0.5} />
                <LRText typeface="captionM">{action.label}</LRText>
              </Pressable>
            )}
          </>
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  ellipsisButton: {
    width: 36,
    height: 36,
    borderRadius: Dimen.cornerRadiusExtraLarge,
    alignItems: 'center',
    justifyContent: 'center',
    transitionDuration: '200ms',
  },
  menuButton: {
    padding: 4,
    margin: -4,
    borderRadius: Dimen.cornerRadiusExtraLarge,
    ['transitionProperty' as any]: 'background-color',
    ['transitionDuration' as any]: '250ms',
  },
  buttonHovered: {
    opacity: 0.8,
  },
  dropdown: {
    position: 'absolute',
    top: Dimen.spacing * 2,
    right: 0,
    width: 200,
    opacity: 0,
    backgroundColor: Color.whiteHigh,
    borderRadius: Dimen.cornerRadiusLarge,
    paddingVertical: Dimen.spacing * 0.25,
    shadowColor: '#000',
    shadowOpacity: 0.25,
    shadowOffset: { width: 0, height: 4 },
    shadowRadius: 4,
    transform: [{ translateY: -20 }],
    transitionDuration: '150ms',
  },
  dropdownOpened: {
    opacity: 1,
    transform: [{ translateY: 0 }],
  },
  action: {
    height: 32,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: Dimen.spacing * 0.75,
    transitionDuration: '150ms',
  },
  actionHovered: {
    backgroundColor: Color.lightGrey,
  },
});
