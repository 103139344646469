export default {
  accent: '#5C62B7',
  accent400: '#5C62B7',
  accent700: '#3C3FA0',
  accent100: '#C4C6E6',
  accent50: '#F4F5FB',
  black: '#000000',
  red200: '#FFE4E3',
  red300: '#FCC4C2',
  red400: '#FCA49F',
  red900: '#890C06',
  green300: '#D6FFBC',
  green400: '#CAF4AF',
  green500: '#A9DA8B',
  green900: '#3F8315',
  gradientStart: '#6C72CF',
  gradientEnd: '#353B92',

  // Greys
  whiteHigh: '#FFFFFF',
  whiteMedium: 'rgba(255,255,255,0.6)',
  whiteDisabled: 'rgba(255,255,255,0.38)',
  gray9: '#434343',
  gray7: '#8C8C8C',
  gray5: '#D9D9D9',
  gray2: '#D9D9D9',
  gray: '#B3B3B3',
  lightGrey: '#E9EAF6',
  darkGray: '#777777',
  extraDarkGray: '#3E3F51',
  darkBg: '#1A1A1A',

  // Dev defined
  accentHovered: '#3c4297',
  imageLoadingBg: '#f7f7f7',
  lightGreyHovered: '#dbdcec',
};
