import { createStore } from 'redux';
import { persistStore } from 'redux-persist';

import reducers from './reducers';

export function configureStore() {
  const store = createStore(
    reducers,
    undefined,
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION__()
  );
  const persistor = persistStore(store);
  return { store, persistor };
}
