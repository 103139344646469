import React from 'react';
import { View } from 'react-native';

type Direction = 'right' | 'down' | 'left' | 'up';

type Props = {
  direction?: Direction;
};

export default function IconCaretSmall({ direction = 'right' }: Props) {
  const rotationMap: Record<Direction, number> = {
    right: 0,
    down: 90,
    left: 180,
    up: 270,
  };

  const rotate = rotationMap[direction] || 0;

  return (
    <View style={{ transform: [{ rotate: `${rotate}deg` }] }}>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2340_24410)">
          <path
            d="M12.125 10.0006L8 5.8756L9.17833 4.69727L14.4817 10.0006L9.17833 15.3039L8 14.1256L12.125 10.0006Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_2340_24410">
            <rect x="0.5" width="20" height="20" rx="10" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </View>
  );
}
