import {
  NavigationProp,
  PathConfig,
  RouteProp,
  useNavigation,
} from '@react-navigation/native';
import * as React from 'react';

import { useLRDataContext } from '../contexts';
import * as Account from '../pods/account';
import * as Albums from '../pods/albums';
import * as Auth from '../pods/auth';
import * as FriendsWall from '../pods/friends-wall';
import * as Home from '../pods/home';
import * as Trustees from '../pods/trustees';
import * as Vault from '../pods/vault';
import { useTypedSelector } from '../reducers';
import { createWebNavigator } from './WebNavigator';
import Cookies from 'universal-cookie';

export type MainStackParamList = {
  'Account.AddFriend': undefined;
  'Account.ManageRooms': undefined;
  'Account.TwoFactorConfirmCode': undefined;
  'Account.TwoFactorRequestCode': undefined;
  'Account.VerificationRequired': undefined;
  'Account.VerifyEmail': { email: string; code: string };
  'Account.UploadProfilePhoto': undefined;

  'Albums.AlbumsList': { roomTriggerType: string };
  'FriendsWall.FriendsWall': undefined;
  'Albums.CreateAlbumFriendship': {
    friendshipId: string;
    friendName: string;
  };
  'Albums.CreateAlbumRoomTrigger': {
    roomTriggerType: string;
    roomTriggerSlot: number;
  };
  'Albums.SuggestMedia': { albumId: string; ownerName: string };
  'Albums.EditAlbum': { albumId: string };
  'Albums.EditMedia': { mediaId: string };
  'Albums.ViewAlbum': { albumId: string; ownerName?: string };
  'Albums.ViewMedia': { albumId: string; position: number };

  'Vault.RootList': undefined;
  'Vault.CategoryExplore': { categoryId: string };
  'Vault.AddFile': { categoryId: string; parentCategoryId: string };
  'Vault.EditFile': { fileId: string };
  'Vault.ViewFile': { fileId: string };

  'Trustees.AddTrustee': undefined;
  'Trustees.TrusteesList': undefined;
  'Trustees.ViewInvite': { code: string };

  'Auth.Login': undefined;
  'Auth.CreateAccount': undefined;
  'Auth.ForgotPassword': undefined;
  'Auth.ResetPassword': { token: string };

  'Home.Billing': undefined;
  'Home.Home': undefined;
  'Home.PreviewRoom': undefined;
  'Home.Settings': undefined;
  'Home.Invites': { friendInviteCode?: string | undefined };
};

export type WebNavigationProp = NavigationProp<MainStackParamList>;
export type WebNavigationRouteProp<T extends keyof MainStackParamList> =
  RouteProp<MainStackParamList, T>;

export function useWebNavigation() {
  return useNavigation<WebNavigationProp>;
}

export const mainStackLinkingConfig: Record<
  keyof MainStackParamList,
  string | PathConfig<MainStackParamList>
> = {
  'Account.AddFriend': '/add-friend',
  'Account.ManageRooms': '/manage-rooms',
  'Account.TwoFactorConfirmCode': '/2fa/confirm-code',
  'Account.TwoFactorRequestCode': '/2fa/request-code',
  'Account.UploadProfilePhoto': '/upload-profile-photo',
  'Account.VerificationRequired': '/verify-email',
  'Account.VerifyEmail': '/verify-email/:email/:code',

  'Albums.AlbumsList': '/albums/list/:roomTriggerType',
  'Albums.CreateAlbumFriendship':
    '/albums/create/friendship/:friendshipId/:friendName',
  'Albums.CreateAlbumRoomTrigger': {
    path: '/albums/create/room-trigger/:roomTriggerType/:roomTriggerSlot',
    parse: { roomTriggerSlot: (value: string) => parseInt(value, 10) },
  },
  'Albums.SuggestMedia': '/albums/suggest-media/:albumId',
  'Albums.EditAlbum': '/albums/edit/:albumId',
  'Albums.EditMedia': '/albums/edit/media/:mediaId',
  'Albums.ViewAlbum': '/albums/view/:albumId',
  'Albums.ViewMedia': {
    path: '/albums/view/:albumId/:position',
    parse: { position: (value: string) => parseInt(value, 10) },
  },

  'Auth.CreateAccount': '/create-account',
  'Auth.Login': '/login',
  'Auth.ForgotPassword': '/forgot-password',
  'Auth.ResetPassword': '/reset-password/:token',

  'FriendsWall.FriendsWall': '/friends-wall',

  'Trustees.AddTrustee': '/trustees/add',
  'Trustees.TrusteesList': '/trustees',
  'Trustees.ViewInvite': '/trustee-invite/:code',

  'Home.Billing': '/billing',
  'Home.Home': '/',
  'Home.PreviewRoom': '/preview-room',
  'Home.Settings': '/settings',
  'Home.Invites': '/invites/:friendInviteCode?',

  'Vault.RootList': '/vault',
  'Vault.CategoryExplore': '/vault/category/:categoryId',
  'Vault.AddFile': '/vault/files/add',
  'Vault.EditFile': '/vault/files/:fileId/edit',
  'Vault.ViewFile': '/vault/files/:fileId',
};

const Stack = createWebNavigator<MainStackParamList>();

export default function MainStack() {
  const { userOverview } = useLRDataContext();
  const cookies = new Cookies();
  const {
    user: authUser,
    managingUserId,
    referralCode,
  } = useTypedSelector((state) => state.auth);
  let initialRouteName: keyof MainStackParamList = 'Auth.Login';
  if (userOverview) { 
    if (!userOverview.myUser.emailVerifiedAt) {
      initialRouteName = 'Account.VerificationRequired';
    } else if (referralCode) {
      initialRouteName = 'Home.Invites';
    } else if (cookies.get('lr_friend_invite')) {
      initialRouteName = 'Home.Invites';
    } /* else if (!userOverview.managingUser.avatar) {
      initialRouteName = 'Account.UploadProfilePhoto';
    } */ else if (
      !userOverview.myUser.featureEntitlements.includes('wall_pictures') &&
      !managingUserId
    ) {
      initialRouteName = 'Home.Billing';
    } else {
      initialRouteName = 'Home.Home';
    }
  }

  return (
    <Stack.Navigator initialRouteName={initialRouteName}>
      {!authUser && (
        <>
          {/* Default screen first */}
          <Stack.Screen
            component={Auth.LoginScreen}
            name="Auth.Login"
            options={{ title: 'Log In' }}
          />
          <Stack.Screen
            component={Auth.CreateAccountScreen}
            name="Auth.CreateAccount"
            options={{ title: 'Create Account' }}
          />
          <Stack.Screen
            component={Auth.ForgotPasswordScreen}
            name="Auth.ForgotPassword"
            options={{ title: 'Forgot Password' }}
          />
          <Stack.Screen
            component={Auth.ResetPasswordScreen}
            name="Auth.ResetPassword"
            options={{ title: 'Reset Password' }}
          />
        </>
      )}
      {authUser && (
        <>
          {!userOverview?.myUser.emailVerifiedAt ? (
            <>
              <Stack.Screen
                component={Account.VerificationRequiredScreen}
                name="Account.VerificationRequired"
                options={{ title: 'Email Verification Required' }}
              />
              <Stack.Screen
                component={Account.VerifyEmailScreen}
                name="Account.VerifyEmail"
                options={{ title: 'Verify Email' }}
              />
            </>
          ) : (
            <>
              {/* Default screen first */}
              <Stack.Screen
                component={Home.HomeScreen}
                name="Home.Home"
                options={{ title: 'Dashboard' }}
              />

              <Stack.Screen
                component={Account.AddFriendScreen}
                name="Account.AddFriend"
                options={{ title: 'Add Friend' }}
              />
              <Stack.Screen
                component={Account.ManageRoomsScreen}
                name="Account.ManageRooms"
                options={{ title: 'Manage Rooms' }}
              />
              <Stack.Screen
                component={Account.TwoFactorConfirmCodeScreen}
                name="Account.TwoFactorConfirmCode"
                options={{ title: '2FA Confirm' }}
              />
              <Stack.Screen
                component={Account.TwoFactorRequestCodeScreen}
                name="Account.TwoFactorRequestCode"
                options={{ title: '2FA Request Code' }}
              />
              <Stack.Screen
                component={Account.ProfilePhotoUploadScreen}
                name="Account.UploadProfilePhoto"
                options={{ title: 'Upload Profile Photo' }}
              />

              <Stack.Screen
                component={Albums.AlbumsListScreen}
                name="Albums.AlbumsList"
              />
              <Stack.Screen
                component={Albums.CreateAlbumRoomTriggerScreen}
                name="Albums.CreateAlbumRoomTrigger"
              />
              <Stack.Screen
                component={Albums.CreateAlbumFriendshipScreen}
                options={{ title: 'Create Friendship Album' }}
                name="Albums.CreateAlbumFriendship"
              />
              <Stack.Screen
                component={Albums.EditAlbumScreen}
                options={{ title: 'Edit Album' }}
                name="Albums.EditAlbum"
              />
              <Stack.Screen
                component={Albums.EditMediaScreen}
                name="Albums.EditMedia"
                options={{ title: 'Edit Media' }}
              />
              <Stack.Screen
                component={Albums.SuggestAlbumMediaScreen}
                name="Albums.SuggestMedia"
                options={{ title: 'Suggest Media' }}
              />
              <Stack.Screen
                component={Albums.ViewAlbumScreen}
                name="Albums.ViewAlbum"
                options={{ title: 'View Album' }}
              />
              <Stack.Screen
                component={Albums.ViewMediaScreen}
                name="Albums.ViewMedia"
                options={{ title: 'View Media' }}
              />

              <Stack.Screen
                component={FriendsWall.FriendsWallScreen}
                name="FriendsWall.FriendsWall"
                options={{ title: 'Friends Wall' }}
              />

              <Stack.Screen
                component={Home.BillingScreen}
                name="Home.Billing"
                options={{ title: 'Billing' }}
              />
              <Stack.Screen
                component={Home.PreviewRoomScreen}
                name="Home.PreviewRoom"
                options={{ title: 'Preview Room' }}
              />
              <Stack.Screen
                component={Home.SettingsScreen}
                name="Home.Settings"
                options={{ title: 'Settings' }}
              />
              <Stack.Screen
                component={Trustees.AddTrusteeScreen}
                name="Trustees.AddTrustee"
                options={{ title: 'Add Trustee' }}
              />
              <Stack.Screen
                component={Trustees.TrusteesScreen}
                name="Trustees.TrusteesList"
                options={{ title: 'Trustees List' }}
              />
              <Stack.Screen
                component={Trustees.ViewInviteScreen}
                name="Trustees.ViewInvite"
                options={{ title: 'Trustee Invite' }}
              />

              <Stack.Screen
                component={Vault.AddFileScreen}
                name="Vault.AddFile"
                options={{ title: 'Add File' }}
              />
              <Stack.Screen
                component={Vault.CategoryExploreScreen}
                name="Vault.CategoryExplore"
                options={{ title: 'Digital Vault' }}
              />
              <Stack.Screen
                component={Vault.EditFileScreen}
                name="Vault.EditFile"
                options={{ title: 'Edit File' }}
              />
              <Stack.Screen
                component={Vault.RootListScreen}
                name="Vault.RootList"
                options={{ title: 'Digital Vault' }}
              />
              <Stack.Screen
                component={Vault.ViewFileScreen}
                name="Vault.ViewFile"
                options={{ title: 'View File' }}
              />
            </>
          )}
        </>
      )}
      <Stack.Screen
        component={Home.InvitesScreen}
        name="Home.Invites"
        options={{ title: 'Friend Request' }}
      />
    </Stack.Navigator>
  );
}
