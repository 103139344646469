import { Color } from '../../constants';

type Props = {
  color?: string;
};

export default function IconCircleTick({ color = Color.accent700 }: Props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2063_50201"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2063_50201)">
        <path
          d="M12 19.7856C10.6167 19.7856 9.31667 19.5252 8.1 19.0046C6.88333 18.4839 5.825 17.7773 4.925 16.8847C4.025 15.9921 3.3125 14.9424 2.7875 13.7358C2.2625 12.5291 2 11.2398 2 9.86789C2 8.49594 2.2625 7.20664 2.7875 5.99999C3.3125 4.79334 4.025 3.74371 4.925 2.85112C5.825 1.95853 6.88333 1.25189 8.1 0.731214C9.31667 0.210535 10.6167 -0.0498047 12 -0.0498047C13.0833 -0.0498047 14.1083 0.107225 15.075 0.421286C16.0417 0.735346 16.9333 1.17338 17.75 1.73538L16.3 3.19824C15.6667 2.80153 14.9917 2.4916 14.275 2.26846C13.5583 2.04531 12.8 1.93373 12 1.93373C9.78333 1.93373 7.89583 2.70649 6.3375 4.252C4.77917 5.7975 4 7.66947 4 9.86789C4 12.0663 4.77917 13.9383 6.3375 15.4838C7.89583 17.0293 9.78333 17.802 12 17.802C14.2167 17.802 16.1042 17.0293 17.6625 15.4838C19.2208 13.9383 20 12.0663 20 9.86789C20 9.57036 19.9833 9.27283 19.95 8.9753C19.9167 8.67777 19.8667 8.3885 19.8 8.1075L21.425 6.49587C21.6083 7.02482 21.75 7.57029 21.85 8.13229C21.95 8.6943 22 9.27283 22 9.86789C22 11.2398 21.7375 12.5291 21.2125 13.7358C20.6875 14.9424 19.975 15.9921 19.075 16.8847C18.175 17.7773 17.1167 18.4839 15.9 19.0046C14.6833 19.5252 13.3833 19.7856 12 19.7856ZM10.6 14.43L6.35 10.215L7.75 8.82653L10.6 11.6531L20.6 1.71059L22 3.09906L10.6 14.43Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
