import React from 'react';
import { View } from 'react-native';

import { Color } from '../../constants';

type Direction = 'right' | 'down' | 'left' | 'up';

type Props = {
  direction?: Direction;
  color?: string;
};

export default function IconCaret({
  direction = 'right',
  color = Color.accent400,
}: Props) {
  const rotationMap: Record<Direction, number> = {
    right: 0,
    up: 135,
    down: 45,
    left: 90,
  };

  const rotate = rotationMap[direction] || 0;

  return (
    <View style={{ transform: [{ rotate: `${rotate}deg` }] }}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: `rotate(${rotate}deg)` }}
      >
        <mask
          id="mask0_1560_31410"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1560_31410)">
          <path
            d="M8.025 22L6.25 20.225L14.475 12L6.25 3.775L8.025 2L18.025 12L8.025 22Z"
            fill={color}
          />
        </g>
      </svg>
    </View>
  );
}
