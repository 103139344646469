import Ionicons from '@expo/vector-icons/Ionicons';
import Clipboard from '@react-native-clipboard/clipboard';
import toast from 'react-hot-toast';
import { Image, Pressable, StyleSheet, View } from 'react-native';

import { Color, Config, Dimen, Style } from '../../constants';
import { useUserOverview } from '../../hooks';
import useManaging from '../../hooks/use-managing';
import { useTypedDispatch } from '../../reducers';
import Button from '../Button';
import { IconAvatar, IconCopy, IconDoorOpen, IconLogout } from '../icons';
import LRText from '../LRText';
import PaddedArea from '../PaddedArea';
import PressableLink from '../PressableLink';
import Seperator from '../Seperator';
import Spacer from '../Spacer';
import NavigationLink from './NavigationLink';

/**
 * Has built-in 1x horizontal spacing (for button pressable area)
 */
export default function SidebarContent() {
  const { actingAsTrustee } = useManaging();
  const { managingUser } = useUserOverview();
  const dispatch = useTypedDispatch();

  return (
    <View>
      <PaddedArea h={0.25}>
        <View style={Style.row}>
          <PressableLink to={{ screen: 'Home.Home' }}>
            {managingUser.avatar && managingUser.avatar.pathAvatar ? (
              <Image
                source={managingUser.avatar.pathAvatar as any}
                style={styles.avatar}
              />
            ) : (
              <IconAvatar size={45} />
            )}
          </PressableLink>
          <Spacer size={0.5} />
          <View>
            <LRText>{`${managingUser.firstName} ${managingUser.lastName}`}</LRText>
            <LRText typeface="body2" color="gray7">
              {actingAsTrustee ? 'Acting as Trustee' : 'My room'}
            </LRText>
            <PressableLink to={{ screen: 'Account.ManageRooms' }}>
              {({ hovered }: any) => (
                <LRText
                  color={hovered ? 'accent700' : 'darkBg'}
                  style={{
                    textDecorationLine: hovered ? 'none' : 'underline',
                    ['transitionDuration' as any]: '0.25s',
                  }}
                  typeface="body2"
                >
                  Change room
                </LRText>
              )}
            </PressableLink>
            <Spacer />
          </View>
        </View>
        <Seperator color={Color.accent400} />
      </PaddedArea>
      <Spacer size={0.5} />
      <NavigationLink
        title="Home"
        to={{ screen: 'Home.Home' }}
        icon={<Ionicons name="home-outline" size={20} />}
      />
      {/* <NavigationLink
        title="Enter your legacy room"
        href={`${Config.VISITOR_ROOT}/${managingUser.roomSlug}`}
        icon={<IconDoorOpen />}
      /> */}
      <NavigationLink
        title="Preview your legacy room"
        to={{ screen: 'Home.PreviewRoom' }}
        icon={<IconDoorOpen />}
      />
      <NavigationLink
        title="Settings"
        to={{ screen: 'Home.Settings' }}
        icon={<Ionicons name="settings-outline" size={20} />}
      />
      <Pressable
        onPress={() => {
          dispatch({ type: 'LOGOUT' });
        }}
        style={({ hovered }: any) => [
          styles.button,
          hovered && styles.buttonHovered,
        ]}
      >
        <PaddedArea v={0.5} h={0.5} style={[Style.row, Style.alignCenter]}>
          <View style={styles.iconWrap}>
            <IconLogout />
          </View>
          <Spacer size={0.5} />
          <LRText typeface="button">Log out</LRText>
        </PaddedArea>
      </Pressable>
      <Spacer size={0.5} />
      <PaddedArea h={0.25}>
        <Seperator color={Color.accent400} />
        <Spacer />
        <LRText typeface="h3">Your Legacy Room Link</LRText>
        <Spacer size={0.25} />
        <LRText typeface="body2" color="gray7">
          Your personalised link
        </LRText>
        <Spacer size={0.75} />
        <Button
          buttonType="hollowAccent"
          buttonSize="small"
          title="Copy Link"
          icon={<IconCopy color={Color.accent400} />}
          onPress={() => {
            Clipboard.setString(
              `${Config.VISITOR_ROOT}/${managingUser.roomSlug}`
            );
            toast.success('Copied to clipboard');
          }}
        />
      </PaddedArea>
    </View>
  );
}

const styles = StyleSheet.create({
  avatar: {
    width: 45,
    height: 45,
    borderRadius: 22.5,
    backgroundColor: Color.gray2,
  },
  button: {
    borderRadius: Dimen.cornerRadiusSmall,
  },
  buttonHovered: {
    backgroundColor: Color.lightGrey,
  },
  iconWrap: {
    width: 24,
    alignItems: 'center',
  },
});
